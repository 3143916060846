export default {
  EditProfile: intl => ({
    page: {
      title: intl.formatMessage({
        description: "My Area Registry Edit Profile page title",
        defaultMessage: "Modifica Profilo",
      }),
      changePassword: {
        label: intl.formatMessage({
          description:
            "My Area Registry Edit Profile page change password link label",
          defaultMessage: "Cambio password",
        }),
      },
      form: {
        submit: intl.formatMessage({
          description:
            "My Area Registry Edit Profile page form submit button label",
          defaultMessage: "Salva",
        }),
      },
      errors: {
        fetchError: intl.formatMessage({
          description: "My Area Registry Edit Profile page general fetch error",
          defaultMessage:
            "Errore di comunicazione con il server, riprovare più tardi.",
        }),
      },
      success: intl.formatMessage({
        description: "My Area Registry Edit Profile page success",
        defaultMessage: "Utente aggiornato correttamente.",
      }),
    },
  }),
};
