import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

// MUI
import { Checkbox as MuiCheckbox, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { CheckedIcon } from "./Checkbox.styles";

const CheckBox = forwardRef(({ checked, disabled, label, ...rest }, ref) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          checked={checked}
          disabled={disabled}
          name={label}
          disableRipple
          color="primary"
          // checkedIcon={<CheckedIcon />}
          // icon={<span>Unchecked</span>}
          {...rest}
          inputRef={ref}
        />
      }
    />
  );
});

CheckBox.displayName = "CheckBox";

/**
 * @example <CheckBoxControl
 *            control={control}
              name={'isFormatted'}
              defaultValue={false}
              disabled={checkIsDisabled()}
              label={'Format TimeZone'}
            />
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Checkbox name
 * @param  errors - errors object destructured from useForm()
 * @param  defaultValue - Checkbox default value
 * @param  disabled - Condition hanling checkbox disabled prop
 * @param  label - Checkbox label
 * @param  customColorChecked - Color desired for checked Checkbox
 * @param  customColorUnchecked - Color desired for unchecked Checkbox
  * @param  errorObj - The errors object from translations, hanlded by useForm()
 */
const CheckBoxControl = ({
  control,
  name,
  errors,
  errorObj,
  defaultValue,
  disabled,
  label,
  ...rest
}) => {
  const renderError = (key, errors, errorObj) => {
    if (errors[key] && errors[key].type)
      return (
        <Typography color={"error"}>{errorObj[errors[key].type]}</Typography>
      );
    return null;
  };
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || false}
        render={({ field }) => (
          <CheckBox
            label={label || ""}
            onChange={e => field.onChange(e.target.checked)}
            checked={field.value}
            disabled={disabled}
            {...field}
            {...rest}
          />
        )}
      />
      {errors && errorObj && renderError(name, errors, errorObj)}
    </>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

CheckBoxControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorObj: PropTypes.object,
};

export { CheckBox, CheckBoxControl };
