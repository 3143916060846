import * as yup from "yup";

export const BusinessPartnerSchema = {
  "business-name": yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  "vat-id": yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  currency: yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  "billbook-data": yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  zone: yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  division: yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().required(),
  }),
  emailPoliform: yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().email().required(),
  }),
  emailVarenna: yup.string().when("select-type", {
    is: "business-partner",
    then: yup.string().email().required(),
  }),
};
