import PropTypes from "prop-types";

import { BlackButton } from "./BlackWhiteRoundedButton.styles";
const BlackWhiteRoundedButton = ({ label, ...rest }) => {
  return (
    <BlackButton variant="contained" {...rest}>
      {label}
    </BlackButton>
  );
};

BlackWhiteRoundedButton.propTypes = {
  label: PropTypes.string,
};

BlackWhiteRoundedButton.defaultProps = {};

export { BlackWhiteRoundedButton };
