import { userGroups } from "constants/enums";
import format from "date-fns/format";

export const registerDTO = object => {
  let userGroup;
  switch (object["select-type"]) {
    case "private":
      userGroup = userGroups.ENDUSER;
      break;
    case "press":
      userGroup = userGroups.PRESS;
      break;
    case "professional":
      userGroup = userGroups.PROFESSIONAL;
      break;
    default:
      userGroup = userGroups.ENDUSER;
  }

  const parsedCategoryId = parseInt(object.category);

  let DTOtemplate = {
    email: object.email,
    password: object.password,
    name: object.name,
    group: userGroup,
    company: object.company,
    lastname: object.surname,
    language: object.language,
    phone: object.telephone,
    mobilePhone: object.mobile,
    jobTitle: object.qualification,
    website: object.website,
    birthdate: object.birthDate
      ? // ? new Date(object.birthDate).toISOString().slice(0, 10)
        format(new Date(object.birthDate), "yyyy-MM-dd")
      : null,
    address: {
      geoCountryId: object.nation?.id,
      geoProvinceId: object.province?.id,
      // TODO remove this sooner or later
      geoRegionId: null,
      geoCityId: object.city?.id,
      geoZipCodeId: object.postalCode?.id,
      street: object.street,
      foreignCity: object.foreignCity || null,
      foreignZipCode: object.foreignPostalCode || null,
    },
    sectorsOfInterestIds: object.sectors,
    categoriesIds: parsedCategoryId ? [parsedCategoryId] : [],
  };

  return DTOtemplate;
};

export const updateDTO = (object, user) => {
  const parsedCategoryId = parseInt(object.category);
  // const result = parseISO(new Date(object.birthDate).toISOString());
  let DTOtemplate = {
    email: object.email,
    name: object.name,
    lastname: object.surname,
    language: object.language,
    company: object.company,
    phone: object.telephone,
    jobTitle: object.qualification || null,
    mobilePhone: object.mobile,
    birthdate: object.birthDate
      ? // ? new Date(object.birthDate).toISOString()
        format(new Date(object.birthDate), "yyyy-MM-dd")
      : null,
    address:
      user.group === userGroups.AGENTSTAFF ||
      user.group === userGroups.LOCALMANAGER
        ? null
        : {
            geoCountryId: object.nation.id,
            geoProvinceId: object.province?.id,
            // TODO remove this sooner or later
            geoRegionId: null,
            geoCityId: object.city?.id,
            geoZipCodeId: object.postalCode?.id,
            street: object.street,
            foreignCity: object.foreignCity || null,
            foreignZipCode: object.foreignPostalCode || null,
          },
    sectorsOfInterestIds: object.sectors,
    categoriesIds: parsedCategoryId ? [parsedCategoryId] : null,
  };

  return DTOtemplate;
};

// {
//   "name": "Manuali comunicazione",
//   "areaId":4,
//   "area": {
//       "id": 4,
//       "name": "Marketing"
//   },
//   "activityId": 18,
//   "activity": {
//       "id": 18,
//       "name": "Manuali comunicazione"
//   },
//   "create": {
//       "editable": false
//   },
//   "read": {
//       "editable": true,
//       "state": true
//   },
//   "delete": {
//       "editable": true
//   }
// }

export const createPolicyDTO = object => {
  return {
    name: object.name,
    areaId: object.area.id,
    activityId: object.activity.id,
    read: object.read?.state,
    update: object.update?.state,
    create: object.create?.state,
    delete: object.delete?.state,
  };
};

export const updatePolicyDTO = object => {
  return {
    id: object.id,
    name: object.name,
    areaId: object.area.id,
    activityId: object.activity.id,
    read: object.read?.state,
    update: object.update?.state,
    create: object.create?.state,
    delete: object.delete?.state,
  };
};
