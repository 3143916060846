import { styled } from "@mui/system";

export const MultiplePanelLabel = styled("div")(() => ({
  border: "1px solid black",
  height: "100%",
  padding: 5,
  borderRadius: 5,
  lineHeight: 1,
  flex: 1,
}));

export const MultiplePanelContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.agenda.multipleAvailabilities,
  position: "absolute",
  top: "1px",
  left: 0,
  width: "100%",
  minHeight: "calc(100% * 4)",
  maxHeight: "calc(100% * 8)",
  // height: "500px",
  zIndex: 9,
  padding: 5,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const MultiplePanelContainerHeader = styled("div")(() => ({
  position: "sticky",
  top: 0,
  display: "flex",
  backgroundColor: "inherit",
}));

export const Overlay = styled("div")(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  // background-color: #00000017,
  zIndex: 1,
  cursor: "auto",
}));
