import { useEffect, useState } from "react";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react router dom
import {
  useLocation,
  useNavigate,
  Navigate,
  useSearchParams,
} from "react-router-dom";

// use-http
import useFetch from "use-http";

// react hook form
import { useForm } from "react-hook-form";

// react mui
import { Box, Grid, Typography } from "@mui/material";

// components
import {
  MainLayout,
  FixedSlotCalendar,
  FreeSlotCalendar,
  LinearProgress,
  BlackWhiteRoundedButton,
  AutocompleteControlAPI,
  LoadingDialog,
} from "components";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";

// constants
import {
  SCHEDULE,
  SCHEDULE_EVENT_DETAIL,
  SCHEDULE_MANAGE_EVENT_PARTICIPANTS,
} from "constants/routes";
import { apiAgenda, apiExportExhibition, apiUser } from "constants/api";
import { userGroups } from "constants/enums";

import "../AgendaDragandDrop.css";

// hooks
import { useUserRoles, useEvents } from "hooks";

// utils
import { parseAvailabilitiesToFreeSlots } from "utils/Agenda";

const EventPrenotation = () => {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // location state
  const { state: currentEvent } = useLocation();
  if (!currentEvent) return <Navigate to={SCHEDULE} />;

  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nAutocomplete = translations.components.Autocomplete(intl);

  const { isLoggedUserOneofGroups } = useUserRoles();
  const { parseEvent } = useEvents();

  // dialog State
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const [userToFilter, setUserToFilter] = useState();

  const { loading, error, data } = useFetch(
    `${apiAgenda.API_BASE}/${currentEvent.id}${
      userToFilter ? `?fromId=${userToFilter.id}` : ``
    }`,
    [open, openUpdateDialog, userToFilter]
  );

  const { loading: loadingExport, get: exportExhibitionXLCX } = useFetch(
    `${apiExportExhibition.API_BASE}${currentEvent.id}`
  );

  const { control } = useForm();

  const [event, setEvent] = useState();

  // this will hold the temporary dragged slot, in order to send
  // later the request with these ids
  const [dragSlots, setDragSlots] = useState([]);

  useEffect(() => {
    if (data && !error) {
      setEvent(parseEvent(data));
      setDragSlots(parseAvailabilitiesToFreeSlots(data));
    }
  }, [data]);

  const handleEport = () => {
    // eslint-disable-next-line
    exportExhibitionXLCX().then(res => {
      window.open(res, "_blank");
    });
  };

  return (
    <MainLayout>
      <LeftArrow className="svg-hover" onClick={() => navigate(SCHEDULE)} />

      {error && <Typography color="error">{i18n.errors.fetchError}</Typography>}
      {loading && <LinearProgress />}
      {event && !error && (
        <>
          <Typography variant={"h4"}>{event.name}</Typography>
          {isLoggedUserOneofGroups([
            userGroups.AREAMANAGER,
            userGroups.ADMIN,
            userGroups.LOCALMANAGER,
            userGroups.AGENT,
          ]) && (
            <Grid container mt={2} spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={12} md={4}>
                <AutocompleteControlAPI
                  key={"autocomplete-key"}
                  control={control}
                  name={"user"}
                  label={i18nAutocomplete.eventPrenotationFilter.label}
                  endpoint={`${apiUser.API_BASE}${apiUser.INTERNALS}`}
                  queryParam={`&q`}
                  onChange={v => setUserToFilter(v)}
                  value={userToFilter}
                  noOptionsText={i18nAutocomplete.agents.noOptions}
                  addSelf
                />
              </Grid>
            </Grid>
          )}
          <Box my={2}>
            <BlackWhiteRoundedButton
              onClick={() => navigate(SCHEDULE_EVENT_DETAIL, { state: event })}
              label={i18n.eventDetail.getDetails}
            />
            <BlackWhiteRoundedButton
              onClick={() =>
                navigate(SCHEDULE_MANAGE_EVENT_PARTICIPANTS, { state: event })
              }
              label={i18n.eventDetail.getParticipants}
            />
            <BlackWhiteRoundedButton
              onClick={handleEport}
              label={i18n.eventDetail.exportData}
            />
          </Box>

          {event.slotTypology === "FREE" && (
            <FreeSlotCalendar
              event={event}
              freeSlots={event.slots}
              dragSlots={dragSlots}
              open={open}
              setOpen={setOpen}
              action={action}
              setAction={setAction}
              openUpdateDialog={openUpdateDialog}
              setOpenUpdateDialog={setOpenUpdateDialog}
              currDate={searchParams.get("currDate")}
            />
          )}
          {event.slotTypology === "FIXED" && (
            <FixedSlotCalendar
              event={event}
              fixedSlots={event.slots}
              open={open}
              setOpen={setOpen}
              action={action}
              setAction={setAction}
              currDate={searchParams.get("currDate")}
            />
          )}
          <LoadingDialog open={loading || loadingExport} />
        </>
      )}
    </MainLayout>
  );
};

export { EventPrenotation };
