import PropTypes from "prop-types";
// react mui
import { Grid } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { AutocompleteControlAPI, TextFieldControl } from "components/index";

// constants
import { ITALY } from "constants/countries";
import { apiAddresses } from "constants/api";

import { useState } from "react";

const LocationControl = ({ control, errors, watch, reset }) => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl).page.anagrafiche;

  // state
  const [nationValue, setNationValue] = useState(watch("nation") || null);
  const [provinceValue, setProvinceValue] = useState(watch("province") || null);
  const [cityValue, setCityValue] = useState(watch("city") || null);
  const [postalCodeValue, setPostalCodeValue] = useState(
    watch("postalCode") || null
  );

  const nation = watch("nation");

  // TODO refactor
  const handleResetNation = data => {
    reset({
      ...data,
      city: undefined,
      province: undefined,
      postalCode: undefined,
      foreignCity: undefined,
      foreignPostalCode: undefined,
      street: undefined,
    });
  };

  watch((data, { name }) => {
    if (name == "nation") {
      if (!data[name]) {
        return handleResetNation(data);
      }
      if (data[name].id != ITALY.id) {
        handleResetNation(data);
      }
    }
    // if we undo the selection of province
    // then subsequentially the city and the
    // postalCode will be ""
    if (name == "province") {
      // if (!data[name]) {
      setCityValue(undefined);
      setPostalCodeValue(undefined);
      reset({
        ...data,
        city: null,
        postalCode: null,
        street: null,
      });
      // }
    }
    // if we undo the selection of city
    // then subsequentially the postalCode
    //  will be null
    if (name == "city") {
      // if (!data[name]) {
      setPostalCodeValue(undefined);
      reset({
        ...data,
        postalCode: null,
        street: null,
      });
      // }
    }
  });

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={12} md={3}>
        <TextFieldControl
          control={control}
          name={"location"}
          placeholder={i18n.location.label}
          label={i18n.location.label}
          errors={errors}
          errorObj={i18n.location.errors}
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <AutocompleteControlAPI
          control={control}
          name={"nation"}
          label={i18n.nation.label}
          errors={errors}
          errorObj={i18n.nation.errors}
          endpoint={`${apiAddresses.API_BASE}${apiAddresses.COUNTRIES}`}
          queryParam={"q"}
          value={nationValue}
          noOptionsText={i18n.nation.noOptionsText}
          onChange={setNationValue}
          isRequired
        />
      </Grid>
      {nation?.id == ITALY.id && (
        <>
          <Grid item xs={12} md={3}>
            <AutocompleteControlAPI
              control={control}
              name={"province"}
              label={i18n.province.label}
              errors={errors}
              errorObj={i18n.province.errors}
              endpoint={`${apiAddresses.API_BASE}${apiAddresses.PROVINCES}`}
              queryParam={`q`}
              noOptionsText={i18n.province.noOptionsText}
              disabled={!nationValue}
              value={provinceValue}
              defaultValue={provinceValue}
              onChange={setProvinceValue}
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteControlAPI
              control={control}
              name={"city"}
              label={i18n.city.label}
              errors={errors}
              errorObj={i18n.city.errors}
              endpoint={`${apiAddresses.API_BASE}${apiAddresses.CITIES}`}
              queryParam={`ParentId=${provinceValue?.id}&q`}
              noOptionsText={i18n.city.noOptionsText}
              disabled={!provinceValue}
              value={cityValue}
              defaultValue={cityValue}
              onChange={setCityValue}
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteControlAPI
              control={control}
              name={"postalCode"}
              label={i18n.postalCode.label}
              errors={errors}
              errorObj={i18n.postalCode.errors}
              endpoint={`${apiAddresses.API_BASE}${apiAddresses.ZIPCODES}`}
              queryParam={`ParentId=${cityValue?.id}&q`}
              noOptionsText={i18n.postalCode.noOptionsText}
              disabled={!cityValue}
              value={postalCodeValue}
              defaultValue={postalCodeValue}
              onChange={setPostalCodeValue}
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextFieldControl
              control={control}
              name={"street"}
              placeholder={i18n.street.label}
              label={i18n.street.label}
              errors={errors}
              errorObj={i18n.street.errors}
              isRequired
              disabled={!postalCodeValue}
              defaultValue={watch("street")}
            />
          </Grid>
        </>
      )}
      {nation?.id != ITALY.id && (
        <>
          <Grid item xs={12} md={3}>
            <TextFieldControl
              control={control}
              name={"foreignCity"}
              placeholder={i18n.foreignCity.label}
              label={i18n.foreignCity.label}
              errors={errors}
              errorObj={i18n.foreignCity.errors}
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextFieldControl
              control={control}
              name={"foreignPostalCode"}
              placeholder={i18n.foreignPostalCode.label}
              label={i18n.foreignPostalCode.label}
              errors={errors}
              errorObj={i18n.foreignPostalCode.errors}
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextFieldControl
              control={control}
              name={"street"}
              placeholder={i18n.street.label}
              label={i18n.street.label}
              errors={errors}
              errorObj={i18n.street.errors}
              isRequired
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

LocationControl.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  reset: PropTypes.func,
};
export { LocationControl };
