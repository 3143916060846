import * as yup from "yup";

export const AnagraficheSchema = {
  nation: yup.object().required(),
  language: yup
    .string()
    .when("select-type", {
      is: "professional",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "press",
      then: yup.string().required(),
    }),

  province: yup.object().when("nation", {
    is: { value: "IT", label: "Italy" },
    then: yup.object().required(),
  }),
  city: yup.object().when("nation", {
    is: { value: "IT", label: "Italy" },
    then: yup.object().required(),
    // otherwise: yup.object().nullable(),
  }),
  postalCode: yup.object().when("nation", {
    is: { value: "IT", label: "Italy" },
    then: yup.object().required(),
  }),
  birthDate: yup.string().nullable(),
  foreignCity: yup.string(),
  foreignPostalCode: yup.string(),
  street: yup.string().required().min(3).max(40),
};
