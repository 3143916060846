import { cloneElement } from "react";

import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import isWithinInterval from "date-fns/isWithinInterval";
import { add, format } from "date-fns";
import sub from "date-fns/sub";
import { agendaSlots, userGroups } from "constants/enums";

export const isInEventRange = (slot, event) => {
  return event.timeSlots.some(({ start, end }) =>
    isWithinInterval(new Date(slot), {
      start: new Date(start),
      end: new Date(end),
    })
  );
};
export const isDraggedSlotInEventRange = (slot, event) => {
  return event.slots.some(({ start, end }) => {
    return areIntervalsOverlapping(
      {
        start: slot.start,
        end: slot.end,
      },
      {
        start: new Date(start),
        end: new Date(end),
      },
      { inclusive: true }
    );
  });
};

export function TimeSlotWrapper(props) {
  if (props.resource === undefined || isInEventRange(props.value)) {
    return props.children;
  }

  const child = props.children;
  return cloneElement(child, {
    className: child.props.className + " rbc-off-range-bg",
  });
}

export const parseAvailabilitiesToFreeSlots = data => {
  let tmpAvailabilitesFreeSlots = [];

  data.slots.map(el => {
    const year = parseInt(format(new Date(el.start), "yyyy"));
    const month = parseInt(format(new Date(el.start), "MM"));
    const day = parseInt(
      format(
        sub(new Date(el.start), {
          minutes: new Date(el.start).getTimezoneOffset(),
        }),
        "dd"
      )
    );

    el.start = sub(new Date(el.start), {
      minutes: new Date(el.start).getTimezoneOffset(),
    });
    el.end = sub(new Date(el.end), {
      minutes: new Date(el.end).getTimezoneOffset(),
    });
    el.availabilities.map(availability => {
      let tmpAvailability = { ...availability };

      const startDateTime = new Date(
        year,
        month - 1,
        day,
        availability.startHour,
        availability.startMinutes
      );
      const startOffset = new Date(
        year,
        month - 1,
        day,
        availability.startHour,
        availability.startMinutes
      ).getTimezoneOffset();
      const endDateTime = new Date(
        year,
        month - 1,
        day,
        availability.endHour,
        availability.endMinutes
      );
      const endOffset = new Date(
        year,
        month - 1,
        day,
        availability.endHour,
        availability.endMinutes
      ).getTimezoneOffset();
      tmpAvailability.start = sub(startDateTime, { minutes: startOffset });
      tmpAvailability.end = sub(endDateTime, { minutes: endOffset });

      tmpAvailabilitesFreeSlots.push(tmpAvailability);
    });
  });

  return tmpAvailabilitesFreeSlots;
};

export const createAppointment = (user, data, slot, mainEvent) => {
  let tmpAppointment = {};

  tmpAppointment.toId = data.participant
    ? null
    : user.group === userGroups.ADMIN ||
      user.group === userGroups.AGENT ||
      user.group === userGroups.AGENTSTAFF ||
      user.group === userGroups.AREAMANAGER ||
      user.group === userGroups.LOCALMANAGER
    ? data.client.id
    : user.id;

  tmpAppointment.note = data.note;
  tmpAppointment.toGuest = data.participant ? data.participant : null;
  const startDateTimeISO = slot?.start.toISOString();
  const endDateTimeISO = slot?.end.toISOString();

  const startTimeISO = startDateTimeISO.split("T")[1].slice(0, -5).split(":");
  const endTimeISO = endDateTimeISO.split("T")[1].slice(0, -5).split(":");

  tmpAppointment.startHour = parseInt(startTimeISO[0]);
  tmpAppointment.startMinutes = parseInt(startTimeISO[1]);
  tmpAppointment.endHour = parseInt(endTimeISO[0]);
  tmpAppointment.endMinutes = parseInt(endTimeISO[1]);

  tmpAppointment.createdById = user.id;

  tmpAppointment.exhibitionId = mainEvent.id;

  tmpAppointment.guests = data.guests;

  return tmpAppointment;
};

export const parseAppointment = data => {
  const tmpDate = data.date.split("-");
  const startDateTime = new Date(
    parseInt(tmpDate[0]),
    parseInt(tmpDate[1]) - 1,
    parseInt(tmpDate[2]),
    data.startHour,
    data.startMinutes
  );
  const startOffset = startDateTime.getTimezoneOffset();
  const endDateTime = new Date(
    parseInt(tmpDate[0]),
    parseInt(tmpDate[1]) - 1,
    parseInt(tmpDate[2]),
    data.endHour,
    data.endMinutes
  );
  const endOffset = endDateTime.getTimezoneOffset();
  return {
    ...data,
    guests: data.participants
      .filter(el => el.isMain == false)
      .map(el => {
        return { ...el, disabled: data.status === agendaSlots.CONFIRMED };
      }),
    participant: data.participants.find(el => el.isMain == true),
    startTime: sub(startDateTime, { minutes: startOffset }),
    endTime: sub(endDateTime, { minutes: endOffset }),
    startDate: sub(startDateTime, { minutes: startOffset }),
    endDate: sub(endDateTime, { minutes: endOffset }),
    toGuest: {
      ...data.toGuest,
      note: data.participants.filter(el => el.isMain == true)[0]?.note,
    },
  };
};

export const updateAppointment = (data, appointmentSlot) => {
  const startTimeISO = data.startTime.toISOString();
  const endTimeISO = data.endTime.toISOString();

  const startTime = startTimeISO.split("T")[1].slice(0, -5).split(":");
  const endTime = endTimeISO.split("T")[1].slice(0, -5).split(":");
  return {
    // date: data.startDate.toISOString().split("T")[0],
    startHour: parseInt(startTime[0]),
    startMinutes: parseInt(startTime[1]),
    endHour: parseInt(endTime[0]),
    endMinutes: parseInt(endTime[1]),
    note: data.note,
    availabilityId: appointmentSlot.availabilityId,
  };
};

export const createAvailability = (user, data, slot) => {
  let tmpAvailability = {};

  let tmpFromId;
  if (user.group === userGroups.ADMIN) {
    tmpFromId = data.client.id;
    // } else if (user.group === userGroups.AGENTSTAFF) {
    //   tmpFromId = user.staffParentId;
  } else {
    tmpFromId = user.id;
  }

  tmpAvailability.fromId = tmpFromId;
  const startDateTimeISO = slot?.start.toISOString();
  const endDateTimeISO = slot?.end.toISOString();

  const startTimeISO = startDateTimeISO.split("T")[1].slice(0, -5).split(":");
  const endTimeISO = endDateTimeISO.split("T")[1].slice(0, -5).split(":");

  tmpAvailability.startHour = parseInt(startTimeISO[0]);
  tmpAvailability.startMinutes = parseInt(startTimeISO[1]);
  tmpAvailability.endHour = parseInt(endTimeISO[0]);
  tmpAvailability.endMinutes = parseInt(endTimeISO[1]);
  // tmpAvailability.creatorId = user.id;

  return tmpAvailability;
};

export const parseAvailability = (mainSlot, slot) => {
  let tmpAvailability = { ...slot };

  const year = parseInt(format(new Date(mainSlot.start), "yyyy"));
  const month = parseInt(format(new Date(mainSlot.start), "MM"));
  const day = parseInt(
    format(
      sub(new Date(mainSlot.start), {
        minutes: new Date(mainSlot.start).getTimezoneOffset(),
      }),
      "dd"
    )
  );
  const startDateTime = new Date(
    year,
    month - 1,
    day,
    slot.startHour,
    slot.startMinutes
  );
  const startOffset = new Date(
    year,
    month - 1,
    day,
    slot.startHour,
    slot.startMinutes
  ).getTimezoneOffset();
  const endDateTime = new Date(
    year,
    month - 1,
    day,
    slot.endHour,
    slot.endMinutes
  );
  const endOffset = new Date(
    year,
    month - 1,
    day,
    slot.endHour,
    slot.endMinutes
  ).getTimezoneOffset();

  tmpAvailability.start = sub(startDateTime, { minutes: startOffset });
  tmpAvailability.end = sub(endDateTime, { minutes: endOffset });

  return tmpAvailability;
};

export const updateAvailability = (
  originalAvailability,
  newStartHour,
  newEndHour
) => {
  let tmpAvailability = {};

  tmpAvailability.id = originalAvailability?.id;
  const startDateTimeISO = newStartHour.toISOString();
  const endDateTimeISO = newEndHour.toISOString();

  const startTimeISO = startDateTimeISO.split("T")[1].slice(0, -5).split(":");
  const endTimeISO = endDateTimeISO.split("T")[1].slice(0, -5).split(":");

  tmpAvailability.startHour = parseInt(startTimeISO[0]);
  tmpAvailability.startMinutes = parseInt(startTimeISO[1]);
  tmpAvailability.endHour = parseInt(endTimeISO[0]);
  tmpAvailability.endMinutes = parseInt(endTimeISO[1]);
  // tmpAvailability.creatorId = user.id;

  return tmpAvailability;
};

export const parseParticipation = data => {
  const tmpDate = data.date.split("-");
  const startDateTime = new Date(
    parseInt(tmpDate[0]),
    parseInt(tmpDate[1]) - 1,
    parseInt(tmpDate[2]),
    data.startHour,
    data.startMinutes
  );
  // eslint-disable-next-line
  const startOffset = startDateTime.getTimezoneOffset();
  const endDateTime = new Date(
    parseInt(tmpDate[0]),
    parseInt(tmpDate[1]) - 1,
    parseInt(tmpDate[2]),
    data.endHour,
    data.endMinutes
  );
  // eslint-disable-next-line
  const endOffset = endDateTime.getTimezoneOffset();
  return {
    ...data,
    startTime: add(startDateTime, { minutes: 120 }),
    endTime: add(endDateTime, { minutes: 120 }),
    startDate: add(startDateTime, { minutes: 120 }),
    endDate: add(endDateTime, { minutes: 120 }),
  };
};
