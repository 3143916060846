import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

// components
import {
  BlackWhiteRoundedButton,
  ExportLinkCSV,
  MainLayout,
} from "components/index";

// utils
import translations from "utils/translations";

import { HeaderContainer } from "./EndUsersList.styles";

import { filtersList } from "./EndUsersList.filters";
import useHeader from "./EndUsersList.header";
import { FilteredList } from "containers";
import { useRegistryDataListFetch } from "hooks";

import { REGISTRY_ENDUSERS_CREATE } from "constants/routes";

function EndUsersList() {
  const [header] = useHeader();
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);
  const navigate = useNavigate();

  const renderHeaderAction = () => {
    return (
      <>
        <HeaderContainer>
          <BlackWhiteRoundedButton
            onClick={() => navigate(REGISTRY_ENDUSERS_CREATE)}
            label={i18n.employees.createbtnText}
          />
          <ExportLinkCSV />
        </HeaderContainer>
      </>
    );
  };
  return (
    <MainLayout>
      <FilteredList
        useCustomFetch={useRegistryDataListFetch}
        i18n={i18n.endUsers}
        i18nErrors={i18n.errors}
        header={header}
        headerAction={renderHeaderAction()}
        filtersList={filtersList}
        queryUserTypeParams={"&Group=ENDUSER&"}
      />
    </MainLayout>
  );
}

export { EndUsersList };
