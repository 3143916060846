import { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  CustomDateTimePicker,
  CustomDatePicker,
  CustomTimePicker,
} from "./DatePicker.styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-US";
import { TextField } from "components/index";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";

const localeMap = {
  EN: enLocale,
  FR: frLocale,
  RU: ruLocale,
  DE: deLocale,
  IT: itLocale,
};

const maskMap = {
  FR: "__/__/____",
  EN: "__/__/____",
  IT: "__/__/____",
  RU: "__.__.____",
  DE: "__.__.____",
};

const DateTimePicker = ({ ...rest }) => {
  // eslint-disable-next-line
  const [locale, setLocale] = useState("IT");

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <CustomDateTimePicker
        mask={maskMap[locale]}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        formatDate={date => new Date(date).toISOString()}
        renderInput={params => {
          params.error = false;
          return (
            <TextField
              onKeyDown={e => {
                e.preventDefault();
              }}
              {...params}
              {...rest}
            />
          );
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};
const TimePicker = ({ ...rest }) => {
  // eslint-disable-next-line
  const [locale, setLocale] = useState("IT");

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <CustomTimePicker
        mask={maskMap[locale]}
        minutesStep={30}
        components={{
          OpenPickerIcon: ClockIcon,
        }}
        formatDate={date => new Date(date).toISOString()}
        renderInput={params => {
          params.error = false;
          return (
            <TextField
              onKeyDown={e => {
                e.preventDefault();
              }}
              {...params}
              {...rest}
            />
          );
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

const DatePicker = ({ ...rest }) => {
  // eslint-disable-next-line
  const [locale, setLocale] = useState("IT");
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <CustomDatePicker
        mask={maskMap[locale]}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        formatDate={date => new Date(date).toISOString()}
        renderInput={params => {
          params.error = false;
          return (
            <TextField
              onKeyDown={e => {
                e.preventDefault();
              }}
              {...params}
              {...rest}
            />
          );
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

const DateTimePickerControl = ({
  control,
  name,
  disabled,
  label,
  errors,
  errorMsg,
  isRequired = false,
  defaultValue,
  ...rest
}) => {
  const renderError = (key, errors, errorMsg) => {
    if (errors[key] && errors[key]?.type === "required")
      return <Typography color={"error"}>{errorMsg}</Typography>;
    return null;
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <DateTimePicker
            label={
              <Typography variant="text">
                {label}
                {isRequired && "*"}
              </Typography>
            }
            onChange={e => {
              return field.onChange(e.target.value);
            }}
            value={field.value}
            disabled={disabled}
            {...field}
            {...rest}
          />
        )}
      />
      {errors && errorMsg && renderError(name, errors, errorMsg)}
    </>
  );
};

DateTimePickerControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool,
};

DateTimePickerControl.defaultProps = {
  isRequired: false,
};

const DatePickerControl = ({
  control,
  name,
  disabled,
  label,
  errors,
  errorMsg,
  isRequired,
  defaultValue,
  ...rest
}) => {
  const renderError = (key, errors, errorMsg) => {
    if (errors[key] && errors[key]?.type === "required")
      return <Typography color={"error"}>{errorMsg}</Typography>;
    return null;
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <DatePicker
            label={
              <Typography variant="text">
                {label} {isRequired && "*"}
              </Typography>
            }
            onChange={e => {
              return field.onChange(e.target.value);
            }}
            defaultValue={defaultValue}
            value={defaultValue || field.value}
            disabled={disabled}
            {...field}
            {...rest}
          />
        )}
      />
      {errors && errorMsg && renderError(name, errors, errorMsg)}
    </>
  );
};

DatePickerControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool,
};

DatePickerControl.defaultProps = {
  isRequired: false,
};

const TimePickerControl = ({
  control,
  name,
  disabled,
  label,
  errors,
  errorMsg,
  isRequired,
  defaultValue,
  ...rest
}) => {
  const renderError = (key, errors, errorMsg) => {
    if (errors[key] && errors[key]?.type === "required")
      return <Typography color={"error"}>{errorMsg}</Typography>;
    return null;
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <TimePicker
            label={
              <Typography variant="text">
                {label} {isRequired && "*"}
              </Typography>
            }
            onChange={e => {
              return field.onChange(e.target.value);
            }}
            defaultValue={defaultValue}
            value={defaultValue || field.value}
            disabled={disabled}
            {...field}
            {...rest}
          />
        )}
      />
      {errors && errorMsg && renderError(name, errors, errorMsg)}
    </>
  );
};

TimePickerControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool,
};

TimePickerControl.defaultProps = {
  isRequired: false,
};

export {
  DateTimePicker,
  DateTimePickerControl,
  DatePicker,
  DatePickerControl,
  TimePicker,
  TimePickerControl,
};
