import PropTypes from "prop-types";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const LoadingDialog = ({ open }) => {
  return (
    <Dialog open={open} sx={{ alignItems: "center", textAlign: "center" }}>
      <DialogTitle>Elaborazione in corso</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};
LoadingDialog.propTypes = {
  open: PropTypes.bool,
};
export { LoadingDialog };
