import { useContext } from "react";

import { AuthContext } from "context/AuthContext";

const useUserRoles = () => {
  const { user } = useContext(AuthContext);
  const isLoggedUserInGroup = role => {
    return user.group === role;
  };

  const isLoggedUserOneofGroups = arrayRoles => {
    return arrayRoles.some(role => user.group === role);
  };

  return { isLoggedUserInGroup, isLoggedUserOneofGroups };
};
export { useUserRoles };
