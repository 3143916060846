import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// assets
import { ReactComponent as LeftArrow } from "assets/icons/Icon_arrow_left.svg";

const BackArrow = ({ backFunction }) => {
  let navigate = useNavigate();

  return (
    <LeftArrow
      className="svg-hover"
      onClick={() => (backFunction ? backFunction() : navigate(-1))}
    />
  );
};

BackArrow.propTypes = {
  backFunction: PropTypes.func,
};
export { BackArrow };
