import styled from "@emotion/styled";
import { colors } from "constants/colors";

export const GreyLabelWrapper = styled("div")(() => ({
  marginTop: 10,
  width: "100%",
  backgroundColor: colors.WHITESMOKE,
  color: colors.BLACK,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
export const GreyLabelContent = styled("div")(() => ({
  padding: "10px 16px",
}));
