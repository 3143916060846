import * as ROUTES from "constants/routes";
import { menuIdentifiers } from "constants/enums";

export const Menu = {
  MYAREA: {
    identifier: menuIdentifiers.MYAREA,
    label: intl =>
      intl.formatMessage({
        description: "Sidebar Menu Dashboard Main",
        defaultMessage: "Dashboard",
      }),
    icon: "/images/icons/dashboard.svg",
    path: ROUTES.ROOT,
    submenu: [],
  },
  AGENDA: {
    identifier: menuIdentifiers.AGENDA,
    label: intl =>
      intl.formatMessage({
        description: "Sidebar Menu Agenda Main",
        defaultMessage: "Agenda",
      }),
    icon: "/images/icons/calendar.svg",
    path: ROUTES.SCHEDULE,
    submenu: [
      // {
      //   identifier: menuIdentifiers.AGENDA_MANAGE_SHOWROOMS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Agenda manage showroom",
      //       defaultMessage: "Gestisci Poliform Lab",
      //     }),
      //   path: ROUTES.SCHEDULE_MANAGE_SHOWROOM,
      // },
      // {
      //   identifier: menuIdentifiers.AGENDA_SHOWROOMS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Agenda showroom",
      //       defaultMessage: "Poliform Lab",
      //     }),
      //   path: ROUTES.SCHEDULE_SHOWROOM,
      // },
      // {
      //   identifier: menuIdentifiers.AGENDA_MANAGE_EVENTS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Agenda manage events",
      //       defaultMessage: "Gestisci Eventi",
      //     }),
      //   path: ROUTES.SCHEDULE_MANAGE_EVENTS,
      // },
      {
        identifier: menuIdentifiers.AGENDA_EVENTS,
        label: intl =>
          intl.formatMessage({
            description: "Sidebar Menu Agenda events",
            defaultMessage: "Eventi",
          }),
        path: ROUTES.SCHEDULE,
      },
      {
        identifier: menuIdentifiers.AGENDA_ARCHIVE,
        label: intl =>
          intl.formatMessage({
            description: "Sidebar Menu Agenda manage archive",
            defaultMessage: "Archivio Eventi",
          }),
        path: ROUTES.SCHEDULE_ARCHIVE,
      },
    ],
  },
  REGISTRY: {
    identifier: menuIdentifiers.REGISTRY,
    label: intl =>
      intl.formatMessage({
        description: "Sidebar Menu Registry Main",
        defaultMessage: "Anagrafiche",
      }),
    icon: "/images/icons/user.svg",
    path: ROUTES.REGISTRY,
    submenu: [
      // {
      //   identifier: menuIdentifiers.REGISTRY_PERMISSIONS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Registry permissions",
      //       defaultMessage: "Gestione Permessi",
      //     }),
      //   path: ROUTES.REGISTRY_PERMISSIONS,
      // },
      {
        identifier: menuIdentifiers.REGISTRY_POLIFORM_USERS,
        label: intl =>
          intl.formatMessage({
            description: "Sidebar Menu Registry poliform users",
            defaultMessage: "Dipendenti Poliform",
          }),
        path: ROUTES.REGISTRY_POLIFORM_USERS,
      },
      // {
      //   identifier: menuIdentifiers.REGISTRY_BUSINESS_PARTNERS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Registry business partner ",
      //       defaultMessage: "Business Partner",
      //     }),
      //   path: ROUTES.REGISTRY_BUSINESS_PARTNERS,
      // },
      // {
      //   identifier: menuIdentifiers.REGISTRY_PROFESSIONALS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Registry professionals ",
      //       defaultMessage: "Professionals",
      //     }),
      //   path: ROUTES.REGISTRY_PROFESSIONALS,
      // },
      // {
      //   identifier: menuIdentifiers.REGISTRY_PRESS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Registry press ",
      //       defaultMessage: "Press",
      //     }),
      //   path: ROUTES.REGISTRY_PRESS,
      // },
      // {
      //   identifier: menuIdentifiers.REGISTRY_ENDUSERS,
      //   label: intl =>
      //     intl.formatMessage({
      //       description: "Sidebar Menu Registry end users ",
      //       defaultMessage: "End Users",
      //     }),
      //   path: ROUTES.REGISTRY_ENDUSERS,
      // },
    ],
  },
  // DOCUMENTS: {
  //   identifier: menuIdentifiers.DOCUMENTS,
  //   label: intl =>
  //     intl.formatMessage({
  //       description: "Sidebar Menu Documents Main",
  //       defaultMessage: "Documenti",
  //     }),
  //   icon: "/images/icons/folder.svg",
  //   path: ROUTES.DOCUMENTS,
  //   submenu: [
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_CIRCULAR_ARCHIVES,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments circular archive",
  //           defaultMessage: "Archivio Circloari",
  //         }),
  //       path: ROUTES.DOCUMENTS_CIRCULAR_ARCHIVE,
  //     },
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_LISTS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments lists",
  //           defaultMessage: "Listini",
  //         }),
  //       path: ROUTES.DOCUMENTS_LISTS,
  //     },
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_STATEMENTS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments statements",
  //           defaultMessage: "Estratti conto",
  //         }),
  //       path: ROUTES.DOCUMENTS_STATEMENTS,
  //     },
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_ARCHIVES,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments archives",
  //           defaultMessage: "Archivio Packaging List, DDT e Fatture",
  //         }),
  //       path: ROUTES.DOCUMENTS_ARCHIVES,
  //     },
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_STOCK_ORDERS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments stock orders",
  //           defaultMessage: "Giacenza ordini",
  //         }),
  //       path: ROUTES.DOCUMENTS_STOCK_ORDERS,
  //     },
  //     {
  //       identifier: menuIdentifiers.DOCUMENTS_ORDERS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Dcouments orders",
  //           defaultMessage: "Ordini",
  //         }),
  //       path: ROUTES.DOCUMENTS_ORDERS,
  //     },
  //   ],
  // },
  // MARKETING: {
  //   identifier: menuIdentifiers.MARKETING,
  //   label: intl =>
  //     intl.formatMessage({
  //       description: "Sidebar Menu Marketing Main",
  //       defaultMessage: "Marketing",
  //     }),
  //   icon: "/images/icons/tag.svg",
  //   path: ROUTES.MARKETING,
  //   submenu: [
  //     {
  //       identifier: menuIdentifiers.MARKETING_MANUALS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Marketing manuals",
  //           defaultMessage: "Manuali comunicazione",
  //         }),
  //       path: ROUTES.MARKETING_MANUALS,
  //     },
  //     {
  //       identifier: menuIdentifiers.MARKETING_MATERIALS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu Marketing materials",
  //           defaultMessage: "Materiali comunicazioni",
  //         }),
  //       path: ROUTES.MARKETING_MATERIALS,
  //     },
  //   ],
  // },
  // PRESS: {
  //   identifier: menuIdentifiers.PRESS,
  //   label: intl =>
  //     intl.formatMessage({
  //       description: "Sidebar Menu Press Main",
  //       defaultMessage: "Press",
  //     }),
  //   icon: "/images/icons/file.svg",
  //   path: ROUTES.PRESS,
  //   submenu: [
  //     {
  //       identifier: menuIdentifiers.PRESS_COMUNICATIONS,
  //       label: intl =>
  //         intl.formatMessage({
  //           description: "Sidebar Menu press comunications",
  //           defaultMessage: "Comunicati",
  //         }),
  //       path: ROUTES.PRESS_COMUNICATIONS,
  //     },
  //   ],
  // },
  // KPI: {
  //   identifier: menuIdentifiers.KPI,
  //   label: intl =>
  //     intl.formatMessage({
  //       description: "Sidebar Menu KPI Main",
  //       defaultMessage: "KPI",
  //     }),
  //   icon: "/images/icons/analytics.svg",
  //   path: ROUTES.KPI,
  //   submenu: [],
  // },
};
