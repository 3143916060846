export const languages = [
  { id: "IT", name: "Italian" },
  { id: "EN", name: "English" },
];
// export const languages = [
//   { id: "IT", name: "Italian" },
//   { id: "EN", name: "English" },
//   { id: "US", name: "English (US)" },
//   { id: "CN", name: "Cinese" },
//   { id: "DE", name: "Deutsch" },
//   { id: "ES", name: "Spanish" },
//   { id: "FR", name: "French" },
//   { id: "RU", name: "Russian" },
// ];
