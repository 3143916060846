export default {
  EventPrenotationDetailBox: intl => ({
    component: {
      fetchError: intl.formatMessage({
        description: "EventPrenotationDetailBox component fetch error",
        defaultMessage: "Errore di comunicazione con il server",
      }),
      title: intl.formatMessage({
        description: "EventPrenotationDetailBox component title",
        defaultMessage: "Dettaglio Appuntamento",
      }),
      pendingState: intl.formatMessage(
        {
          description:
            "EventPrenotationDetailBox component pending state description",
          defaultMessage: "Il tuo appuntamento è in fase di approvazione",
        },
        { agent: name => <b>{name}</b> }
      ),
      approvedState: intl.formatMessage(
        {
          description:
            "EventPrenotationDetailBox component approval state description",
          defaultMessage: "Il tuo appuntamento è stato confermato",
        },
        { agent: name => <b>{name}</b> }
      ),
      refusedState: intl.formatMessage(
        {
          description:
            "EventPrenotationDetailBox component refused state description",
          defaultMessage: "Il tuo appuntamento è stato rifiutato",
        },
        { agent: name => <b>{name}</b> }
      ),
      locationTitle: intl.formatMessage({
        description: "EventPrenotationDetailBox component location title",
        defaultMessage: "Dove",
      }),
      dateTitle: intl.formatMessage({
        description: "EventPrenotationDetailBox component date title",
        defaultMessage: "Quando",
      }),
      hour: {
        title: intl.formatMessage({
          description: "EventPrenotationDetailBox component hour span title",
          defaultMessage: "A che ora",
        }),
        from: intl.formatMessage({
          description: "EventPrenotationDetailBox component hour span from",
          defaultMessage: "Dalle",
        }),
        to: intl.formatMessage({
          description: "EventPrenotationDetailBox component hour span to",
          defaultMessage: "alle",
        }),
      },
      description: intl.formatMessage({
        description: "EventPrenotationDetailBox component description title",
        defaultMessage: "Più informazioni",
      }),
    },
  }),
};
