import { useContext } from "react";

// constants
import { guestUpdateActions } from "constants/enums";

// context
import { AuthContext } from "context/AuthContext";

const useGuests = () => {
  const { user } = useContext(AuthContext);

  const updateAppointmentGuests = (data, appointmentGuests) => {
    let newGuests = data.guests
      .filter(el => !el.id)
      .map(el => {
        return {
          ...el,
          createdById: user.id,
          action: guestUpdateActions.CREATED,
        };
      });
    appointmentGuests?.map(guest => {
      const found = data.guests.find(element => element.id === guest.id);
      if (found) {
        if (JSON.stringify(guest) === JSON.stringify(found)) {
          newGuests.push({
            ...guest,
            action: guestUpdateActions.NONE,
            createdById: user.id,
          });
        } else if (
          JSON.stringify(guest.email) !== JSON.stringify(found.email)
        ) {
          newGuests.push({
            ...found,
            action: guestUpdateActions.UPDATED,
            createdById: user.id,
          });
        } else {
          newGuests.push({
            ...found,
            action: guestUpdateActions.UPDATED,
            createdById: user.id,
          });
        }
      } else {
        newGuests.push({
          ...guest,
          action: guestUpdateActions.DELETED,
          createdById: user.id,
        });
      }
    });
    return newGuests;
  };

  return { updateAppointmentGuests };
};
export { useGuests };
