import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const SidebarWrapper = styled("div")(({ openmenu, theme }) => ({
  flex: 1,
  maxWidth: openmenu ? "250px" : "70px",
  transition: "all .5s ease",
  overflow: openmenu ? "hidden" : null,
  position: "relative",
  background: theme.palette.secondary.main,
  borderRight: `1px solid ${theme.palette.secondary.dark}`,
  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    width: "100%",
    height: "100vh",
    position: "absolute",
    left: openmenu ? 0 : "-100%",
    overflow: "auto",
    zIndex: 99,
  },
}));

export const SidebarHeader = styled("div")(({ openmenu, theme }) => ({
  height: openmenu ? "50px" : "60px",
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  padding: openmenu ? "10px 40px" : "5px",
  display: "grid",
  alignItems: "center",
}));

export const SidebarMenuItem = styled("li")(({ openmenu, theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  padding: openmenu ? "10px 40px" : "5px",
  listStyle: "none",
  display: "grid",
  position: "relative",
  "&.active": {
    background: theme.palette.background.dark,
  },
  "&:hover": {
    cursor: "pointer",
  },
  "&>p": {
    alignSelf: "center",
  },
  height: !openmenu ? "60px" : null,
  placeContent: openmenu ? null : "center",
  minHeight: "30px",
}));

export const SidebarSubMenuWrapper = styled("ul")(
  ({ opensidebar, opensubmenu, theme, isactive }) => ({
    display: opensubmenu ? "block" : "none",
    height: opensubmenu ? "192px" : "0px",
    padding: !opensidebar ? "10px 20px" : 0,
    margin: 0,
    background: isactive
      ? theme.palette.background.dark
      : theme.palette.secondary.main,
    transition: "all 1s ease",
    position: !opensidebar ? "absolute" : null,
    left: !opensidebar ? "100%" : null,
    width: !opensidebar ? "150px" : null,
    top: !opensidebar ? "-1px" : null,
    overflow: "auto",
    border: !opensidebar ? `1px solid ${theme.palette.secondary.dark}` : null,
    zIndex: 11,
  })
);

export const SidebarSubMenuItem = styled("li")(() => ({
  listStyle: "none",
  padding: "5px 0 0 0",
}));

export const IconContainer = styled("img")(() => ({}));

export const ResponsiveCloseSidebar = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "block",
    position: "absolute",
    right: "20px",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const MainLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  textTransform: "uppercase",
  color: theme.palette.secondary.dark,
  "&.active": {
    color: theme.palette.text.primary,
  },
  "&:hover": {
    fontWeight: "bold",
    // textDecoration: "underline",
    color: theme.palette.text.primary,
  },
}));
export const SubLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  "&.active": {
    color: theme.palette.text.primary,
  },
  "&:hover": {
    fontWeight: "bold",
    // textDecoration: "underline",
    color: theme.palette.text.primary,
  },
}));
