import { userStateSelect } from "constants/enums";
import { ReactComponent as IconSearch } from "assets/icons/ico_search.svg";
import { apiAddresses } from "constants/api";

export const filtersList = [
  { key: "Email", label: "Qualifica", type: "text" },
  {
    key: "comapanyName",
    label: "Azienda",
    type: "text",
  },
  {
    key: "GeoCountryId",
    label: "Nazione",
    type: "autocomplete",
    autocompleteKey: "q",
    endpoint: `${apiAddresses.API_BASE}${apiAddresses.COUNTRIES}`,
  },
  {
    key: "status",
    label: "Categoria",
    type: "select",
    value: userStateSelect,
  },
  {
    key: "sector",
    label: "Settore",
    type: "select",
    value: userStateSelect,
  },
  {
    key: "rating",
    label: "Rating",
    type: "select",
    value: userStateSelect,
  },
  { key: "q", label: "Cerca", type: "text", endIcon: IconSearch },
];
