import { useEffect, useMemo, useState } from "react";
// react  router dom
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  GreyCustomLabel,
  LinearProgress,
  MainLayout,
  EventPrenotationDetailBox,
  BackArrow,
  MainParticipantControl,
  BlackWhiteRoundedButton,
  LoadingDialog,
  TextFieldControl,
} from "components";
import { Button, Grid, Typography } from "@mui/material";

// containers templates
import { ParticipantListControl } from "containers";

// constants
import { apiAppointments } from "constants/api";
import { agendaSlots, formControlNames } from "constants/enums";

// use-http
import useFetch from "use-http";

//react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// date fns
import {
  SCHEDULE,
  SCHEDULE_EVENT_APPOINTMENT_UPDATE,
  SCHEDULE_EVENT_PRENOTATION,
} from "constants/routes";
import { format } from "date-fns";

// hooks
import { useAgendaSchema, useGuests } from "hooks";

// utils
import { parseAppointment } from "utils/Agenda";

const EventDetailAppointment = () => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);

  const { updateAppointmentGuests } = useGuests();

  // schema
  const { appointmentGuestsSchema } = useAgendaSchema();

  let navigate = useNavigate();

  const { state } = useLocation();
  if (!state) {
    return <Navigate to={SCHEDULE} />;
  }

  const { slot: appointmentSlot, event: appointmentEvent } = state;

  const [appointmentDetailData, setAppointmentDetailData] = useState();
  const [appointmentDetailGuests, setAppointmentDetailGuests] = useState();

  const { put: updateGuests, loading: loadingUpdateGuests } = useFetch(
    `${apiAppointments.API_BASE}/${appointmentSlot?.id}${apiAppointments.GUESTS}`
  );

  const {
    data: appointmentDetail,
    loading: loadingAppointmentDetail,
    error,
  } = useFetch(`${apiAppointments.API_BASE}/${appointmentSlot?.id}`, []);

  const [emailResent, setEmailResent] = useState(false);
  const { put: resendEmail, loading: loadingResendEmail } = useFetch(
    `${apiAppointments.API_BASE}/${appointmentSlot?.id}${apiAppointments.RESENDMAIL}`
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return appointmentDetailData
        ? parseAppointment(appointmentDetailData)
        : {};
    }, [appointmentDetailData]),
    resolver: yupResolver(appointmentGuestsSchema),
  });

  useEffect(() => {
    if (appointmentDetail && !error) {
      setAppointmentDetailData(parseAppointment(appointmentDetail));

      setAppointmentDetailGuests(parseAppointment(appointmentDetail).guests);
      reset(parseAppointment(appointmentDetail));
    }
  }, [appointmentDetail]);

  const onSubmit = data => {
    updateGuests(updateAppointmentGuests(data, appointmentDetailGuests)).then(
      () =>
        navigate(
          `${SCHEDULE_EVENT_PRENOTATION}?currDate=${appointmentDetailData.startTime.getTime()}`,
          { state: appointmentEvent }
        )
    );
  };

  const handleResendEmail = () => {
    resendEmail().then(() => setEmailResent(true));
  };

  return (
    <MainLayout>
      <BackArrow
        backFunction={() => {
          navigate(
            `${SCHEDULE_EVENT_PRENOTATION}?currDate=${appointmentDetailData.startTime.getTime()}`,
            { state: appointmentEvent }
          );
        }}
      />
      <Typography variant={"h4"}>{appointmentEvent.name}</Typography>
      {loadingAppointmentDetail && <LinearProgress />}
      {!loadingAppointmentDetail && !error && appointmentDetailData && (
        <>
          <EventPrenotationDetailBox
            slotPrenotationState={appointmentDetailData.status}
            address={appointmentEvent.address}
            date={appointmentDetailData.date}
            slotFromHour={format(appointmentDetailData.startTime, "HH:mm")}
            slotToHour={format(appointmentDetailData.endTime, "HH:mm")}
            description={appointmentEvent.description}
          />
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldControl
                control={control}
                errors={errors}
                name={formControlNames.agenda.note}
                label={i18n.confirmParticipation.note}
                placeholder={i18n.confirmParticipation.note}
                multiline
                minRows={4}
                disabled
              />
            </Grid>
          </Grid>
          <>
            {emailResent && (
              <Typography my={2}>{i18nActions.operationSuccess}</Typography>
            )}
            {!emailResent && (
              <Grid mt={2} item container>
                <BlackWhiteRoundedButton
                  onClick={handleResendEmail}
                  label={i18nActions.resendMail}
                />
              </Grid>
            )}
          </>

          {appointmentDetailData.status === agendaSlots.PENDING && (
            <GreyCustomLabel
              firstLabel={i18n.eventDetailAppointment.manage}
              addLabel={i18nActions.modifyTime}
              handleAdd={() =>
                navigate(SCHEDULE_EVENT_APPOINTMENT_UPDATE, {
                  state: { slot: appointmentSlot, event: appointmentEvent },
                })
              }
            />
          )}
          {appointmentDetailData.status === agendaSlots.CONFIRMED && (
            <GreyCustomLabel firstLabel={i18n.eventDetailAppointment.manage} />
          )}
          <MainParticipantControl
            participant={appointmentDetailData.participant}
            control={control}
            errors={errors}
            disabled
            marginTop
          />
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldControl
                control={control}
                errors={errors}
                name={`toGuest.${formControlNames.agenda.note}`}
                label={i18n.confirmParticipation.participantNote}
                placeholder={i18n.confirmParticipation.participantNote}
                multiline
                minRows={4}
                disabled
              />
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ParticipantListControl
              control={control}
              errors={errors}
              disabled={appointmentDetailData.status === agendaSlots.REJECTED}
            />
            <Grid mt={2} item container style={{ alignItems: "center" }}>
              {appointmentDetailData.status !== agendaSlots.REJECTED && (
                <Grid item>
                  <BlackWhiteRoundedButton
                    type="submit"
                    label={i18nActions.save}
                  />
                </Grid>
              )}
              <Grid item>
                <Link
                  to={`${SCHEDULE_EVENT_PRENOTATION}?currDate=${appointmentDetailData.startTime.getTime()}`}
                  state={appointmentEvent}
                >
                  <Button>{i18nActions.cancel}</Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <LoadingDialog open={loadingUpdateGuests || loadingResendEmail} />
    </MainLayout>
  );
};

export { EventDetailAppointment };
