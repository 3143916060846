import { ReactComponent as IconSearch } from "assets/icons/ico_search.svg";
import { apiAddresses } from "constants/api";

export const filtersList = [
  { key: "Email", label: "Qualifica", type: "text" },
  {
    key: "city",
    label: "Città",
    type: "select",
    endPoint: "",
  },
  {
    key: "GeoCountryId",
    label: "Nazione",
    type: "autocomplete",
    autocompleteKey: "q",
    endpoint: `${apiAddresses.API_BASE}${apiAddresses.COUNTRIES}`,
  },
  { key: "q", label: "Cerca", type: "text", endIcon: IconSearch },
];
