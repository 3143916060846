// context
import { useContext, useState } from "react";
import { AuthContext } from "context/AuthContext";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// components
import { Link, MainLayout, TextFieldControl } from "components/index";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";

// constants
import { apiUser } from "constants/api";
import { EDITPROFILE } from "constants/routes";

// react-hook-form
import { useForm } from "react-hook-form";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// use-http
import { useFetch } from "use-http";

const schema = yup
  .object({
    password: yup.string().required("No password provided."),
    newPassword: yup
      .string()
      .required("No password provided.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,

        "Password is too short - should be 8 chars minimum."
      ),
    newpPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

const ChangePassword = () => {
  // hooks
  const intl = useIntl();
  const i18n = translations.MyArea.Registry.ChangePassword(intl).page;

  // state
  const [changed, setChanged] = useState(false);

  // use http
  const { put, post, loading, response, error } = useFetch();

  // context
  const { user, setUser } = useContext(AuthContext);

  // react hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // use http
  const onSubmit = async data => {
    // eslint-disable-next-line
    const updatedPassword = await put(
      `${apiUser.API_BASE}${apiUser.CHANGE_PASSWORD}`,
      data
    );
    if (response.ok) {
      post(`${apiUser.API_BASE}${apiUser.SIGN_IN}`, {
        email: user.email,
        password: data.newPassword,
      }).then(updatedUser => {
        setUser(updatedUser);
        setChanged(true);
        reset();
      });
    }
  };

  return (
    <MainLayout>
      <Link to={EDITPROFILE}>
        <LeftArrow />
      </Link>
      <Typography variant="h5">{i18n.title}</Typography>
      {changed && !error && <Typography>{i18n.success}</Typography>}
      {error == 400 && (
        <Typography color="error">{i18n.errors.invalidCredentials}</Typography>
      )}
      {error == 500 && (
        <Typography color="error">{i18n.errors.fetchError}</Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mt={5} container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextFieldControl
              control={control}
              name={"password"}
              placeholder={i18n.form.password.label}
              label={i18n.form.password.label}
              errors={errors}
              errorObj={i18n.form.password.errors}
              isRequired
              type={"password"}
              autoComplete={"current-password"}
              endIcon={<EyeIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldControl
              control={control}
              name={"newPassword"}
              placeholder={i18n.form.newPassword.label}
              label={i18n.form.newPassword.label}
              errors={errors}
              errorObj={i18n.form.newPassword.errors}
              isRequired
              type={"password"}
              autoComplete={"new-password"}
              endIcon={<EyeIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldControl
              control={control}
              name={"newPasswordConfirmation"}
              placeholder={i18n.form.newConfirmPassword.label}
              label={i18n.form.newConfirmPassword.label}
              errors={errors}
              errorObj={i18n.form.newConfirmPassword.errors}
              isRequired
              type={"password"}
              autoComplete={"new-password"}
              endIcon={<EyeIcon />}
            />
          </Grid>
          <Grid item xs={12}>
            {!loading && <Button type="submit">{i18n.form.submit}</Button>}
            {loading && <CircularProgress />}
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};
export { ChangePassword };
