import { useContext, useEffect, useState } from "react";

// react mui
import { Box, Typography } from "@mui/material";

// components
import {
  MainLayout,
  LinearProgress,
  BlackWhiteRoundedButton,
} from "components/index";

// react router dom
import { useNavigate } from "react-router-dom";

// use-http
import useFetch from "use-http";

// context
import { AuthContext } from "context/AuthContext";

// constants
import { apiAgenda } from "constants/api";
import { SCHEDULE_MANAGE_EVENTS } from "constants/routes";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

//react-big-calendar
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";

//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};

// hooks
import { useCalendar, useUserRoles, useEvents } from "hooks";

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

import "./Schedule.css";
import { userGroups } from "constants/enums";

const Schedule = () => {
  const { user } = useContext(AuthContext);

  const { parseEvents } = useEvents();

  const navigate = useNavigate();

  const { isLoggedUserInGroup } = useUserRoles();

  // eslint-disable-next-line
  const [locale, setLocale] = useState(user.language);
  const [events, setEvents] = useState([]);

  const intl = useIntl();
  const i18n = translations.Agenda(intl);

  const { loading, error, response, data } = useFetch(
    `${apiAgenda.API_BASE}`,
    []
  );

  const [calendarMessages, components] = useCalendar();

  useEffect(() => {
    if (data && response.ok) {
      setEvents(parseEvents(data.data));
    }
  }, [data, response]);

  return (
    <MainLayout>
      <Typography variant={"h4"}>{i18n.title}</Typography>
      {isLoggedUserInGroup(userGroups.ADMIN) && (
        <Box my={2}>
          <BlackWhiteRoundedButton
            onClick={() => navigate(SCHEDULE_MANAGE_EVENTS)}
            label={i18n.createEvent.handleEvents}
          />
        </Box>
      )}

      {error && <Typography color="error">{i18n.errors.fetchError}</Typography>}
      {loading && <LinearProgress />}
      {response.ok && (
        <div style={{ height: "90%", marginTop: 20 }}>
          <Calendar
            components={components}
            culture={locale}
            localizer={localizer}
            events={events}
            step={120}
            timeslots={1}
            allDayAccessor="allDay"
            style={{ height: "100%" }}
            messages={calendarMessages}
          />
        </div>
      )}
    </MainLayout>
  );
};
// Schedule.propTypes = {};
export { Schedule };
