import { styled } from "@mui/system";

const SlotBase = styled("div")(() => ({
  alignItems: "center",
  height: "100%",
  display: "flex",
  borderRadius: "5px",
  position: "relative",
  padding: "5px",
  margin: "0 5px",
  flex: 1,
  overflow: "auto",
  "&:hover": {
    cursor: "pointer",
  },
  /* Hide scrollbar for Chrome, Safari and Opera */
  "&::-webkit-scrollbar": {
    display: "none",
  },

  /* Hide scrollbar for IE, Edge and Firefox */
  "&": {
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
}));

export const SlotWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  height: "100%",
  border: `1px solid ${theme.palette.agenda.available}`,
  background: theme.palette.background.default,
  color: theme.palette.agenda.available,
  display: "flex",
  borderRadius: "5px",
  position: "relative",
  padding: "5px",
  margin: "0 5px",
  flex: 1,
  overflow: "auto",
  /* Hide scrollbar for Chrome, Safari and Opera */
  "&::-webkit-scrollbar": {
    display: "none",
  },

  /* Hide scrollbar for IE, Edge and Firefox */
  "&": {
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
}));

export const BookedSlotWrapper = styled(SlotBase)(({ theme }) => ({
  background: theme.palette.agenda.occupied,
}));

export const ConfirmedSlotWrapper = styled(SlotBase)(({ theme }) => ({
  backgroundColor: theme.palette.agenda.approved,
  color: "black",
}));

export const PendingSlotWrapper = styled(SlotBase)(({ theme }) => ({
  backgroundColor: theme.palette.agenda.pending,
  color: "black",
}));

export const BackgroundSlotWrapper = styled("div")(() => ({
  alignItems: "center",
  height: "100%",
  border: "1px solid black",
  display: "flex",
  borderRadius: "5px",
  padding: "5px",
  flex: 1,
}));

export const PrenotationIconWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.primary.main,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  fontSize: "1rem",
  margin: "5px",
}));

export const DeleteAvailabilityWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.agenda.occupied,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  position: "absolute",
  top: 0,
  right: 0,
  fontSize: "1rem",
  margin: "5px",
}));
