import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { colors } from "constants/colors";

export default responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 600,
        lg: 1366,
        xl: 1920,
        xxl: 2560,
      },
    },
    palette: {
      primary: {
        main: colors.BLACK,
      },
      secondary: {
        main: colors.GAINSBORO,
        dark: colors.DARKGREY,
      },
      success: {
        main: colors.LIGHTBLUE,
      },
      error: {
        main: colors.RED,
      },
      background: {
        default: colors.WHITE,
        dark: colors.DARKGAINSBORO,
        contrastText: colors.WHITE,
      },
      agenda: {
        occupied: colors.OCCUPIEDSLOT,
        createAvailability: colors.BLACK,
        pending: colors.PENDINGSLOT,
        available: colors.AVAILABLESLOT,
        approved: colors.APPROVEDSLOT,
        multipleAvailabilities: colors.WHITESMOKE,
      },
      text: {
        primary: colors.BLACK,
      },
    },
    typography: {
      fontFamily: `"Alpha-Regular", "Helvetica", "Arial", sans-serif`,
      h5: {
        fontSize: "1.56rem",
        fontWeight: "bold",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: "bold",
      },
      body1: {
        fontSize: "0.78rem",
      },
      body2: {
        fontSize: "0.58rem",
        // textDecoration: "underline",
      },
      button: {
        fontSize: "0.78rem",
        fontWeight: "bold",
        // textDecoration: "underline",
      },
      subtitle1: {
        fontSize: "0.62rem",
      },
    },
    components: {
      // Name of the component
      MuiInputBase: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: 0,
          },
          notchedOutline: {
            borderColor: "none",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            textTransform: "lowercase",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            margin: "16px 0",
            borderRadius: 0,
            boxShadow: "none",

            "&:before": {
              top: 0,
            },
          },
        },
      },
      //MuiAccordionSummary-root.Mui-expanded
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            maxHeight: "48px",
            background: colors.GAINSBORO,
            "&.Mui-expanded": {
              maxHeight: "48px",
              minHeight: "48px",
            },
          },
        },
      },
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MuiDialog: {
        defaultProps: {
          fullWidth: true,
          maxWidth: "sm",
        },
        styleOverrides: {
          paper: {
            borderRadius: 0,
            minHeight: 200,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 20,
            justifyContent: "start",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: "lighter",
            textTransform: "uppercase",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          // Name of the slot
          root: {
            color: colors.BLACK,
            fontWeight: "lighter",
            padding: 0,
            minWidth: "unset",
            // Some CSS
            borderRadius: 0,
            border: "none",
            textDecoration: "underline",
            "&:hover": {
              color: colors.BLACK,
              textDecoration: "underline",
              border: "none",
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
  })
);
