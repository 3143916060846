import PropTypes from "prop-types";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// constants
import { apiSectorOfInterests } from "constants/api";

// use-http
import useFetch from "use-http";

// react mui
import { CircularProgress, Grid, Typography } from "@mui/material";

// components
import {
  TextFieldControl,
  MultiSelectControl,
  SelectControl,
} from "components";

// constants
import { formControlNames } from "constants/enums";
import { languages } from "constants/languages";

const ProfessionalControl = ({ control, errors, fullWidth = false }) => {
  const intl = useIntl();
  const i18n = translations.Commons(intl);
  const language = translations.templates.ProfileForm(intl);
  const professional =
    translations.templates.ProfileForm(intl).page.professional;

  const { data, loading, error } = useFetch(apiSectorOfInterests.API_BASE, []);

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <TextFieldControl
          control={control}
          errors={errors}
          name={formControlNames.registry.name}
          label={i18n.name.label}
          errorObj={i18n.name.errors}
        />
      </Grid>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <TextFieldControl
          control={control}
          errors={errors}
          name={formControlNames.registry.lastname}
          label={i18n.lastname.label}
          errorObj={i18n.lastname.errors}
        />
      </Grid>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <TextFieldControl
          control={control}
          errors={errors}
          name={formControlNames.registry.email}
          label={i18n.email.label}
          errorObj={i18n.email.errors}
        />
      </Grid>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <SelectControl
          control={control}
          name={formControlNames.registry.language}
          placeholder={language.page.anagrafiche.language.label}
          label={language.page.anagrafiche.language.label}
          errors={errors}
          errorObj={language.page.anagrafiche.language.errors}
          isRequired
          options={languages}
        />
      </Grid>
      {/* TODO uncomment this when ready to accept sectors */}
      {1 === 2 && (
        <Grid item xs={12} md={fullWidth ? 12 : 4}>
          {error && <Typography>{i18n.sector.errors.fetchError}</Typography>}
          {loading && <CircularProgress size={25} />}
          {data?.data && (
            <MultiSelectControl
              control={control}
              name={formControlNames.registry.sectors}
              placeholder={professional.sector.label}
              label={professional.sector.label}
              errors={errors}
              errorObj={professional.sector.errors}
              options={data.data}
              fullWidth
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

ProfessionalControl.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export { ProfessionalControl };
