export default {
  Autocomplete: intl => ({
    component: {
      fetchError: intl.formatMessage({
        description: "Autocomplete component fetch error",
        defaultMessage: "Errore di comunicazione con il server",
      }),
      noOptionsText: intl.formatMessage({
        description: "Autocomplete component noOptions text",
        defaultMessage: "Nessun risultato corrispondente",
      }),
      atLeastThree: intl.formatMessage({
        description: "Autocomplete component at least three characters text",
        defaultMessage: "Inserire almeno 3 caratteri",
      }),
    },
    agents: {
      client: {
        label: intl.formatMessage({
          description:
            "Autocomplete for agents or areamanager search client component label",
          defaultMessage: "business partner",
        }),
        noOptions: intl.formatMessage({
          description:
            "Autocomplete for agents or areamanager search client component no results",
          defaultMessage: "Nessun utente trovato",
        }),
        required: intl.formatMessage({
          description:
            "Autocomplete for agents or areamanager component required",
          defaultMessage: "partecipante principale obbligatorio",
        }),
      },
      label: intl.formatMessage({
        description: "Autocomplete for agents or areamanager component label",
        defaultMessage: "area manager/local manager/agente/staff agente",
      }),
      noOptions: intl.formatMessage({
        description:
          "Autocomplete for agents or areamanager component no results",
        defaultMessage: "Nessun utente trovato",
      }),
      required: intl.formatMessage({
        description:
          "Autocomplete for agents or areamanager component required",
        defaultMessage: "agente/area manager obbligatorio",
      }),
    },
    eventPrenotationFilter: {
      label: intl.formatMessage({
        description: "Autocomplete in eventPrenotation Filter",
        defaultMessage: "filtra per utente associato",
      }),
    },
  }),
};
