import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import {
  DatePickerControl,
  GreyCustomLabel,
  TimePickerControl,
} from "components";

import { useIntl } from "react-intl";
import translations from "utils/translations";
import { isValid } from "date-fns";

function TimeSlotsControl({ control, errors, watch }) {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).createEvent;

  const [numberOfParticipants, setNumberOfParticipants] = useState(1);
  // eslint-disable-next-line
  const { fields, append, remove } = useFieldArray({
    control,
    name: "slots",
  });

  // eslint-disable-next-line
  const handleAddParticipants = () => {
    setNumberOfParticipants(numberOfParticipants + 1);
    append({ startDate: "", endDate: "", startTime: "", endTime: "" });
  };

  return (
    <>
      <GreyCustomLabel
        firstLabel={i18n.form.when.label}
        tooltipText={i18n.form.when.tooltip}
        // addLabel={i18n.addParticipants}
        // handleAdd={handleAddParticipants}
      />
      <Grid container spacing={2} mt={2}>
        {/* <Grid item xs={12}>
        <Button onClick={handleAddParticipants}>{i18n.addParticipants}</Button>
      </Grid> */}

        {fields.map((participant, i) => {
          return (
            <>
              <Grid item xs={12} md={3}>
                <DatePickerControl
                  control={control}
                  errors={errors}
                  name={`slots.${i}.startDate`}
                  minDate={new Date()}
                  maxDate={watch(`slots.${i}.endDate`) || null}
                  label={i18n.form.date.startDate}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePickerControl
                  name={`slots.${i}.endDate`}
                  control={control}
                  minDate={watch(`slots.${i}.startDate`) || new Date()}
                  errors={errors}
                  label={i18n.form.date.endDate}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TimePickerControl
                  name={`slots.${i}.startTime`}
                  control={control}
                  maxTime={
                    isValid(watch(`slots.${i}.endTime`))
                      ? watch(`slots.${i}.endTime`)
                      : ""
                  }
                  errors={errors}
                  format={"HH:mm"}
                  label={i18n.form.date.startTime}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TimePickerControl
                  name={`slots.${i}.endTime`}
                  minTime={
                    isValid(watch(`slots.${i}.startTime`))
                      ? watch(`slots.${i}.startTime`)
                      : ""
                  }
                  control={control}
                  errors={errors}
                  label={i18n.form.date.endTime}
                  isRequired
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}

TimeSlotsControl.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
};
export { TimeSlotsControl };
