import { styled } from "@mui/system";
import { LinearProgress } from "@mui/material";

export const CustomLinearProgress = styled(LinearProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 9,
  width: "100%",
});
