import PropTypes from "prop-types";

// constants
import { agendaSlots } from "constants/enums";

const StatusCircle = ({ status }) => {
  return (
    <>
      {status === agendaSlots.PENDING && (
        <svg height="10" width="10">
          <circle fill="#FFC990" r="5" cx="5" cy="5" />
        </svg>
      )}
      {status === agendaSlots.REJECTED && (
        <svg height="10" width="10">
          <circle fill="#E67F83" r="5" cx="5" cy="5" />
        </svg>
      )}
      {status === agendaSlots.APPROVED ||
        (status === agendaSlots.CONFIRMED && (
          <svg height="10" width="10">
            <circle fill="#ACDC87" r="5" cx="5" cy="5" />
          </svg>
        ))}
    </>
  );
};
StatusCircle.propTypes = {
  status: PropTypes.oneOf([
    agendaSlots.APPROVED,
    agendaSlots.AVAILABLE,
    agendaSlots.CONFIRMED,
    agendaSlots.DELETED,
    agendaSlots.OCCUPIED,
    agendaSlots.PENDING,
    agendaSlots.REJECTED,
  ]),
};
export { StatusCircle };
