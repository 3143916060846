import PropTypes from "prop-types";

// react mui
import { Typography } from "@mui/material";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// styled components
import { Content, Header, Wrapper } from "./EventDetailBox.styles";
import { ActionIconManager } from "components";

// react router dom
import { ListIconTypes } from "constants/enums";

const EventDetailBox = ({ address, date, fromHour, toHour, description }) => {
  // hooks
  const intl = useIntl();
  const i18n = translations.components.EventDetailBox(intl).component;

  return (
    <Wrapper>
      <Header>
        <Typography variant="body1" color={"white"} textTransform={"uppercase"}>
          {i18n.title}
        </Typography>
      </Header>
      <Content>
        <Typography variant="body1">
          <Typography variant={"span"} fontWeight={"bolder"}>
            {i18n.locationTitle}
          </Typography>{" "}
          {address.location}, {address.street}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={`https://www.google.com/maps/search/${address.location
              .toLowerCase()
              .replaceAll(" ", "+")},${address.street
              .toLowerCase()
              .replaceAll(" ", "+")}`}
          >
            <ActionIconManager
              listActions={[
                {
                  type: ListIconTypes.MAPLOCATION,
                },
              ]}
            />
          </a>
        </Typography>
        <Typography variant="body1">
          <Typography variant={"span"} fontWeight={"bolder"}>
            {i18n.dateTitle}
          </Typography>{" "}
          {date}
        </Typography>
        <Typography variant="body1">
          <Typography variant={"span"} fontWeight={"bolder"}>
            {i18n.hour.title}
          </Typography>{" "}
          {i18n.hour.from} {fromHour} {i18n.hour.to} {toHour}
        </Typography>
        <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
        <Typography variant="body1" fontWeight={"bolder"}>
          {i18n.description}
        </Typography>{" "}
        <Typography variant="body1">
          <div
            dangerouslySetInnerHTML={{
              __html: description.replaceAll("\n", "<br/>"),
            }}
          />
        </Typography>
      </Content>
    </Wrapper>
  );
};
EventDetailBox.propTypes = {
  address: PropTypes.object,
  date: PropTypes.object,
  fromHour: PropTypes.string,
  toHour: PropTypes.string,
  description: PropTypes.string,
};
export { EventDetailBox };
