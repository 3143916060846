// hooks
import { useUserRoles } from "hooks";

// constants
import { userGroups } from "constants/enums";

// yup
import * as yup from "yup";

yup.addMethod(yup.array, "unique", function (message, mapper = a => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const guests = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required("First Name is required"),
      lastname: yup.string().required("Last Name is required"),
      email: yup.string().trim().email().required("email is required"),
    })
  )
  .unique("duplicate email", a => a.email);

function useAgendaSchema() {
  const { isLoggedUserOneofGroups } = useUserRoles();

  const appointmentGuestsSchema = yup.object({ guests: guests });

  // the schema is conditional based on the user logged id
  const appointmentSchema = isLoggedUserOneofGroups([
    userGroups.ADMIN,
    userGroups.AGENT,
    userGroups.AGENTSTAFF,
    userGroups.AREAMANAGER,
    userGroups.LOCALMANAGER,
  ])
    ? yup
        .object({
          client: yup
            .object()
            .nullable()
            .when("searchRegisteredUser", {
              is: true,
              then: yup.object().required("Client required"),
            }),
          participant: yup
            .object()
            .nullable()
            .when("searchRegisteredUser", {
              is: false,
              then: yup.object().shape({
                name: yup.string().required("First Name is required"),
                lastname: yup.string().required("Last Name is required"),
                email: yup
                  .string()
                  .trim()
                  .email()
                  .required("email is required"),
              }),
            }),
          guests: guests,
        })
        .required()
    : yup.object({
        guests: guests,
      });

  return { appointmentSchema, appointmentGuestsSchema };
}
export { useAgendaSchema };
