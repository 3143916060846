// constants
import { apiAddresses } from "constants/api";
import { userStateSelect } from "constants/enums";

import { useIntl } from "react-intl";
import translations from "utils/translations";

const useEmployeesListFilters = () => {
  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);
  const search = translations.components.FiltersList(intl).send;

  const filtersList = [
    { key: "Email", label: i18n.header.email, type: "text" },
    // { key: "Group", label: "Ruolo", type: "select", value: filterGroup },
    { key: "Group", type: "role" },
    {
      key: "status",
      label: i18n.header.state,
      type: "select",
      value: userStateSelect,
    },
    { key: "MobilePhone", label: i18n.header.mobilePhone, type: "text" },
    {
      key: "GeoCountryId",
      label: i18n.header.country,
      type: "autocomplete",
      autocompleteKey: "q",
      endpoint: `${apiAddresses.API_BASE}${apiAddresses.COUNTRIES}`,
    },
    { key: "q", label: search, type: "text" },
  ];

  return filtersList;
};
export default useEmployeesListFilters;
