import PropTypes from "prop-types";
// react mui
import { Grid } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react router dom
import { useLocation } from "react-router-dom";

// fields
import AnagraficheFields from "./Anagrafiche.fields";

// constants
import { REGISTER } from "constants/routes";

// components
import { TextFieldControl } from "components/index";

function PrivateFields({ control, errors, watch, reset }) {
  // Hooks
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl).page.private;
  const { pathname } = useLocation();

  return (
    <>
      <Grid mt={1} container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"name"}
            placeholder={i18n.privateName.label}
            label={i18n.privateName.label}
            errors={errors}
            errorObj={i18n.privateName.errors}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"surname"}
            placeholder={i18n.privateSurname.label}
            label={i18n.privateSurname.label}
            errors={errors}
            errorObj={i18n.privateSurname.errors}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"email"}
            placeholder={i18n.email.label}
            label={i18n.email.label}
            errors={errors}
            errorObj={i18n.email.errors}
            isRequired
            disabled={pathname !== REGISTER}
          />
        </Grid>

        <AnagraficheFields
          reset={reset}
          control={control}
          errors={errors}
          watch={watch}
        />
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"mobile"}
            placeholder={i18n.mobile.label}
            label={i18n.mobile.label}
            errors={errors}
            errorObj={i18n.mobile.errors}
          />
        </Grid>
      </Grid>
    </>
  );
}

PrivateFields.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  reset: PropTypes.func,
};
export default PrivateFields;
