export default {
  LegalNotes: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Legal Notes page title",
        defaultMessage: `Note legali`,
      }),
      description: intl.formatMessage(
        {
          // id: "privacy.page.title",
          description: "Legal notes page description",
          defaultMessage: `
          <bold>Condizioni di utilizzo</bold>{br} 
          Il sito Internet della Società Poliform® S.p.A. è un servizio di informazioni on-line fornito da Poliform® S.p.A.Entrando in questo sito si dichiara di comprendere e accettare tutti i termini e le condizioni di seguito elencate.Se non si comprendono o non si accettano tali termini e condizioni si è invitati a non utilizzare il sito e a non scaricare alcun materiale dallo stesso. Poliform® S.p.A. si riserva il diritto di modificare i contenuti del sito e delle note legali in qualsiasi momento e senza alcun preavviso{br}
          <bold>Limiti all'utilizzo</bold>{br}
          Tutti i contenuti, quali, a titolo esemplificativo e non limitativo, il know-how, il testo, la grafica, i files, letabelle, le immagini e le informazioni presenti all'interno di questo sito, sono protetti ai sensi della rispettivanormativa in materia diritto d'autore, di brevetti, di marchi e di proprietà intellettuale. Ogni prodotto o societàmenzionati in questo sito sono marchi dei rispettivi proprietari o titolari e possono essere protetti da brevettie/o copyright concessi o registrati dalle autorità preposte. Possono quindi essere scaricati o utilizzati solo peruso personale e non commerciale: pertanto nulla, neppure in parte, potrà essere copiato, modificato orivenduto per fini di lucro o per trarne qualsivoglia utilità.{br}
          <bold>Limiti di responsabilità</bold>{br}
          In nessun caso Poliform® S.p.A. potrà essere ritenuta responsabile dei danni di qualsiasi natura causati direttamente o indirettamente dall’accesso al sito, dall’incapacità o impossibilità di accedervi, dall’affidamento dell’utente e dall’utilizzo dei contenuti. Poliform® S.p.A. provvederà ad inserire nel sito informazioni aggiornate, ma non garantisce circa la loro completezza o accuratezza. Poliform® S.p.A. non è in alcun modo responsabile, e pertanto non fornisce alcuna garanzia in ordine alle informazioni, ai dati, ai riferimenti alle aziende, ed alle eventuali inesattezze tecniche o di altra natura che possano essere contenute presenti su www.poliform.it. In relazione a quanto precede, Poliform® S.p.A. declina ogni responsabilità per danni diretti o indiretti diqualunque natura essi siano o sotto qualunque forma si manifestino, in dipendenza dell'utilizzazione del sitowww.poliform.it e/o delle notizie ed informazioni ivi contenute. Poliform® S.p.A. si riserva il diritto di modificare e/o sopprimere qualunque tipo di informazione e/o programma contenuto nel sito www.poliform.it in qualunque momento e senza alcun preavviso.{br}
          <bold>Accesso a siti esterni collegati</bold>{br}
          Poliform® S.p.A. è esente da qualsiasi responsabilità per quanto riguarda i siti ai quali è possibile accedere tramite i collegamenti posti all’interno del sito stesso. Il fatto che attraverso il sito vengano forniti questi collegamenti non implica per Poliform® S.p.A. alcuna possibilità di controllo sui siti in questione, sulla cui qualità, contenuti e grafica Poliform® S.p.A. declina ogni responsabilità.{br}
          <bold>Download</bold>{br}
          Oggetti, prodotti, programmi e routine eventualmente presenti in questo sito per lo scaricamento (download),quali ad esempio documentazione tecnica o commerciale, software, ecc., salvo contraria o diversa indicazione, sono liberamente e gratuitamente disponibili alle condizioni stabilite da Poliform® S.p.A. Quanto reperibile via download in questo sito può essere coperto da copyright, diritti d’uso e/o copia degli eventuali proprietari; Poliform® S.p.A. invita, pertanto, a verificare condizioni di utilizzo e diritti, e si ritiene esplicitamente sollevata da qualsiasi responsabilità in merito.{br}
          <bold>Informazioni ricevute da Poliform® S.p.A.</bold>{br}
          Poliform® S.p.A. non accetta l’invio di informazioni di carattere confidenziale o riservato attraverso questo sito. Qualsiasi informazione o materiale inviato a Poliform® S.p.A. attraverso questo sito non verrà considerato di natura confidenziale. Poliform® S.p.A. non avrà obblighi di alcun tipo rispetto a tale materiale e sarà libera di riprodurlo, usarlo, rivelarlo, mostrarlo, trasformarlo, farne opere derivate e distribuirlo a terzi senza limiti. Inoltre, Poliform® S.p.A. sarà libera di utilizzare tutte le idee, concetti, know-how o conoscenze tecniche contenute in tale materiale, per qualsiasi scopo, incluso, senza ad esso essere limitato, lo sviluppo, la produzione e commercializzazione di prodotti utilizzanti tale materiale. Chiunque invia materiale garantisce che il medesimo è pubblicabile - in forma anonima salva autorizzazione espressa dell’inviante all’utilizzo del suo nome - ed accetta di tenere indenne Poliform® S.p.A. da qualsiasi azione da parte di terzi in relazione a tale materiale.{br}
          <bold>Legge e giurisdizione</bold>{br}
          L’applicazione della condizioni di utilizzo è regolata dalla legge sostanziale e processuale italiana. Poliform® è un marchio registrato in Italia e all’estero.
          `,
        },
        {
          bold: str => <b>{str}</b>,
          br: (
            <>
              <br />
              <br />
            </>
          ),
        }
      ),
    },
  }),
};
