export default {
  Register: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Register page title",
        defaultMessage: "Registrazione",
      }),
      insertDataTitle: intl.formatMessage({
        description: "Register page tiinsert Data title",
        defaultMessage: "Inserisci i tuoi dati",
      }),
      selectType: {
        label: intl.formatMessage({
          description: "Register page select type label",
          defaultMessage: "tipo utente",
        }),
        errors: {
          required: intl.formatMessage({
            description: "Register page select type Error",
            defaultMessage: "Il campo tipologia è obbligatorio",
          }),
        },
        businessPartner: intl.formatMessage({
          description: "Register page select type Business Partner",
          defaultMessage: "Business Partner",
        }),
        press: intl.formatMessage({
          description: "Register page select type Press",
          defaultMessage: "Press",
        }),
        professional: intl.formatMessage({
          description: "Register page select type Professional",
          defaultMessage: "Professionista",
        }),
        private: intl.formatMessage({
          description: "Register page select type private",
          defaultMessage: "Privato",
        }),
      },
      password: {
        title: intl.formatMessage({
          description: "Register page Password title",
          defaultMessage: "Crea nuova password",
        }),
        errors: {
          required: intl.formatMessage({
            description: "Register page Password error required",
            defaultMessage: "La password è obbligatoria",
          }),
          min: intl.formatMessage({
            description: "Register page Password error minimum",
            defaultMessage:
              "La password è troppo corta, inserire almeno 8 caratteri",
          }),
          matches: intl.formatMessage({
            description: "Register page Password error minimum",
            defaultMessage:
              "La password deve contenere almeno 8 caratteri di cui almeno: una lettera maiuscola, una minuscola e un numero ",
          }),
        },
        label: intl.formatMessage({
          description: "Register page Password label",
          defaultMessage: "Password",
        }),
      },
      confirmPassword: {
        errors: {
          required: intl.formatMessage({
            description: "Register page confirmPassword error required",
            defaultMessage: "La conferma password è obbligatoria",
          }),
          oneOf: intl.formatMessage({
            description: "Register page confirmPassword error oneOf",
            defaultMessage: "Le password non coincidono",
          }),
        },
        label: intl.formatMessage({
          description: "Register page confirmPassword label",
          defaultMessage: "Conferma Password",
        }),
      },
      registerFormSubmit: intl.formatMessage({
        description: "Register page submit button",
        defaultMessage: "Conferma",
      }),
      privacyLink: intl.formatMessage({
        description: "Register page privacy link",
        defaultMessage: "Privacy",
      }),
      legalNotesLink: intl.formatMessage({
        description: "Register page legal notes link",
        defaultMessage: "note legali",
      }),
      informative: {
        errors: {
          oneOf: intl.formatMessage({
            description: "Register page informative error required",
            defaultMessage: "L'informativa è obbligatoria",
          }),
        },
        label: intl.formatMessage({
          description: "Register page informative label",
          defaultMessage:
            "Ai sensi del Dlgs n. 196/2003, vista l'informativa presente sul sito della Società Poliform® S.p.A., acconsento che Questa tratti i miei dati personali, anche trasferendoli a terzi, autorizzandone l'archiviazione informatico/cartacea, al fine di dare corso alla mia richiesta.",
        }),
      },
      fetchError: {
        server: intl.formatMessage({
          description: "Register page fetch error server 500",
          defaultMessage:
            "Errore di comunicazione con il server, riprovare più tardi",
        }),
        userExists: intl.formatMessage({
          description: "Register page fetch error server 409 user exists",
          defaultMessage: "La mail inserita è già presente a sistema",
        }),
      },
    },
  }),
};
