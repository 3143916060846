import PropTypes from "prop-types";

//
import { Link } from "react-router-dom";

// react mui
import { Typography } from "@mui/material";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// styled components
import { SlotWrapper } from "./OccupiedSlot.styles";
import { SCHEDULE_EVENT_APPOINTMENT_DETAIL } from "constants/routes";

const OccupiedSlot = ({ slot, event, appointment }) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).slots.occupiedSlot;

  return (
    <Link
      style={{ flex: 1 }}
      to={SCHEDULE_EVENT_APPOINTMENT_DETAIL}
      state={{
        slot: { ...appointment },
        event: { ...event },
      }}
    >
      <SlotWrapper>
        <Typography lineHeight={1} fontSize={10}>
          {slot.fromDisplayName} - {i18n.label}
        </Typography>
      </SlotWrapper>
    </Link>
  );
};

OccupiedSlot.propTypes = {
  slot: PropTypes.object,
  event: PropTypes.object,
  appointment: PropTypes.object,
};

export { OccupiedSlot };
