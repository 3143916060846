import PropTypes from "prop-types";

// react mui
import { Typography } from "@mui/material";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { SlotTooltip, StatusCircle } from "components";

// styled components
import {
  SlotWrapper,
  BackgroundSlotWrapper,
  PrenotationIconWrapper,
  DeleteAvailabilityWrapper,
  PendingSlotWrapper,
  ConfirmedSlotWrapper,
} from "./FreeSlot.styles";

// react router dom
import { useNavigate } from "react-router-dom";

// constants
import {
  SCHEDULE_EVENT_APPOINTMENT_CREATE,
  SCHEDULE_EVENT_APPOINTMENT_DETAIL,
} from "constants/routes";
import { agendaSlots, calendarDialogActions } from "constants/enums";
import { format } from "date-fns";

const FreeSlot = ({ slot, setCurrentSlot, event, setAction, setOpen }) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).slots;
  const i18nTimeInfo = translations.components.EventDetailBox(intl).component;

  const slotTimeInfo = (
    <p>
      {i18nTimeInfo.hour.from} {format(slot.start, "HH:mm")} <br />
      {i18nTimeInfo.hour.to} {format(slot.end, "HH:mm")}
    </p>
  );
  const navigate = useNavigate();

  const handleDeleteAvailability = e => {
    e.stopPropagation();
    setCurrentSlot(slot);
    setAction(calendarDialogActions.DELETEAVAILABILITY);
    setOpen(true);
  };

  const handleDeleteAppointment = appointment => {
    setCurrentSlot(appointment);
    setAction(calendarDialogActions.DELETEAPPOINTMENT);
    setOpen(true);
  };

  const handleCreateAppointment = e => {
    e.stopPropagation();
    navigate(SCHEDULE_EVENT_APPOINTMENT_CREATE, {
      state: { slot, event },
    });
  };

  if (!slot.exhibitionSlotId) {
    return (
      <BackgroundSlotWrapper
        // eslint-disable-next-line
        onTouchStart={e => {
          setCurrentSlot(slot);
        }}
        onMouseDown={() => {
          setCurrentSlot(slot);
        }}
      ></BackgroundSlotWrapper>
    );
  }

  if (slot.appointments.length >= 1) {
    const notRejectedSlot = slot.appointments.find(
      el => el.status !== agendaSlots.REJECTED
    );

    if (notRejectedSlot) {
      switch (notRejectedSlot.status) {
        case agendaSlots.PENDING:
          return (
            <SlotTooltip
              content={
                <Typography lineHeight={1.1} fontSize={10}>
                  <StatusCircle status={agendaSlots.PENDING} />
                  <b style={{ paddingLeft: 5 }}>
                    {notRejectedSlot.toDisplayName}
                  </b>{" "}
                  - {i18n.pendingSlot.label}
                  <br />
                  {slot.fromDisplayName}
                  {slotTimeInfo}
                </Typography>
              }
            >
              <PendingSlotWrapper
                onClick={() =>
                  navigate(SCHEDULE_EVENT_APPOINTMENT_DETAIL, {
                    state: {
                      slot: { ...notRejectedSlot },
                      event: { ...event },
                    },
                  })
                }
              >
                <DeleteAvailabilityWrapper
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteAppointment(notRejectedSlot);
                  }}
                >
                  ⨯
                </DeleteAvailabilityWrapper>
                <Typography lineHeight={1.1} fontSize={10}>
                  <b>{notRejectedSlot.toDisplayName}</b> -{" "}
                  {i18n.pendingSlot.label}
                  <br />
                  {slot.fromDisplayName}
                </Typography>
              </PendingSlotWrapper>
            </SlotTooltip>
          );
        case agendaSlots.CONFIRMED:
          return (
            <SlotTooltip
              content={
                <Typography lineHeight={1.1} fontSize={10}>
                  <StatusCircle status={agendaSlots.CONFIRMED} />
                  <b style={{ paddingLeft: 5 }}>
                    {notRejectedSlot.toDisplayName}
                  </b>{" "}
                  - {i18n.approvedSlot.label}
                  <br />
                  {slot.fromDisplayName}
                  {slotTimeInfo}
                </Typography>
              }
            >
              <ConfirmedSlotWrapper
                onClick={() =>
                  navigate(SCHEDULE_EVENT_APPOINTMENT_DETAIL, {
                    state: {
                      slot: { ...notRejectedSlot },
                      event: { ...event },
                    },
                  })
                }
              >
                <DeleteAvailabilityWrapper
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteAppointment(notRejectedSlot);
                  }}
                >
                  ⨯
                </DeleteAvailabilityWrapper>
                <Typography lineHeight={1.1} fontSize={10}>
                  <b>{notRejectedSlot.toDisplayName}</b> -{" "}
                  {i18n.approvedSlot.label}
                  <br />
                  {slot.fromDisplayName}
                </Typography>
              </ConfirmedSlotWrapper>
            </SlotTooltip>
          );
        default:
          return (
            <SlotWrapper onClick={handleCreateAppointment}>
              <DeleteAvailabilityWrapper onClick={handleDeleteAvailability}>
                ⨯
              </DeleteAvailabilityWrapper>
              <PrenotationIconWrapper>+</PrenotationIconWrapper>
              <Typography lineHeight={1.1} fontSize={10}>
                {slot.fromDisplayName}
              </Typography>
            </SlotWrapper>
          );
      }
    }
  }

  return (
    <SlotTooltip
      content={
        <Typography lineHeight={1.1} fontSize={10}>
          <b>{slot.fromDisplayName}</b> - {i18n.availableSlot.label}
          {slotTimeInfo}
        </Typography>
      }
    >
      <SlotWrapper onClick={handleCreateAppointment}>
        <DeleteAvailabilityWrapper onClick={handleDeleteAvailability}>
          ⨯
        </DeleteAvailabilityWrapper>
        <PrenotationIconWrapper>+</PrenotationIconWrapper>
        <Typography lineHeight={1.1} fontSize={10}>
          {slot.fromDisplayName}
        </Typography>
      </SlotWrapper>
    </SlotTooltip>
  );
};
FreeSlot.propTypes = {
  slot: PropTypes.object,
  setCurrentSlot: PropTypes.func,
  setAction: PropTypes.func,
  setOpen: PropTypes.func,
  event: PropTypes.object,
};
export { FreeSlot };
