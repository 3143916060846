// context
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// components
import { Link, MainLayout } from "components/index";

// constants
import { userGroups } from "constants/enums";
import { apiUser } from "constants/api";
import { CHANGEPASSWORD } from "constants/routes";

// react-hook-form
import { useForm } from "react-hook-form";

//fields
import PrivateFields from "templates/ProfileForm/Fields/Private.fields";
import PressFields from "templates/ProfileForm/Fields/Press.fields";
import ProfessionalFields from "templates/ProfileForm/Fields/Professional.fields";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// form schema yup
import { BusinessPartnerSchema } from "templates/ProfileForm/Schema/BusinessPartner.schema";
import { AnagraficheSchema } from "templates/ProfileForm/Schema/Anagrafiche.schema";
import { GenericSchema } from "templates/ProfileForm/Schema/Generic.schema";

// use-http
import { useFetch } from "use-http";

// styled components
import { FormContainer } from "./EditProfile.styles";

// utils
import { updateDTO } from "utils/users";

// hooks
import { useUserRoles } from "hooks";
import StaffAgenteLocalManagerFields from "templates/ProfileForm/Fields/StaffAgenteLocalManager.fields";

const EditProfile = () => {
  // hooks
  const intl = useIntl();
  const i18n = translations.MyArea.Registry.EditProfile(intl).page;

  // use http
  const { put, loading, response, error } = useFetch();

  const { isLoggedUserInGroup, isLoggedUserOneofGroups } = useUserRoles();

  // context
  const { user, setUser } = useContext(AuthContext);

  const schema = isLoggedUserOneofGroups([
    userGroups.AGENTSTAFF,
    userGroups.LOCALMANAGER,
  ])
    ? yup
        .object({
          ...BusinessPartnerSchema,
          ...GenericSchema,
        })
        .required()
    : yup
        .object({
          ...BusinessPartnerSchema,
          ...GenericSchema,
          ...AnagraficheSchema,
        })
        .required();
  // react hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name,
      surname: user?.lastname,
      email: user?.email,
      mobile: user?.mobilePhone,
      telephone: user?.phone,
      birthDate: user?.birthdate ? new Date(user?.birthdate) : new Date(),
      nation: user?.address?.geoCountry,
      province: user?.address?.geoProvince,
      city: user?.address?.geoCity,
      postalCode: user?.address?.geoZipCode,
      foreignCity: user?.address?.foreignCity,
      foreignPostalCode: user?.address?.foreignZipCode,
      street: user?.address?.street,
      company: user?.company,
      sectors: user?.sectorsOfInterest?.map(el => el.id) || null,
      category: user?.categories[0]?.id,
      website: user?.website,
      language: user?.language,
      qualification: user?.jobTitle,
    },
  });

  // use http
  const onSubmit = async data => {
    const updatedUser = await put(
      `${apiUser.API_BASE}/${user.id}`,
      updateDTO(data, user)
    );
    if (response.ok) {
      setUser({ ...user, ...updatedUser });
    }
  };

  return (
    <MainLayout>
      <Typography variant="h5">{i18n.title}</Typography>
      <Link to={CHANGEPASSWORD}>
        <Typography textAlign={"right"}>{i18n.changePassword.label}</Typography>
      </Link>
      {response?.ok && <Typography>{i18n.success}</Typography>}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Grid mb={5} container spacing={2}>
          {isLoggedUserInGroup(userGroups.PROFESSIONAL) && (
            <ProfessionalFields
              watch={watch}
              control={control}
              errors={errors}
              reset={reset}
            />
          )}
          {isLoggedUserInGroup(userGroups.PRESS) && (
            <PressFields
              watch={watch}
              control={control}
              errors={errors}
              reset={reset}
            />
          )}
          {isLoggedUserOneofGroups([
            userGroups.AGENTSTAFF,
            userGroups.LOCALMANAGER,
          ]) && (
            <StaffAgenteLocalManagerFields
              watch={watch}
              control={control}
              errors={errors}
              reset={reset}
            />
          )}

          {isLoggedUserOneofGroups([
            userGroups.ENDUSER,
            userGroups.ADMIN,
            userGroups.AGENT,
            userGroups.AREAMANAGER,
          ]) && (
            <PrivateFields
              watch={watch}
              control={control}
              errors={errors}
              reset={reset}
            />
          )}
        </Grid>
        {!loading && <Button type="submit">{i18n.form.submit}</Button>}
        {loading && <CircularProgress />}
        {error && (
          <Typography color="error">{i18n.errors.fetchError}</Typography>
        )}
      </FormContainer>
    </MainLayout>
  );
};
export { EditProfile };
