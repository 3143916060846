import { useMemo } from "react";
import PropTypes from "prop-types";

import * as dates from "date-arithmetic";
import { Navigate } from "react-big-calendar";
import TimeGrid from "react-big-calendar/lib/TimeGrid";

// react big calendar
import { dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";
//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

let numDays;

function MyWeek({
  date,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  numDays = differenceInCalendarDays(max, min) + 1;
  const currRange = useMemo(
    () => MyWeek.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <TimeGrid
      date={date}
      // eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

MyWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

MyWeek.range = (date, { localizer }) => {
  const start = date;
  let end;
  switch (numDays) {
    case 1:
      end = dates.add(start, 0, "day");
      break;
    case 2:
      end = dates.add(start, 1, "day");
      break;
    case 3:
      end = dates.add(start, 2, "day");
      break;
    default:
      end = dates.add(start, 3, "day");
      break;
  }

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

MyWeek.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -4, "day");

    case Navigate.NEXT:
      return localizer.add(date, 4, "day");

    default:
      return date;
  }
};

MyWeek.title = date => {
  // return `${format(date, "iiii dd")}`;
  return `${date.toLocaleDateString()}`;
};

function MyDay({
  date,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  numDays = differenceInCalendarDays(max, min) + 1;
  const currRange = useMemo(
    () => MyDay.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <TimeGrid
      date={date}
      // eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

MyDay.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

MyDay.range = (date, { localizer }) => {
  const start = date;
  let end;
  end = dates.add(start, 0, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

MyDay.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "day");

    case Navigate.NEXT:
      return localizer.add(date, 1, "day");

    default:
      return date;
  }
};

MyDay.title = date => {
  // return `${format(date, "iiii dd")}`;
  return `${date.toLocaleDateString()}`;
};

export default function CustomWeekView() {
  const myWeek = useMemo(() => MyWeek, []);

  return { myWeek };
}
