import { InputLabel, Typography, FormControl, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";

import { CustomSelect } from "./Select.styles";

const Select = ({ ...rest }) => <CustomSelect {...rest} />;

const SelectControl = ({
  control,
  name,
  defaultValue,
  placeholder,
  options,
  disabled,
  label,
  errors,
  errorObj,
  isRequired,
  returnName = false,
  ...rest
}) => {
  // Hooks
  const valueWatch = useWatch({
    control,
    name,
  });
  // State
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  const renderError = (key, errors, errorObj) => {
    if (errors[key] && errors[key].type)
      return (
        <Typography color={"error"}>{errorObj[errors[key].type]}</Typography>
      );
    return null;
  };

  useEffect(() => {
    if (valueWatch === "") {
      setSelectedOptions([]);
    }
  }, [valueWatch]);
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {label}
              {isRequired && "*"}
            </InputLabel>
            <Select
              label={
                <Typography variant="text">
                  {label}
                  {isRequired && "*"}
                </Typography>
              }
              onChange={e => {
                field.onChange(e.target.value);
                setSelectedOptions(e.target.value);
              }}
              value={selectedOptions}
              disabled={disabled}
              placeholder={placeholder}
              {...field}
              {...rest}
            >
              {options.map(o => {
                return (
                  <MenuItem key={o.id} value={returnName ? o.name : o.id}>
                    <Typography fontSize={14}>{o.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      />
      {errors && errorObj && renderError(name, errors, errorObj)}
    </>
  );
};

SelectControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  errorObj: PropTypes.object,
  isRequired: PropTypes.bool,
  returnName: PropTypes.bool,
};

SelectControl.defaultProps = {
  isRequired: false,
};

export { Select, SelectControl };
