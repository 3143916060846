import PropTypes from "prop-types";

// react mui
import { Typography } from "@mui/material";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { Link } from "components";

// constants
import { SCHEDULE_EVENT_APPOINTMENT_DETAIL } from "constants/routes";
import { calendarDialogActions } from "constants/enums";

// styled components
import { DeleteAvailabilityWrapper, SlotWrapper } from "./PendingSlot.styles";

const PendingSlot = ({
  setSelectedSlot,
  setOpen,
  slot,
  event,
  setAction,
  appointment,
}) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).slots.pendingSlot;

  const handleDeleteAppointment = e => {
    e.stopPropagation();
    setSelectedSlot(appointment);
    setAction(calendarDialogActions.DELETEAPPOINTMENT);
    setOpen(true);
  };

  return (
    <SlotWrapper>
      <DeleteAvailabilityWrapper onClick={handleDeleteAppointment}>
        ⨯
      </DeleteAvailabilityWrapper>
      <Link
        style={{ flex: 1, textDecoration: "none" }}
        to={SCHEDULE_EVENT_APPOINTMENT_DETAIL}
        state={{
          slot: { ...appointment },
          event: { ...event },
        }}
      >
        <Typography lineHeight={1.1} fontSize={10}>
          <b>{appointment.toDisplayName}</b> - {i18n.label}
          <br />
          {slot.fromDisplayName}
        </Typography>
      </Link>
    </SlotWrapper>
  );
};
PendingSlot.propTypes = {
  setSelectedSlot: PropTypes.func,
  setOpen: PropTypes.func,
  setAction: PropTypes.func,
  slot: PropTypes.object,
  event: PropTypes.object,
  appointment: PropTypes.object,
};
export { PendingSlot };
