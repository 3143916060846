export default {
  Agenda: intl => ({
    title: intl.formatMessage({
      description: "Agenda main title",
      defaultMessage: "Eventi",
    }),
    calendar: {
      date: intl.formatMessage({
        description: "Agenda calendar date label title",
        defaultMessage: "Data",
      }),
      time: intl.formatMessage({
        description: "Agenda calendar date label time",
        defaultMessage: "Ora",
      }),
      event: intl.formatMessage({
        description: "Agenda calendar date label event",
        defaultMessage: "Evento",
      }),
      allDay: intl.formatMessage({
        description: "Agenda calendar date label allDay",
        defaultMessage: "Tutto il giorno",
      }),
      week: intl.formatMessage({
        description: "Agenda calendar date label week",
        defaultMessage: "Settimana",
      }),
      workWeek: intl.formatMessage({
        description: "Agenda calendar date label workWeek",
        defaultMessage: "Settimana lavorativa",
      }),
      day: intl.formatMessage({
        description: "Agenda calendar date label day",
        defaultMessage: "Giorno",
      }),
      month: intl.formatMessage({
        description: "Agenda calendar date label month",
        defaultMessage: "Mese",
      }),
      yesterday: intl.formatMessage({
        description: "Agenda calendar date label yesterday",
        defaultMessage: "Ieri",
      }),
      tomorrow: intl.formatMessage({
        description: "Agenda calendar date label tomorrow",
        defaultMessage: "Domani",
      }),
      today: intl.formatMessage({
        description: "Agenda calendar date label today",
        defaultMessage: "Oggi",
      }),
      agenda: intl.formatMessage({
        description: "Agenda calendar date label agenda",
        defaultMessage: "Agenda",
      }),
      noEventsInRange: intl.formatMessage({
        description: "Agenda calendar date label noEventsInRange",
        defaultMessage: "Non ci sono eventi in questo range",
      }),
      eventDetail: intl.formatMessage({
        description: "Agenda calendar event detail label",
        defaultMessage: "Dettaglio Evento",
      }),
    },
    slots: {
      approvedSlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot approvedSlot label",
          defaultMessage: "Approvato",
        }),
      },
      availableSlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot availableSlot label",
          defaultMessage: "Disponibile",
        }),
      },
      createAvailabilitySlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot createAvailabilitySlot label",
          defaultMessage: "Crea disponibilità",
        }),
      },
      occupiedSlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot occupiedSlot label",
          defaultMessage: "Occupato",
        }),
      },
      pendingSlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot pendingSlot label",
          defaultMessage: "In Approvazione",
        }),
      },
      refusedSlot: {
        label: intl.formatMessage({
          description: "Agenda calendar slot pendingSlot label",
          defaultMessage: "Rifiutato",
        }),
      },
    },

    dialogs: {
      error: intl.formatMessage({
        description: "Agenda calendar dialogs error",
        defaultMessage: "Errore nell'operazione.",
      }),
      alreadyBookedError: intl.formatMessage({
        description:
          "Agenda calendar dialogs free slot update error already booked",
        defaultMessage:
          "Una disponibilità prenotata non può essere aggiornata.",
      }),
      sameDayError: intl.formatMessage({
        description: "Agenda calendar dialogs free slot update error sameday",
        defaultMessage:
          "La disponibilità non può essere spostata fuori dal giorno originale.",
      }),
      updateFreeSlot: {
        title: intl.formatMessage({
          description: "Agenda calendar dialogs free slot update title",
          defaultMessage: "Aggiornamento disponibilità",
        }),
        originalDescription: intl.formatMessage({
          description:
            "Agenda calendar dialogs free slot update error original availability description",
          defaultMessage: "Disponibilità originale:",
        }),
        newDescription: intl.formatMessage({
          description:
            "Agenda calendar dialogs free slot update error new availability description",
          defaultMessage: "Disponibilità aggiornata:",
        }),
      },
      errorDescription: intl.formatMessage({
        description: "Agenda calendar dialogs error",
        defaultMessage: "L'operazione richiesta non ha avuto successo.",
      }),
      close: intl.formatMessage({
        description: "Agenda calendar dialogs close",
        defaultMessage: "chiudi",
      }),
      showAvailabilities: intl.formatMessage({
        description: "Agenda calendar show more label",
        defaultMessage: "Mostra disponibilità",
      }),
      success: {
        title: intl.formatMessage({
          description: "Agenda calendar dialogs success title",
          defaultMessage: "Complimenti",
        }),
      },
      submit: intl.formatMessage({
        description: "Agenda calendar dialogs submit label",
        defaultMessage: "Ok",
      }),
      createAvailability: intl.formatMessage({
        description: "Agenda calendar dialogs submit label",
        defaultMessage: "Crea disponibilità",
      }),
      createAppointment: intl.formatMessage({
        description: "Agenda calendar dialogs submit label",
        defaultMessage: "Crea appuntamento",
      }),
      deleteAppointment: intl.formatMessage({
        description: "Agenda calendar dialogs submit label",
        defaultMessage: "Eliminazione appuntamento",
      }),
      deleteAppointmentContent: intl.formatMessage({
        description: "Agenda calendar dialogs submit label",
        defaultMessage: "Confermi di voler eliminare l'appuntamento?",
      }),
      deleteAppointmentSuccess: intl.formatMessage({
        description: "Agenda calendar dialogs delete success",
        defaultMessage: "L'appuntamento è stata eliminato.",
      }),
      cancel: intl.formatMessage({
        description: "Agenda calendar dialogs cancel label",
        defaultMessage: "Chiudi",
      }),
      availability: {
        title: intl.formatMessage({
          description: "Agenda calendar dialogs availability title",
          defaultMessage: "Gestisci Slot",
        }),
        success: intl.formatMessage({
          description:
            "Agenda calendar dialogs prenotation success description",
          defaultMessage: "La disponibilità è stata creata correttamente.",
        }),
      },
      prenotation: {
        title: intl.formatMessage({
          description: "Agenda calendar dialogs prenotation title",
          defaultMessage: "Richiesta di appuntamento",
        }),
        success: intl.formatMessage({
          description:
            "Agenda calendar dialogs prenotation success description",
          defaultMessage:
            "La tua richiesta di prenotazione è stata inviata correttamente. Il riferimento Poliform indicato verrà notificato dell appuntamento.",
        }),
      },
      delete: {
        title: intl.formatMessage({
          description: "Agenda calendar dialogs delete title",
          defaultMessage: "Eliminazione disponibilità",
        }),
        content: intl.formatMessage({
          description: "Agenda calendar dialogs delete content",
          defaultMessage:
            "Confermi di voler eliminare la seguente disponibilità?",
        }),
        deleteAction: intl.formatMessage({
          description: "Agenda calendar dialogs delete action",
          defaultMessage: "elimina",
        }),
        deleteSuccess: intl.formatMessage({
          description: "Agenda calendar dialogs delete success",
          defaultMessage: "La disponibilità è stata eliminata.",
        }),
      },
    },
    eventDetail: {
      exportData: intl.formatMessage({
        description: "Agenda eventDetail exportData",
        defaultMessage: "Esporta dati",
      }),
      getDetails: intl.formatMessage({
        description: "Agenda eventDetail get details",
        defaultMessage: "Visualizza dettaglio",
      }),
      getParticipants: intl.formatMessage({
        description: "Agenda eventDetail get details",
        defaultMessage: "Gestisci Partecipanti",
      }),
      sendParticipation: intl.formatMessage({
        description: "Agenda eventDetail send participation",
        defaultMessage: "partecipa",
      }),
      form: {
        compilationError: intl.formatMessage({
          description: "Agenda eventDetail form compilation error",
          defaultMessage: "Errore nella compilazione, ricontrolla i dati",
        }),
      },
      freeAppointmentCta: intl.formatMessage({
        description: "Agenda eventDetail free appointment cta",
        defaultMessage: "Vuoi partecipare? Clicca qui sotto",
      }),
      appointmentCta: intl.formatMessage({
        description: "Agenda eventDetail restricted appointment cta",
        defaultMessage:
          "Vuoi partecipare? Seleziona un orario e sarai ricontattato",
      }),
      availabilityCta: intl.formatMessage({
        description: "Agenda eventDetail restricted availability cta",
        defaultMessage:
          "Crea le tue disponibilità per permettere agli utenti di partecipare o crea un appuntamento",
      }),
      requestAppointment: intl.formatMessage({
        description: "Agenda eventDetail restricted request appointment",
        defaultMessage: "Richiedi appuntamento",
      }),
      requestAvailability: intl.formatMessage({
        description: "Agenda eventDetail restricted request availiability",
        defaultMessage: "GESTISCI SLOT",
      }),
    },
    deleteEvent: {
      title: intl.formatMessage({
        description: "Agenda deleteEvent title",
        defaultMessage: "Elimina evento",
      }),
      content: intl.formatMessage({
        description: "Agenda deleteEvent content",
        defaultMessage: "Sei sicuro di voler eliminare il seguente evento?",
      }),
    },
    createEvent: {
      serverError: intl.formatMessage({
        description: "Agenda createEvent server error",
        defaultMessage:
          "Errore durante la creazione dell'evento, riprovare più tardi.",
      }),
      success: intl.formatMessage({
        description: "Agenda createEvent success",
        defaultMessage: "Evento creato con successo.",
      }),
      title: intl.formatMessage({
        description: "Agenda createEvent title",
        defaultMessage: "Nuovo evento",
      }),
      handleEvents: intl.formatMessage({
        description: "Agenda createEvent title",
        defaultMessage: "Gestisci Eventi",
      }),
      addParticipants: intl.formatMessage({
        description: "Agenda createEvent addParticipants",
        defaultMessage: "Aggiungi",
      }),
      compilationErrors: intl.formatMessage({
        description: "Agenda createEvent compilation errors",
        defaultMessage:
          "Errore nella compilazione, assicurati di aver inserito correttamente i dati",
      }),
      duplicateEmailError: intl.formatMessage({
        description: "Agenda createEvent duplicateEmailError",
        defaultMessage:
          "Errore nella compilazione degli ospiti, email duplicate",
      }),
      form: {
        submit: intl.formatMessage({
          description: "Agenda createEvent form submit",
          defaultMessage: "Salva",
        }),
        cancel: intl.formatMessage({
          description: "Agenda createEvent form cancel",
          defaultMessage: "Annulla",
        }),
        typology: {
          label: intl.formatMessage({
            description: "Agenda createEvent typology  label",
            defaultMessage: "Tipologia",
          }),
          tooltip: intl.formatMessage({
            description: "Agenda createEvent typology  tooltip",
            defaultMessage:
              "Un evento chiuso prevede la partecipazione degli utenti tramite appuntamento. Un evento aperto prevede la partecipazione libera entro l'orario dell'evento.",
          }),
        },
        name: {
          label: intl.formatMessage({
            description: "Agenda createEvent form  namelabel",
            defaultMessage: "Nome",
          }),
          errors: {
            required: intl.formatMessage({
              description: "Agenda createEvent form name errors required",
              defaultMessage: "Il nome dell'evento è obbligatorio",
            }),
          },
        },
        description: {
          label: intl.formatMessage({
            description: "Agenda createEvent form  description",
            defaultMessage: "Descrizione",
          }),
          errors: {
            required: intl.formatMessage({
              description:
                "Agenda createEvent form description errors required",
              defaultMessage: "La descrizione è obbligatoria",
            }),
          },
        },
        checkin: intl.formatMessage({
          description: "Agenda createEvent form  checkin",
          defaultMessage: "Checkin obbligatorio",
        }),
        location: {
          label: intl.formatMessage({
            description: "Agenda createEvent location  label",
            defaultMessage: "Dove",
          }),
          tooltip: intl.formatMessage({
            description: "Agenda createEvent location  tooltip",
            defaultMessage:
              "Inserisci il luogo che ospiterà l'evento e il relativo indirizzo.",
          }),
        },
        when: {
          label: intl.formatMessage({
            description: "Agenda createEvent when  label",
            defaultMessage: "Quando",
          }),
          tooltip: intl.formatMessage({
            description: "Agenda createEvent when  tooltip",
            defaultMessage:
              "Un evento può durare uno o più giorni, e per ogni giorno è possibile decidere l'ora di inizio e di fine.",
          }),
        },
        date: {
          startDate: intl.formatMessage({
            description: "Agenda createEvent date  startDate",
            defaultMessage: "Data di inizio",
          }),
          endDate: intl.formatMessage({
            description: "Agenda createEvent date  endDate",
            defaultMessage: "Data di fine",
          }),
          startTime: intl.formatMessage({
            description: "Agenda createEvent date  startTime",
            defaultMessage: "Ora di inizio",
          }),
          endTime: intl.formatMessage({
            description: "Agenda createEvent date  endTime",
            defaultMessage: "Ora di fine",
          }),
        },
        visibility: {
          label: intl.formatMessage({
            description: "Agenda createEvent visibility label",
            defaultMessage: "Visibilità",
          }),
          tooltip: intl.formatMessage({
            description: "Agenda createEvent visibility tooltip",
            defaultMessage:
              "Un evento pubblico è visibile da tutti gli utenti che accedono all'agenda. Un evento privato è visibile solo dagli utenti che decidi tu.",
          }),
          public: intl.formatMessage({
            description: "Agenda createEvent visibility radio public",
            defaultMessage: "Pubblico",
          }),
          private: intl.formatMessage({
            description: "Agenda createEvent visibility radio private",
            defaultMessage: "Privato",
          }),
        },
        slotTypology: {
          label: intl.formatMessage({
            description: "Agenda createEvent slotTypology label",
            defaultMessage: "Slot Appuntamenti",
          }),
          tooltip: intl.formatMessage({
            description: "Agenda createEvent slotTypology tooltip",
            defaultMessage:
              "Definisci se l'evento ha degli slot di durata libera o fissa.",
          }),
          free: intl.formatMessage({
            description: "Agenda createEvent slotTypology radio free",
            defaultMessage: "Liberi",
          }),
          fixed: intl.formatMessage({
            description: "Agenda createEvent slotTypology radio fixed",
            defaultMessage: "Fissi",
          }),
          durations: {
            label: intl.formatMessage({
              description: "Agenda createEvent slotTypology duration label",
              defaultMessage: "Durata Minima",
            }),
            halfour: intl.formatMessage({
              description: "Agenda createEvent slotTypology duration option 30",
              defaultMessage: "30 minuti",
            }),
            hour: intl.formatMessage({
              description: "Agenda createEvent slotTypology duration option 60",
              defaultMessage: "1 ora",
            }),
            twohours: intl.formatMessage({
              description:
                "Agenda createEvent slotTypology duration option 120",
              defaultMessage: "2 ore",
            }),
          },
        },
      },
    },
    confirmParticipation: {
      expirationError: intl.formatMessage({
        description: "Agenda confirmParticipation expired error",
        defaultMessage: "Il codice del link è scaduto.",
      }),
      success: intl.formatMessage({
        description: "Agenda confirmParticipation success",
        defaultMessage: "La tua risposta è stata inviata correttamente.",
      }),
      note: intl.formatMessage({
        description: "Agenda confirmParticipation note intern",
        defaultMessage: "Note interne",
      }),
      participantNote: intl.formatMessage({
        description: "Agenda confirmParticipation note participant",
        defaultMessage: "Note partecipante",
      }),
    },
    eventCreateAppointment: {
      title: intl.formatMessage({
        description: "Agenda eventCreateAppointment page title",
        defaultMessage: "Nuovo appuntamento",
      }),
      greyLabel: intl.formatMessage({
        description:
          "Agenda eventCreateAppointment page first grey custom label",
        defaultMessage: "APPUNTAMENTO",
      }),
      searchRegisteredUsers: intl.formatMessage({
        description: "Agenda eventCreateAppointment search registered users",
        defaultMessage: "Cerca tra utenti censiti a sistema",
      }),
      partecipant: intl.formatMessage({
        description: "Agenda eventCreateAppointment partecipant label",
        defaultMessage: "Partecipante",
      }),
    },
    eventDetailAppointment: {
      manage: intl.formatMessage({
        description: "Agenda eventDetailAppointment manage",
        defaultMessage: "Gestisci appuntamento",
      }),
      update: {
        title: intl.formatMessage({
          description: "Agenda eventDetailAppointment update page title",
          defaultMessage: "Modifica appuntamento",
        }),
      },
      title: intl.formatMessage({
        description: "Agenda eventDetailAppointment page title",
        defaultMessage: "Dettaglio appuntamento",
      }),
      partecipants: intl.formatMessage({
        description: "Agenda eventDetailAppointment partecipant label",
        defaultMessage: "Partecipante",
      }),
    },
    eventManageParticipant: {
      dialog: {
        approve: {
          content: intl.formatMessage({
            description: "Agenda eventManageParticipant dialog approve content",
            defaultMessage: "L'appuntamento è stato approvato con successo.",
          }),
        },
        refusal: {
          label: intl.formatMessage({
            description: "Agenda eventManageParticipant dialog refusal label",
            defaultMessage: "Motivazione rifiuto",
          }),
          placeholder: intl.formatMessage({
            description: "Agenda eventManageParticipant dialog refusal label",
            defaultMessage: "Motivazione",
          }),
          errorObj: {
            required: intl.formatMessage({
              description:
                "Agenda eventManageParticipant dialog refusal error required",
              defaultMessage: "Inserire una motivazione del rifiuto",
            }),
          },
        },
      },
    },

    errors: {
      fetchError: intl.formatMessage({
        description: "Agenda general fetch error",
        defaultMessage:
          "Errore di comunicazione con il server, riprovare più tardi.",
      }),
    },
    success: intl.formatMessage({
      description: "Agenda  success",
      defaultMessage: "Utente aggiornato correttamente.",
    }),
    appointmentOwner: intl.formatMessage({
      description: "Agenda  appointmentOwner",
      defaultMessage: "Owner appuntamento",
    }),
    appointmentGuest: intl.formatMessage({
      description: "Agenda  appointmentOwner",
      defaultMessage: "Guest",
    }),
  }),
};
