import { Typography } from "@mui/material";

// components
import { Link } from "components";
import { LEGALNOTES, PRIVACY } from "constants/routes";
import { useIntl } from "react-intl";
import translations from "utils/translations";

// styled components
import { PrivacyContainer } from "./PrivacyFooter.styles";

const PrivacyFooter = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.components.PrivacyFooter(intl);

  return (
    <PrivacyContainer>
      <Typography variant={"body1"}>
        <Link to={PRIVACY}>{i18n.privacyLink}</Link> |{" "}
        <Link to={LEGALNOTES}>{i18n.legalNotesLink}</Link>
      </Typography>
    </PrivacyContainer>
  );
};
export { PrivacyFooter };
