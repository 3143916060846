import { useContext } from "react";
//context
import { AuthContext } from "context/AuthContext";
// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react router dom
import { Navigate } from "react-router-dom";

// react-hook-form
import { useForm } from "react-hook-form";

// use-http
import { useFetch } from "use-http";

// mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// constants
import { FORGOTPASSWORD, ROOT } from "constants/routes";

// components
import {
  TextFieldControl,
  Link,
  ReservedAreaCover,
  ReservedAreaTitle,
  PrivacyFooter,
} from "components/index";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";

// custom styles
import { Wrapper, LeftPanel, FormContainer } from "./Login.styles";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// API
import { apiUser } from "constants/api";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const Login = () => {
  // context
  const { user, setUser } = useContext(AuthContext);

  // Hooks
  const intl = useIntl();
  const i18n = translations.Login(intl).page;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // use http
  const { post, response, loading, error: fetchError } = useFetch();

  // if there is a logged user object saved on local or session storage, redirect to myarea
  if (user) {
    return <Navigate to={ROOT} replace />;
  }

  // handlers
  const onSubmit = async data => {
    const userData = await post(`${apiUser.API_BASE}${apiUser.SIGN_IN}`, data);
    if (response.ok) {
      setUser(userData);
    }
  };

  return (
    <Wrapper>
      <LeftPanel>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <ReservedAreaTitle />
            </Grid>
            <Grid item>
              {fetchError && response.status == 401 && (
                <Typography variant={"body1"} color={"error"}>
                  {i18n.fetchError.credential}
                </Typography>
              )}
              {fetchError && response.status == 500 && (
                <Typography variant={"body1"} color={"error"}>
                  {i18n.fetchError.server}
                </Typography>
              )}
              {!fetchError && !loading && (
                <Typography fontSize={18} variant={"body1"}>
                  {i18n.loginAction}
                </Typography>
              )}
            </Grid>
            {!loading && (
              <>
                <Grid item>
                  <TextFieldControl
                    control={control}
                    name={"email"}
                    placeholder={i18n.loginFormEmail.label}
                    label={i18n.loginFormEmail.label}
                    errors={errors}
                    errorObj={i18n.loginFormEmail.errors}
                    isRequired
                  />
                </Grid>
                <Grid item>
                  <TextFieldControl
                    control={control}
                    name={"password"}
                    placeholder={i18n.loginFormPassword.label}
                    label={i18n.loginFormPassword.label}
                    errors={errors}
                    errorObj={i18n.loginFormPassword.errors}
                    isRequired
                    autoComplete={"current-password"}
                    type={"password"}
                    endIcon={<EyeIcon />}
                  />
                </Grid>
                <Grid sx={{ alignSelf: "self-end" }} item>
                  <Link to={FORGOTPASSWORD}>
                    <Typography fontSize={16} variant={"body2"}>
                      {i18n.loginFormRetrievePassword}
                    </Typography>
                  </Link>
                </Grid>

                <Grid item>
                  <Button
                    style={{ fontSize: 16 }}
                    variant="outlined"
                    type="submit"
                  >
                    {i18n.loginFormSubmit}
                  </Button>
                </Grid>
                <Grid item>
                  {/* <Typography variant="body1">
                    {i18n.loginRegisterInvitation}{" "}
                    <Link to={REGISTER}>{i18n.loginRegister}</Link>
                  </Typography> */}
                </Grid>
              </>
            )}
            {loading && (
              <Grid item>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </FormContainer>
        <PrivacyFooter />
      </LeftPanel>
      <ReservedAreaCover />
    </Wrapper>
  );
};
export { Login };
