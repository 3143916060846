import { useContext, useState } from "react";
// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// context
import { AuthContext } from "context/AuthContext";

// react router dom
import { useNavigate } from "react-router-dom";

// react-hook-form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// use-http
import { useFetch } from "use-http";

// mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// utils
import { registerDTO } from "utils/users";

// components
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import {
  TextFieldControl,
  Link,
  CheckBoxControl,
  PoliformLogo,
} from "components/index";

//routes
import { ROOT } from "constants/routes";

// custom styles
import { Wrapper, Panel } from "./Register.styles";
import { SelectControl } from "components/index";

// form conditional fields
import PressFields from "templates/ProfileForm/Fields/Press.fields";
import ProfessionalFields from "templates/ProfileForm/Fields/Professional.fields";
import PrivateFields from "templates/ProfileForm/Fields/Private.fields";

// form schema yup
import { BusinessPartnerSchema } from "templates/ProfileForm/Schema/BusinessPartner.schema";
import { AnagraficheSchema } from "templates/ProfileForm/Schema/Anagrafiche.schema";
import { GenericSchema } from "templates/ProfileForm/Schema/Generic.schema";

// API
import { apiUser } from "constants/api";
import { PrivacyPopup, LegalNotesPopup } from "pages/index";

const schema = yup
  .object({
    password: yup
      .string()
      .required("No password provided.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,

        "Password is too short - should be 8 chars minimum."
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    informative: yup
      .boolean()
      .oneOf([true], "Must Accept Terms and Conditions"),
    ...BusinessPartnerSchema,
    ...GenericSchema,
    ...AnagraficheSchema,
  })
  .required();

const Register = () => {
  // context
  const { setUser } = useContext(AuthContext);

  // State
  const [typeSelected, setTypeSelected] = useState("");
  const [privacyPopup, setPrivacyPopup] = useState(false);
  const [legalNotesPopup, setLegalNotesPopup] = useState(false);

  // Hooks
  const { post, response, loading, error: fetchError } = useFetch();
  const navigate = useNavigate();
  const intl = useIntl();
  const i18n = translations.Register(intl).page;
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // "select-type": "private",
      nation: "",
      city: "",
      province: "",
      postalCode: "",
      foreignCity: "",
      foreignPostalCode: "",
      street: "",
    },
  });
  console.log("C.LOG ~ file: index.js ~ line 108 ~ errors", errors);

  // select options
  const selectOptions = [
    {
      id: "press",
      name: i18n.selectType.press,
    },
    {
      id: "professional",
      name: i18n.selectType.professional,
    },
    {
      id: "private",
      name: i18n.selectType.private,
    },
  ];

  watch((data, { name }) => {
    if (name == "select-type") {
      setTypeSelected(data[name]);
      reset({ "select-type": data[name] });
    }
  });

  const onSubmit = async data => {
    // eslint-disable-next-line
    const newUser = await post(
      `${apiUser.API_BASE}${apiUser.SIGN_UP}`,
      registerDTO(data)
    );
    if (response.ok) {
      post(`${apiUser.API_BASE}${apiUser.SIGN_IN}`, {
        email: data.email,
        password: data.password,
      }).then(loggedInUser => {
        setUser(loggedInUser);
        navigate(ROOT, { replace: true });
      });
    }
  };
  return (
    <Wrapper>
      <Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid p={0} container direction="column" minWidth={"100%"}>
            <Grid item xs={12}>
              <PoliformLogo />
            </Grid>
            <hr style={{ width: "100%" }} />
            <Grid mb={5} container spacing={2}>
              <Grid item xs={12}>
                <Typography my={2} variant={"h5"}>
                  {i18n.title}
                </Typography>
              </Grid>
              {fetchError && response.status == 500 && (
                <Grid item xs={12}>
                  <Typography variant={"body1"} color={"error"}>
                    {i18n.fetchError.server}
                  </Typography>
                </Grid>
              )}
              {fetchError && response.status == 409 && (
                <Grid item xs={12}>
                  <Typography variant={"body1"} color={"error"}>
                    {i18n.fetchError.userExists}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <SelectControl
                  control={control}
                  name={"select-type"}
                  label={i18n.selectType.label}
                  errors={errors}
                  errorObj={i18n.selectType.errors}
                  options={selectOptions}
                  isRequired
                  fullWidth
                />
              </Grid>
            </Grid>
            {!typeSelected == "" && (
              <Typography variant={"h6"}>{i18n.insertDataTitle}</Typography>
            )}
            {typeSelected == "professional" && (
              <ProfessionalFields
                reset={reset}
                watch={watch}
                control={control}
                errors={errors}
              />
            )}
            {typeSelected == "press" && (
              <PressFields
                reset={reset}
                watch={watch}
                control={control}
                errors={errors}
              />
            )}
            {typeSelected == "private" && (
              <PrivateFields
                reset={reset}
                watch={watch}
                control={control}
                errors={errors}
              />
            )}
            {!typeSelected == "" && (
              <>
                <Grid
                  mt={1}
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant={"h6"}>
                      {i18n.password.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextFieldControl
                      control={control}
                      name={"password"}
                      placeholder={i18n.password.label}
                      label={i18n.password.label}
                      errors={errors}
                      errorObj={i18n.password.errors}
                      isRequired
                      type={"password"}
                      endIcon={<EyeIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextFieldControl
                      control={control}
                      name={"passwordConfirmation"}
                      placeholder={i18n.confirmPassword.label}
                      label={i18n.confirmPassword.label}
                      errors={errors}
                      errorObj={i18n.confirmPassword.errors}
                      isRequired
                      type={"password"}
                      endIcon={<EyeIcon />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PrivacyPopup open={privacyPopup} setOpen={setPrivacyPopup} />
                  <Link to={"#"}>
                    <Typography
                      variant={"body1"}
                      onClick={() => setPrivacyPopup(true)}
                    >
                      {i18n.privacyLink}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <LegalNotesPopup
                    open={legalNotesPopup}
                    setOpen={setLegalNotesPopup}
                  />
                  <Link to={"#"}>
                    <Typography
                      variant={"body1"}
                      onClick={() => setLegalNotesPopup(true)}
                    >
                      {i18n.legalNotesLink}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CheckBoxControl
                    control={control}
                    name={"informative"}
                    placeholder={i18n.informative.label}
                    label={i18n.informative.label}
                    errors={errors}
                    errorObj={i18n.informative.errors}
                    isRequired
                  />
                </Grid>
                {!loading && (
                  <Grid item>
                    <Button type="submit">{i18n.registerFormSubmit}</Button>
                  </Grid>
                )}
                {loading && <CircularProgress />}
              </>
            )}
          </Grid>
        </form>
      </Panel>
    </Wrapper>
  );
};
export { Register };
