import { Typography } from "@mui/material";

export const createColumns = (
  columnObj,
  currentOrderState,
  currentOrderIdState,
  setCurrentOrder,
  setCurrentOrderId
) => {
  return columnObj.map(el => {
    return {
      Header: p => (
        <Typography
          variant="body1"
          fontWeight={"bolder"}
          onClick={() => {
            handleSortBy(
              p.column.id,
              currentOrderState,
              currentOrderIdState,
              setCurrentOrder,
              setCurrentOrderId
            );
          }}
        >
          {el.name || ""}{" "}
          {currentOrderIdState === p.column.id &&
            (currentOrderState === "ASC" ? "🠕" : "🠗")}
        </Typography>
      ),
      accessor: el.accessor,
      Cell: el.Cell ? el.Cell : p => p.value || "",
      minWidth: 140,
      width: 200,
    };
  });
};

const handleSortBy = (
  id,
  currentOrder,
  currentOrderId,
  setCurrentOrder,
  setCurrentOrderId
) => {
  if (currentOrderId === id) {
    switch (currentOrder) {
      case "":
        setCurrentOrder("ASC");
        break;
      case "ASC":
        setCurrentOrder("DESC");
        break;
      case "DESC":
        setCurrentOrderId("");
        setCurrentOrder("");
        break;
      default:
        setCurrentOrder("ASC");
        break;
    }
  } else {
    setCurrentOrderId(id);
    setCurrentOrder("ASC");
  }
};
