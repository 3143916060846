import { useState } from "react";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

import PropTypes from "prop-types";

// react mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// date-fns
import { format } from "date-fns";

// styled components
import { MultiplePanelLabel } from "./ShowMore.styles";

const ShowMore = ({ slot, children }) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).dialogs;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MultiplePanelLabel onClick={() => setOpen(true)}>
        <Typography color={"black"} lineHeight={1}>
          {i18n.showAvailabilities}
        </Typography>
      </MultiplePanelLabel>
      {open && (
        <>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              <Typography
                style={{ flex: 1 }}
                color={"black"}
                textAlign={"center"}
              >
                {format(slot.start, "HH:mm")} - {format(slot.end, "HH:mm")}
              </Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions onClick={handleClose}>
              <Button> {i18n.close}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
ShowMore.propTypes = {
  slot: PropTypes.object,
  children: PropTypes.node,
};

export { ShowMore };
