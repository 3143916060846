import { styled } from "@mui/system";

export const EventWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  position: "relative",
  padding: "5px",
  borderRadius: "5px",
  flex: 1,
}));

export const EventActionsWrapper = styled("div")(() => ({
  position: "absolute",
  top: "10px",
  right: "20px",
  display: "flex",
}));

export const EventActionsIconContainer = styled("div")(() => ({
  padding: "20px",
  borderRadius: "50%",
  background: "white",
  width: "20px",
  height: "20px",
  display: "grid",
  placeContent: "center",
  margin: "0 5px",
}));
