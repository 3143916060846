import PropTypes from "prop-types";

// react mui
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// constants
import { apiAvailabilities } from "constants/api";

// date-fns

import { format } from "date-fns";
const DeleteAvailabilityDialog = ({
  del,
  creationError,
  handleClose,
  loading,
  setLoading,
  success,
  setSuccess,
  error,
  setError,
  slot,
}) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).dialogs;

  const deleteAvailability = () => {
    setLoading(true);
    // create an availability for the agent for tthe selected slot
    del(`${apiAvailabilities.API_BASE}/${slot.id}`)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      {success && !creationError && (
        <DialogTitle>{i18n.success.title}</DialogTitle>
      )}
      {creationError && <DialogTitle>{i18n.error}</DialogTitle>}
      {!success && !error && <DialogTitle>{i18n.delete.title}</DialogTitle>}
      <DialogContent>
        {loading && <CircularProgress />}
        {!success && !loading && slot && (
          <>
            <Typography>{i18n.delete.content}</Typography>
            <Typography mb={1}>{slot?.fromDisplayName}</Typography>
            <Typography mb={1}>{format(slot?.start, "dd/MM/yyyy")}</Typography>
            <Typography mb={1}>
              {format(slot.start, "HH:mm")} - {format(slot.end, "HH:mm")}
            </Typography>
          </>
        )}
        {success && !loading && !creationError && (
          <>
            <Typography>{i18n.delete.deleteSuccess}</Typography>
          </>
        )}
        {!loading && creationError && (
          <>
            <Typography color="error">{i18n.error}</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!success && !loading && (
          <>
            <Button onClick={handleClose}>{i18n.cancel}</Button>
            <Button onClick={deleteAvailability}>
              {i18n.delete.deleteAction}
            </Button>
          </>
        )}
        {success && <Button onClick={handleClose}>{i18n.submit}</Button>}
      </DialogActions>
    </>
  );
};
DeleteAvailabilityDialog.propTypes = {
  del: PropTypes.func,
  handleClose: PropTypes.func,
  creationError: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  slot: PropTypes.object,
};
export default DeleteAvailabilityDialog;
