import { useCallback, useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";

// context
import { AuthContext } from "context/AuthContext";

// components
import {
  FreeSlotsDialog,
  FreeSlotsUpdateDialog,
  GreyCustomLabel,
} from "components";

// react big calendar
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";

//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// constants
import { calendarDialogActions } from "constants/enums";

// hooks
import { useCalendar, useCalendarSlots } from "hooks";
import { isDraggedSlotInEventRange } from "utils/Agenda";

import CustomWeekView from "./CustomWeekView";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const FreeSlotCalendar = ({
  event,
  freeSlots,
  dragSlots,
  setOpen,
  open,
  openUpdateDialog,
  setOpenUpdateDialog,
  action,
  setAction,
  currDate,
}) => {
  const { user } = useContext(AuthContext);

  // eslint-disable-next-line
  const [locale, setLocale] = useState(user.language);
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(
    currDate ? new Date(parseInt(currDate)) : event.start
  );

  const [currentSlot, setCurrentSlot] = useState();

  const [calendarMessages] = useCalendar();

  const [newDraggedSlot, setNewDraggedSlot] = useState();

  const { myWeek } = CustomWeekView();

  // update dialog states
  const [originalAvailability, setOriginalAvailability] = useState();
  const [newStartHour, setNewStartHour] = useState();
  const [newEndHour, setNewEndHour] = useState();

  // REFACTOR
  const [baseBackgroundEvents, setBaseBackgroundEvents] = useState(freeSlots);
  const [baseEvents, setBaseEvents] = useState(dragSlots);
  const [isSelectingAppointment, setIsSelectingAppointment] = useState(false);

  const onNavigate = useCallback(
    newDate => {
      const MIN = new Date(event.start);
      const MAX = new Date(event.end);
      if (newDate >= MIN && newDate <= MAX) {
        setCurrentDate(newDate);
      } else if (newDate < MIN) {
        setCurrentDate(MIN);
      }
    },
    [currentDate]
  );
  const onNavigateAvailability = useCallback(
    newDate => {
      const MIN = new Date(baseBackgroundEvents[0].start);
      const MAX = new Date(baseBackgroundEvents[0].end);
      if (newDate < MIN) {
        setCurrentDate(MIN);
      } else if (newDate > MAX) {
        setCurrentDate(MAX);
      } else {
        setCurrentDate(newDate);
      }
    },
    [currentDate]
  );

  const handleResetView = () => {
    setNewDraggedSlot();
    setCurrentSlot();
    setBaseBackgroundEvents(freeSlots);
    setBaseEvents(dragSlots);
    setIsSelectingAppointment(false);
  };

  const handleUpdateDialog = (event, start, end) => {
    setOriginalAvailability(event);
    setNewStartHour(start);
    setNewEndHour(end);
    setOpenUpdateDialog(true);
  };

  const { componentsFreeSlots } = useCalendarSlots({
    event,
    setOpen,
    setCurrentSlot,
    setAction,
  });

  useEffect(() => {
    if (!open) {
      handleResetView();
    }
  }, [open, dragSlots, freeSlots, openUpdateDialog]);

  return (
    <>
      <div style={{ maxHeight: "90%" }}>
        {!isSelectingAppointment && (
          <DragAndDropCalendar
            components={componentsFreeSlots}
            culture={locale}
            localizer={localizer}
            dayLayoutAlgorithm={"no-overlap"}
            backgroundEvents={baseBackgroundEvents}
            events={baseEvents}
            onEventDrop={({ event, start, end }) => {
              handleUpdateDialog(event, start, end);
            }}
            onEventResize={({ event, start, end }) => {
              handleUpdateDialog(event, start, end);
            }}
            startAccessor="start"
            onSelectEvent={event => {
              setCurrentSlot(event);
              if (!event.booked) {
                setOpen(true);
              }

              // if (event.fromId) {
              //   setBaseBackgroundEvents([event]);
              //   setCurrentDate(event.start);
              //   setBaseEvents([]);
              //   setIsSelectingAppointment(true);
              // }
            }}
            onSelectSlot={e => {
              if (isDraggedSlotInEventRange(e, event)) {
                setNewDraggedSlot({
                  start: e.start,
                  end: e.end,
                  title: "PRENOTAZIONE IN CORSO",
                  allDay: false,
                  availability: "AVAILABLE",
                });
                setAction(calendarDialogActions.CREATEAVAILABILITY);
                setOpen(true);
              }
            }}
            endAccessor="end"
            selectable={"ignoreevents"}
            step={event.slotDuration}
            timeslots={1}
            allDayAccessor="allDay"
            // views={["week"]}
            views={{
              week: myWeek,
              day: true,
            }}
            defaultView={currDate ? "day" : "week"}
            min={new Date(event.start)}
            max={new Date(event.end)}
            date={new Date(currentDate)}
            onNavigate={onNavigate}
            // resizable={true}
            messages={calendarMessages}
          />
        )}
        {/* OLD CASE- if we click on a slot, the visual expands to DAY view and ranging for the slot duration
        giving the opportunity to handle a Drag'n'Drop inside the availability slot
      */}
        {isSelectingAppointment && (
          <>
            <GreyCustomLabel
              firstLabel={currentSlot?.fromDisplayName}
              tooltipText={
                "Trascina per selezionare una fascia oraria di appuntamento"
              }
              addLabel={"annulla"}
              handleAdd={() => {
                handleResetView();
                setCurrentDate(event.start);
              }}
            />
            <div style={{ marginTop: "1rem" }} />
            <DragAndDropCalendar
              components={componentsFreeSlots}
              culture={locale}
              localizer={localizer}
              backgroundEvents={baseBackgroundEvents}
              events={baseEvents}
              startAccessor="start"
              endAccessor="end"
              onDragStart={({ event }) => {
                setCurrentSlot(event);
              }}
              onSelectSlot={e => {
                if (isDraggedSlotInEventRange(e, event)) {
                  setNewDraggedSlot({
                    start: e.slots[0],
                    end: e.slots[e.slots.length - 1],
                    title: "PRENOTAZIONE IN CORSO",
                    allDay: false,
                    availability: "AVAILABLE",
                  });
                  // setCurrentSlot(newDraggedSlot);
                  setOpen(true);
                }
              }}
              selectable={"ignoreEvents"}
              step={event.slotDuration}
              timeslots={1}
              allDayAccessor="allDay"
              views={["day"]}
              view={"day"}
              min={new Date(baseBackgroundEvents[0].start)}
              max={new Date(baseBackgroundEvents[0].end)}
              date={new Date(currentDate)}
              onNavigate={onNavigateAvailability}
              resizable={false}
              messages={calendarMessages}
            />
          </>
        )}
      </div>
      {(newDraggedSlot || currentSlot) && (
        <FreeSlotsDialog
          open={open}
          onClose={() => setOpen(false)}
          mainSlot={currentSlot}
          slot={newDraggedSlot}
          event={event}
          action={action}
        />
      )}
      <FreeSlotsUpdateDialog
        open={openUpdateDialog}
        onClose={setOpenUpdateDialog}
        originalAvailability={originalAvailability}
        newStartHour={newStartHour}
        newEndHour={newEndHour}
      />
    </>
  );
};
FreeSlotCalendar.propTypes = {
  event: PropTypes.object,
  freeSlots: PropTypes.array,
  dragSlots: PropTypes.array,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setOpenUpdateDialog: PropTypes.func,
  openUpdateDialog: PropTypes.bool,
  action: PropTypes.string,
  setAction: PropTypes.func,
  currDate: PropTypes.string,
};
export { FreeSlotCalendar };
