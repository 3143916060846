import { Typography } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

function ReservedAreaTitle() {
  // Hooks
  const intl = useIntl();
  const reservedAreaTitle = translations.Home(intl);

  return (
    <Typography
      textTransform={"uppercase"}
      fontSize={24}
      style={{
        position: "absolute",
        top: 70,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {reservedAreaTitle.page.reservedArea}
    </Typography>
  );
}
export { ReservedAreaTitle };
