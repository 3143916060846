import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { colors } from "constants/colors";

export const BlackButton = styled(Button)(() => ({
  marginRight: 10,
  backgroundColor: colors.BLACK,
  color: colors.WHITE,
  borderRadius: 20,
  minWidth: 100,
  padding: "5px 20px",
  boxShadow: "none",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
  },
}));
