import styled from "@emotion/styled";

export const Wrapper = styled("div")(() => ({
  display: "inline",
  "&> *": {
    marginRight: 5,
    width: 30,
    height: 30,
  },
  "&:hover": {
    cursor: "pointer",
  },
}));
