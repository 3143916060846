import { useContext } from "react";

import { useIntl } from "react-intl";
import translations from "utils/translations";

import { agendaSlots } from "constants/enums";

import { AuthContext } from "context/AuthContext";
import { ActionIconManager, StatusCircle } from "components";
// eslint-disable-next-line
const useHeader = ({ setAction, setOpen, setAppointment, mainEvent }) => {
  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);
  const i18nCommons = translations.Commons(intl);
  const i18nSlots = translations.Agenda(intl).slots;
  // eslint-disable-next-line
  const { user } = useContext(AuthContext);

  const header = [
    // {
    //   name: i18n.header.requestDate,
    //   accessor: "requestDate",
    //   //Cell: p => <>cdsjunh {p.row.original.id}</>,
    // },
    {
      name: i18n.header.email,
      accessor: "email",
    },
    {
      name: i18n.header.nameLastname,
      accessor: "displayName",
    },
    {
      name: i18n.header.typologies,
      accessor: "group",
    },
    {
      name: i18n.header.category,
      accessor: "jobTitle",
    },
    {
      name: i18n.header.invited,
      accessor: "isInvited",
      Cell: element =>
        element.value == true ? i18nCommons.yes : i18nCommons.no,
    },
    {
      name: i18n.header.state,
      accessor: "status",
      Cell: element => {
        return (
          <>
            <StatusCircle status={element.value} />{" "}
            {element.value == agendaSlots.PENDING && (
              <>{i18nSlots.pendingSlot.label}</>
            )}
            {element.value == agendaSlots.REJECTED && (
              <>{i18nSlots.refusedSlot.label}</>
            )}
            {element.value == agendaSlots.APPROVED ||
              (element.value == agendaSlots.CONFIRMED && (
                <>{i18nSlots.approvedSlot.label}</>
              ))}
          </>
        );
      },
    },
    {
      // Header: "Cognome",
      accessor: "editrights", // accessor is the "key" in the data
      // eslint-disable-next-line
      Cell: element => (
        <ActionIconManager
          listActions={
            [
              // {
              //   type: ListIconTypes.CHECK,
              //   callback: () => {
              //     setAppointment(element.row.original);
              //     setAction(eventManageParticipant.APPROVE);
              //     setOpen(true);
              //   },
              // },
              // {
              //   type: ListIconTypes.DELETE,
              //   callback: () => {
              //     setAppointment(element.row.original);
              //     setAction(eventManageParticipant.REFUSE);
              //     setOpen(true);
              //   },
              // },
              // {
              //   type: ListIconTypes.CALENDAR,
              //   callback: () => {
              //     navigate(SCHEDULE_EVENT_APPOINTMENT_DETAIL, {
              //       state: {
              //         slot: { id: element.row.original.appointmentId },
              //         event: mainEvent,
              //       },
              //     });
              //   },
              // },
              // {
              //   type: ListIconTypes.CALENDAR,
              //   callback: () => {
              //     console.log("TODO: calendar", element.row.original);
              //   },
              // },
            ]
          }
        />
      ),
    },
  ];

  return [header];
};

export default useHeader;
