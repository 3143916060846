import { useContext } from "react";

// react router dom
import { useLocation, useNavigate } from "react-router-dom";

// react mui
import { Button, Grid, Typography } from "@mui/material";

// context
import { AuthContext } from "context/AuthContext";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  MainLayout,
  EventDetailBox,
  TextFieldControl,
  GreyCustomLabel,
  BackArrow,
} from "components";

// containers
import { ParticipantListControl } from "containers";

// constants
import { eventTipology, userGroups } from "constants/enums";

// date-fns
import { format } from "date-fns";

// react-hook-form
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

// templates
import { SCHEDULE, SCHEDULE_EVENT_PRENOTATION } from "constants/routes";

// hooks
import { useUserRoles } from "hooks";

// form validation rules
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  tickets: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("Email is required"),
    })
  ),
});

// TODO here we will add the GET request based on the state of the page
// which will be a timeslot object with an id

const EventDetail = () => {
  // context
  const { user } = useContext(AuthContext);

  const { state: event } = useLocation();

  const navigate = useNavigate();
  if (!event) navigate(SCHEDULE);

  const { isLoggedUserOneofGroups } = useUserRoles();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: user.name,
      surname: user.lastname,
      email: user.email,
    },
  };

  function onSubmit(data) {
    // TODO handle onsubmit logic
    console.log(
      "C.LOG ~ file: EventPrenotationDetail.js ~ line 43 ~ data",
      data
    );
  }

  const intl = useIntl();
  const i18n = translations.Agenda(intl);

  // functions to build form returned by useForm() and useFieldArray() hooks
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  return (
    <MainLayout>
      <BackArrow />
      {event && (
        <>
          <Typography variant={"h4"}>{event.name}</Typography>
          <EventDetailBox
            address={event.address}
            date={`${format(new Date(event.start), "dd/MM")} - ${format(
              new Date(event.end),
              "dd/MM"
            )}`}
            fromHour={format(new Date(event.start), "HH:mm")}
            toHour={format(new Date(event.end), "HH:mm")}
            description={event.description}
          />

          {event.typology === eventTipology.OPEN && (
            <>
              <GreyCustomLabel
                firstLabel={i18n.eventDetail.freeAppointmentCta}
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(errors).length >= 1 && (
                  <Typography color="error">
                    {i18n.eventDetail.form.compilationError}
                  </Typography>
                )}
                <Grid container spacing={2} mt={2} mb={2}>
                  <Grid item xs={12} md={4}>
                    <TextFieldControl
                      name={`name`}
                      control={control}
                      placeholder={"nome"}
                      disabled
                      label={"nome"}
                      errors={errors}
                      errorObj={{ required: "nome obbligatorio" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldControl
                      name={`surname`}
                      control={control}
                      placeholder={"cognome"}
                      disabled
                      label={"cognome"}
                      errors={errors}
                      errorObj={{ required: "nome obbligatorio" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldControl
                      name={`email`}
                      control={control}
                      placeholder={"email"}
                      disabled
                      label={"email"}
                      errors={errors}
                      errorObj={{ required: "nome obbligatorio" }}
                    />
                  </Grid>
                </Grid>
                <Button type={"submit"}>
                  {i18n.eventDetail.sendParticipation}
                </Button>
                <ParticipantListControl
                  control={control}
                  errors={errors}
                  register={register}
                />
              </form>
            </>
          )}
          {event.typology === eventTipology.RESTRICTED &&
            !isLoggedUserOneofGroups([
              userGroups.AGENT,
              userGroups.AGENTSTAFF,
              userGroups.AREAMANAGER,
              userGroups.LOCALMANAGER,
            ]) && (
              <GreyCustomLabel
                firstLabel={i18n.eventDetail.appointmentCta}
                addLabel={i18n.eventDetail.requestAppointment}
                handleAdd={() =>
                  navigate(SCHEDULE_EVENT_PRENOTATION, { state: event })
                }
              />
            )}
          {event.typology === eventTipology.RESTRICTED &&
            isLoggedUserOneofGroups([
              userGroups.AGENT,
              userGroups.AGENTSTAFF,
              userGroups.AREAMANAGER,
              userGroups.LOCALMANAGER,
            ]) && (
              <GreyCustomLabel
                firstLabel={i18n.eventDetail.availabilityCta}
                addLabel={i18n.eventDetail.requestAvailability}
                handleAdd={() =>
                  navigate(SCHEDULE_EVENT_PRENOTATION, { state: event })
                }
              />
            )}
        </>
      )}
    </MainLayout>
  );
};
export { EventDetail };
