export const apiUser = {
  API_BASE: "/Users",
  SIGN_IN: "/signIn",
  SIGN_UP: "/signUp",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword",
  CHANGE_PASSWORD: "/changePassword",
  AUTOCOMPLETE: "/autocomplete",
  INTERNALS: "/internals",
  EXTERNALS: "/externals",
  BYROLES: "/byroles",
  CREATE: "/create",
};

export const apiAddresses = {
  API_BASE: "/Addresses",
  COUNTRIES: "/countries",
  PROVINCES: "/provinces",
  CITIES: "/cities",
  ZIPCODES: "/zipcodes",
};

export const apiSectorOfInterests = {
  API_BASE: "/SectorsOfInterest",
};
export const apiCategories = {
  API_BASE: "/Categories",
};

export const apiRoles = {
  API_BASE: "/Roles",
};

export const apiPolicies = {
  API_BASE: "/Policies",
};

export const apiMenu = {
  API_BASE: "/Menu",
};

export const apiAgenda = {
  API_BASE: "/Exhibitions",
};

export const apiExhibitionSlots = {
  API_BASE: "/ExhibitionSlots",
  AVAILABILITY: "/availability",
};

export const apiExportExhibition = {
  API_BASE: "/Export/Exhibitions/",
};

export const apiAvailabilities = {
  API_BASE: "/Availabilities",
  APPOINTMENT: "/appointment",
};
export const apiAppointments = {
  API_BASE: "/Appointments",
  DETAIL: "/detail",
  UPDATE: "/update",
  GUESTS: "/guests",
  RESENDMAIL: "/resend",
  NOTE: "/note",
};
export const apiOnboarding = {
  API_BASE: "/Onboarding",
  SETPASSWORD: "/setPassword",
};
export const apiExhibitionParticipants = {
  API_BASE: "/ExhibitionParticipants",
  APPOINTMENT: "/appointment",
};
