import PropTypes from "prop-types";

import { ListIconTypes } from "constants/enums";

import { Wrapper } from "./ActionIconManager.styles";

import { ReactComponent as IconDarkDelete } from "assets/icons/icon_rounded_dark_delete.svg";
import { ReactComponent as IconDarkAdd } from "assets/icons/icon_rounded_dark_add.svg";
import { ReactComponent as IconDarkMessage } from "assets/icons/ico_rounde_dark_chat.svg";
import { ReactComponent as IconDarkAnalytics } from "assets/icons/icon_rounded_dark_analytics.svg";
import { ReactComponent as IconDarkCalendar } from "assets/icons/icon_rounded_dark_calendar.svg";
import { ReactComponent as IconDarkShare } from "assets/icons/icon_rounded_dark_share.svg";
import { ReactComponent as IconDarkView } from "assets/icons/icon_rounded_dark_view.svg";
import { ReactComponent as IconDarkCheck } from "assets/icons/ico_rounded_dark_check.svg";
import { ReactComponent as IconDarkEdit } from "assets/icons/icon_rounded_dark_edit.svg";
import { ReactComponent as IconDarkParticipants } from "assets/icons/ico-participants.svg";
import { ReactComponent as IconMapLocation } from "assets/icons/map-location.svg";
import { ReactComponent as IconDarkQRCode } from "assets/icons/ico_rounded_dark_scan.svg";

const ActionIconManager = ({ listActions }) => {
  return (
    <Wrapper>
      {listActions.map(({ type, callback }) => {
        switch (type) {
          case ListIconTypes.DELETE:
            return <IconDarkDelete onClick={() => callback()} />;
          case ListIconTypes.ADD:
            return <IconDarkAdd onClick={() => callback()} />;
          case ListIconTypes.MESSAGE:
            return <IconDarkMessage onClick={() => callback()} />;
          case ListIconTypes.ANALYTICS:
            return <IconDarkAnalytics onClick={() => callback()} />;
          case ListIconTypes.CALENDAR:
            return <IconDarkCalendar onClick={() => callback()} />;
          case ListIconTypes.SHARE:
            return <IconDarkShare onClick={() => callback()} />;
          case ListIconTypes.VIEW:
            return <IconDarkView onClick={() => callback()} />;
          case ListIconTypes.CHECK:
            return <IconDarkCheck onClick={() => callback()} />;
          case ListIconTypes.EDIT:
            return <IconDarkEdit onClick={() => callback()} />;
          case ListIconTypes.PARTICIPANTS:
            return <IconDarkParticipants onClick={() => callback()} />;
          case ListIconTypes.MAPLOCATION:
            return <IconMapLocation onClick={() => callback()} />;
          case ListIconTypes.QRCODE:
            return <IconDarkQRCode onClick={() => callback()} />;
        }
      })}
    </Wrapper>
  );
};

ActionIconManager.propTypes = {
  listActions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    })
  ),
};

ActionIconManager.defaultProps = {};

export { ActionIconManager };
