import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
// react-mui
import { Typography } from "@mui/material";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { MainLayout } from "components/index";

import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";
import { format } from "date-fns";

//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};

// import PropTypes from "prop-types";
const MyArea = () => {
  const { user } = useContext(AuthContext);

  const intl = useIntl();
  const i18n = translations.Home(intl).page;

  return (
    <MainLayout>
      <Typography variant="h4">
        {i18n.title}, <b>{user.name}</b>
      </Typography>
      <Typography
        variant="body1"
        textTransform={"capitalize"}
        fontSize={16}
        color="secondary.dark"
      >
        {format(new Date(), "cccc dd MMMM yyyy", {
          locale: locales[user.language],
        })}
      </Typography>
    </MainLayout>
  );
};
// MyArea.propTypes = {};
export { MyArea };
