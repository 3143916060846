import { styled } from "@mui/system";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  background: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const LeftPanel = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    // boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
    // color: theme.palette.primary.light,
    // background:
    //   "url('https://picsum.photos/1920/1080') transparent no-repeat bottom center  / cover",
  },
}));
export const FormContainer = styled("form")(({ theme }) => ({
  margin: "auto",
  width: "60%",
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
    // color: theme.palette.primary.light,
    // background:
    //   "url('https://picsum.photos/1920/1080') transparent no-repeat bottom center  / cover",
  },
}));
