// react-intl
import { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import translations from "utils/translations";

// context
import { AuthContext } from "context/AuthContext";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// // react router dom
import { useLocation, useNavigate } from "react-router-dom";

// react mui
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
} from "@mui/material";

// components
import {
  AutocompleteControlAPI,
  BlackWhiteRoundedButton,
  LinearProgress,
  LoadingDialog,
  MainLayout,
  TextFieldControl,
  SelectControl,
  CheckBoxControl,
  BackArrow,
} from "components/index";

// react-hook-form
import { useForm } from "react-hook-form";

// constants
import { REGISTRY_POLIFORM_USERS } from "constants/routes";
import { formControlNames, userGroups } from "constants/enums";
import { apiUser } from "constants/api";
import { languages } from "constants/languages";

// use-http
import { useFetch } from "use-http";

// hooks
import { useUserRoles } from "hooks";

const CreateEmployee = () => {
  const { user } = useContext(AuthContext);

  const { state } = useLocation();

  // Hooks
  // eslint-disable-next-line
  const [userToMod, setUserToMod] = useState();

  const { get, loading, response } = useFetch(
    `${apiUser.API_BASE}/${state?.id}`
  );
  const {
    del,
    put,
    loading: editingLoading,
    response: editingResponse,
  } = useFetch(`${apiUser.API_BASE}/${state?.id}`);

  const {
    post,
    loading: creatingUser,
    response: createResponse,
  } = useFetch(`${apiUser.API_BASE}${apiUser.CREATE}`);
  const [deleting, setDeleting] = useState(false);

  const {
    data: rolesData,
    loading: loadingRoles,
    error: errorRoles,
  } = useFetch("/CorporateGroups", []);

  let navigate = useNavigate();
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    if (state?.id) {
      const user = await get();
      setUserToMod(user);
    }
  }, [state]);

  const { isLoggedUserInGroup, isLoggedUserOneofGroups } = useUserRoles();

  const schema = yup
    .object({
      name: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().email().required(),
      language: yup.string().required(),
      mobilePhone: yup.string().matches(/^$|^\d+$/, "enter valid number"),
      selectGroup:
        isLoggedUserInGroup(userGroups.ADMIN) && !userToMod
          ? yup.string().required()
          : yup.object().nullable(),
      staffParentId:
        isLoggedUserInGroup(userGroups.ADMIN) && !userToMod
          ? yup
              .object()
              .nullable()
              .when("selectGroup", selectGroup => {
                if (
                  selectGroup === userGroups.AGENTSTAFF ||
                  selectGroup === userGroups.LOCALMANAGER
                )
                  return yup
                    .object()
                    .required("User must have a staff parent id");
              })
          : yup.object().nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      if (userToMod) {
        return { ...userToMod };
      } else {
        return { group: user.group };
      }
    }, [userToMod]),
  });

  const onCancel = () => {
    navigate(REGISTRY_POLIFORM_USERS);
  };

  useEffect(() => {
    reset(userToMod);
  }, [userToMod]);

  watch((data, { name }) => {
    if (name == "selectGroup") {
      reset({ group: user.group, selectGroup: data[name] });
    }
  });

  const onSubmit = async data => {
    if (!userToMod) {
      if (isLoggedUserInGroup(userGroups.ADMIN)) {
        if (
          watch("selectGroup") === userGroups.AGENTSTAFF ||
          watch("selectGroup") === userGroups.LOCALMANAGER
        ) {
          data.staffParentId = data.staffParentId?.id;
        }
        data.group = data.selectGroup;
      } else if (
        isLoggedUserOneofGroups([
          userGroups.AGENTSTAFF,
          userGroups.LOCALMANAGER,
        ])
      ) {
        data.staffParentId = user.staffParentId;
      } else if (isLoggedUserInGroup(userGroups.AGENT)) {
        data.group = userGroups.AGENTSTAFF;
        data.staffParentId = user.id;
      } else if (isLoggedUserInGroup(userGroups.AREAMANAGER)) {
        data.group = userGroups.LOCALMANAGER;
        data.staffParentId = user.id;
      } else {
        data.staffParentId = null;
      }
      post(data).then(() => {
        if (createResponse.status === 200) {
          navigate(REGISTRY_POLIFORM_USERS);
        }
      });
    } else {
      put(data).then(() => {
        navigate(REGISTRY_POLIFORM_USERS);
      });
    }
  };

  const handleDelete = () => {
    setDeleting(true);
    del().then(() => {
      navigate(REGISTRY_POLIFORM_USERS);
    });
  };

  return (
    <MainLayout>
      {loading && <LinearProgress />}
      {!loading && (
        <>
          <BackArrow />

          {!userToMod && (
            <Typography variant="h4">
              {i18n.employees.createUserTitle} - Staff
            </Typography>
          )}
          {userToMod && (
            <Typography variant="h4">
              {i18n.employees.editUserTitle} - Staff
            </Typography>
          )}
          {createResponse.ok && (
            <Typography color="success">{i18n.userCreation.success}</Typography>
          )}
          {editingResponse.ok && (
            <Typography color="success">{i18n.userEdit.success}</Typography>
          )}
          {createResponse.status === 409 && (
            <Typography color="error">
              {i18n.page.fetchError.userExists}
            </Typography>
          )}
          {response.status === 500 ||
            (createResponse.status === 500 && (
              <Typography color="error">
                {i18n.page.fetchError.fetchError}
              </Typography>
            ))}
          <form onSubmit={handleSubmit(onSubmit)}>
            {!userToMod && isLoggedUserInGroup(userGroups.ADMIN) && (
              <Grid container mt={1} spacing={2}>
                <Grid item xs={12} sm={3}>
                  {errorRoles && (
                    <Typography>{i18n.roles.fetchError}</Typography>
                  )}
                  {loadingRoles && <CircularProgress size={25} />}
                  {rolesData && !errorRoles && (
                    <SelectControl
                      control={control}
                      name={"selectGroup"}
                      label={"gruppo"}
                      options={rolesData}
                      errors={errors}
                      errorObj={{
                        required: "obbligatorio",
                        typeError: "obbligatorio",
                      }}
                      fullWidth
                      returnName
                    />
                  )}
                </Grid>
                {(watch("selectGroup") === userGroups.AGENTSTAFF ||
                  watch("selectGroup") === userGroups.LOCALMANAGER) && (
                  <Grid item xs={12} sm={3}>
                    <AutocompleteControlAPI
                      control={control}
                      name={"staffParentId"}
                      label={"utente di riferimento"}
                      errors={errors}
                      errorObj={{
                        required: "obbligatorio",
                        typeError: "obbligatorio",
                      }}
                      endpoint={`${apiUser.API_BASE}${apiUser.AUTOCOMPLETE}`}
                      // TODO refactor this endpoint when we will have a third option
                      queryParam={`&Groups=${
                        watch("selectGroup") === userGroups.AGENTSTAFF
                          ? userGroups.AGENT
                          : userGroups.AREAMANAGER
                      }&q`}
                      noOptionsText={"nessun utente trovato"}
                      isRequired
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container mt={1} spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={formControlNames.registry.name}
                  placeholder={i18n.employees.employeeName.label}
                  label={i18n.employees.employeeName.label}
                  errors={errors}
                  errorObj={i18n.employees.employeeName.errors}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={formControlNames.registry.lastname}
                  placeholder={i18n.employees.employeeSurname.label}
                  label={i18n.employees.employeeSurname.label}
                  errors={errors}
                  errorObj={i18n.employees.employeeSurname.errors}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={formControlNames.registry.email}
                  placeholder={i18n.employees.employeeEmail.label}
                  label={i18n.employees.employeeEmail.label}
                  errors={errors}
                  errorObj={i18n.employees.employeeEmail.errors}
                  isRequired
                  disabled={userToMod}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectControl
                  control={control}
                  name={formControlNames.registry.language}
                  placeholder={i18n.page.anagrafiche.language.label}
                  label={i18n.page.anagrafiche.language.label}
                  errors={errors}
                  errorObj={i18n.page.anagrafiche.language.errors}
                  isRequired
                  options={languages}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={formControlNames.registry.mobilePhone}
                  placeholder={i18n.page.private.mobile.label}
                  label={i18n.page.private.mobile.label}
                  errors={errors}
                  errorObj={i18n.page.private.mobile.errors}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={formControlNames.registry.jobTitle}
                  placeholder={i18n.page.professional.qualification.label}
                  label={i18n.page.professional.qualification.label}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CheckBoxControl
                control={control}
                name={"profiling"}
                placeholder={i18n.employees.informative.label}
                label={i18n.employees.informative.label}
              />
            </Grid>
            <BlackWhiteRoundedButton
              type="submit"
              label={i18n.employees.submit}
            />
            <Button onClick={onCancel}>{i18n.employees.cancel}</Button>

            {userToMod && (
              <>
                {/* <Button onClick={() => setOpen(true)}>
              {i18n.employees.delete}
            </Button> */}
              </>
            )}
            <Dialog onClose={handleClose} open={open}>
              <DialogTitle>{i18n.employees.dialog.title}</DialogTitle>
              <DialogContent>
                <Typography mb={1}>{i18n.employees.dialog.content}</Typography>
              </DialogContent>
              <DialogActions>
                <>
                  <Button onClick={handleClose}>
                    {i18n.employees.dialog.no}
                  </Button>
                  {/* TODO: implementing onClose */}
                  <Button onClick={handleDelete}>
                    {i18n.employees.dialog.yes}
                  </Button>
                </>
              </DialogActions>
            </Dialog>
          </form>
        </>
      )}
      <LoadingDialog open={deleting || editingLoading || creatingUser} />
    </MainLayout>
  );
};

export { CreateEmployee };
