import { useCallback, useContext, useState } from "react";

import PropTypes from "prop-types";

// context
import { AuthContext } from "context/AuthContext";

// react big calendar
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";
//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// hooks
import { useCalendar, useCalendarSlots } from "hooks";
import CustomWeekView from "./CustomWeekView";

// components
import { FixedSlotsDialog } from "components";

const FixedSlotCalendar = ({
  event,
  fixedSlots,
  setOpen,
  open,
  action,
  setAction,
  currDate,
}) => {
  const { user } = useContext(AuthContext);
  // eslint-disable-next-line
  const [locale, setLocale] = useState(user.language);

  // eslint-disable-next-line
  const [currentView, setCurrentView] = useState("week");

  const [currentDate, setCurrentDate] = useState(
    currDate ? new Date(parseInt(currDate)) : event.start
  );

  const [selectedSlot, setSelectedSlot] = useState();

  const { myWeek } = CustomWeekView();

  const [calendarMessages] = useCalendar();
  const { componentsFixedSlots } = useCalendarSlots({
    event,
    setOpen,
    setSelectedSlot,
    setAction,
  });

  const onNavigate = useCallback(
    newDate => {
      const MIN = new Date(event.start);
      const MAX = new Date(event.end);
      if (newDate >= MIN && newDate <= MAX) {
        setCurrentDate(newDate);
      } else if (newDate < MIN) {
        setCurrentDate(MIN);
      }
    },
    [currentDate]
  );

  return (
    <>
      <div style={{ maxHeight: "90%" }}>
        <Calendar
          components={componentsFixedSlots}
          culture={locale}
          dayLayoutAlgorithm={"no-overlap"}
          localizer={localizer}
          events={fixedSlots}
          startAccessor="start"
          endAccessor="end"
          step={event.slotDuration}
          timeslots={1}
          allDayAccessor="allDay"
          // views={["week"]}
          views={{
            week: myWeek,
            day: true,
          }}
          defaultView={currDate ? "day" : "week"}
          onView={newView => setCurrentView(newView)}
          min={new Date(event.start)}
          max={new Date(event.end)}
          date={new Date(currentDate)}
          onNavigate={onNavigate}
          resizable={false}
          messages={calendarMessages}
          showMultiDayTimes={true}
        />
      </div>
      <FixedSlotsDialog
        open={open}
        onClose={() => setOpen(false)}
        slot={selectedSlot}
        event={event}
        action={action}
      />
    </>
  );
};
FixedSlotCalendar.propTypes = {
  event: PropTypes.object,
  fixedSlots: PropTypes.array,
  selectedSlot: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  action: PropTypes.string,
  setAction: PropTypes.func,
  currDate: PropTypes.string,
};
export { FixedSlotCalendar };
