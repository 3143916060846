import { calendarDialogActions } from "constants/enums";
import PropTypes from "prop-types";

// react-intl
// import { useIntl } from "react-intl";
// import translations from "utils/translations";

// styled components
import {
  PrenotationIconWrapper,
  SlotWrapper,
} from "./CreateAvailabilitySlot.styles";

const CreateAvailabilitySlot = ({
  setSelectedSlot,
  setOpen,
  slot,
  setAction,
}) => {
  // const intl = useIntl();
  // const i18n = translations.Agenda(intl).slots.createAvailabilitySlot;

  return (
    <SlotWrapper
      onClick={() => {
        setSelectedSlot(slot);
        setAction(calendarDialogActions.CREATEAVAILABILITY);
        setOpen(true);
      }}
    >
      <PrenotationIconWrapper>+</PrenotationIconWrapper>
    </SlotWrapper>
  );
};

CreateAvailabilitySlot.propTypes = {
  setSelectedSlot: PropTypes.func,
  setAction: PropTypes.func,
  setOpen: PropTypes.func,
  slot: PropTypes.object,
};

export { CreateAvailabilitySlot };
