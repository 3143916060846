import PropTypes from "prop-types";

// date fns
import { format } from "date-fns";

// react mui
import { Typography } from "@mui/material";

// components
import { Link } from "components";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as LockerIcon } from "assets/icons/lockpick.svg";
import { ReactComponent as GroupIcon } from "assets/icons/user.svg";

// styled components
import {
  EventActionsIconContainer,
  EventActionsWrapper,
  EventWrapper,
} from "./EventDayItem.styles";

// constants
import {
  SCHEDULE_EVENT_DETAIL,
  SCHEDULE_EVENT_PRENOTATION,
  SCHEDULE_MANAGE_EVENT_PARTICIPANTS,
} from "constants/routes";
import { eventTipology } from "constants/enums";

const EventDayItem = ({ event }) => {
  return (
    <EventWrapper>
      <Link
        style={{ color: "white", textDecoration: "none" }}
        to={SCHEDULE_EVENT_PRENOTATION}
        state={event}
      >
        {event.typology === eventTipology.RESTRICTED && (
          <LockerIcon style={{ position: "absolute", right: "5px" }} />
        )}
        <Typography variant={"span"} fontSize={8}>
          {format(event.start, "dd-MM")}/{format(event.end, "dd-MM")}
        </Typography>
        <Typography fontWeight={"bolder"}>{event.title}</Typography>
        <Typography>{event.location}</Typography>
        <Typography variant={"span"} fontSize={8}>
          {event.numParticipants} partecipanti
        </Typography>
        <EventActionsWrapper>
          <Link to={SCHEDULE_MANAGE_EVENT_PARTICIPANTS} state={event}>
            <EventActionsIconContainer>
              <GroupIcon />
            </EventActionsIconContainer>
          </Link>
          <Link to={SCHEDULE_EVENT_DETAIL} state={event}>
            <EventActionsIconContainer>
              <EditIcon />
            </EventActionsIconContainer>
          </Link>
        </EventActionsWrapper>
      </Link>
    </EventWrapper>
  );
};
EventDayItem.propTypes = {
  event: PropTypes.object,
};
export { EventDayItem };
