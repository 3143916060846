import { useState } from "react";
import PropTypes from "prop-types";

// use-http
import useFetch from "use-http";

// constants
import { calendarDialogActions } from "constants/enums";

// react mui
import { Dialog } from "@mui/material";

// dialogs
import CreateAvailabilityDialog from "./Dialogs/CreateAvailability.dialog";
import DeleteAvailabilityDialog from "./Dialogs/DeleteAvailability.dialog";
import DeleteAppointmentDialog from "./Dialogs/DeleteAppointment.dialog";

const FreeSlotsDialog = ({ onClose, open, slot, mainSlot, event, action }) => {
  const { post, error: creationError, del } = useFetch();

  if (!mainSlot && !slot) {
    onClose();
  }

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setSuccess(false);
    setLoading(false);
    setError(false);
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {action === calendarDialogActions.CREATEAVAILABILITY && (
        <CreateAvailabilityDialog
          post={post}
          creationError={creationError}
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          slot={slot}
          mainSlot={mainSlot}
          event={event}
        />
      )}
      {action === calendarDialogActions.DELETEAVAILABILITY && (
        <DeleteAvailabilityDialog
          del={del}
          creationError={creationError}
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          slot={mainSlot}
        />
      )}
      {action === calendarDialogActions.DELETEAPPOINTMENT && (
        <DeleteAppointmentDialog
          del={del}
          creationError={creationError}
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          slot={mainSlot}
        />
      )}
    </Dialog>
  );
};
FreeSlotsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  slot: PropTypes.object,
  mainSlot: PropTypes.number,
  event: PropTypes.object,
  action: PropTypes.string,
};

export { FreeSlotsDialog };
