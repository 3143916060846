import { styled } from "@mui/system";

export const Wrapper = styled("div")(() => ({}));
export const Header = styled("div")(({ theme }) => ({
  padding: "20px",
  backgroundColor: theme.palette.primary.main,
}));
export const Content = styled("div")(({ theme }) => ({
  padding: "20px",
  backgroundColor: theme.palette.secondary.main,
}));
