export const userGroups = {
  SYSTEM: "SYSTEM",
  ADMIN: "ADMIN",
  AREAMANAGER: "AREAMANAGER",
  LOCALMANAGER: "LOCALMANAGER",
  AGENT: "AGENT",
  AGENTSTAFF: "AGENTSTAFF",
  BUSINESSPARTNER: "BUSINESSPARTNER",
  BUSINESSPARTNERSTAFF: "BUSINESSPARTNERSTAFF",
  EMPLOYEE: "EMPLOYEE",
  PROFESSIONAL: "PROFESSIONAL",
  PRESS: "PRESS",
  ENDUSER: "ENDUSER",
};

export const userGroupsSmall = {
  AREAMANAGER: "AREAMANAGER",
  LOCALMANAGER: "LOCALMANAGER",
  AGENT: "AGENT",
  AGENTSTAFF: "AGENTSTAFF",
  EMPLOYEE: "EMPLOYEE",
};

export const formControlNames = {
  registry: {
    name: "name",
    lastname: "lastname",
    email: "email",
    sectors: "sectorsOfInterestIds",
    language: "language",
    mobilePhone: "mobilePhone",
    jobTitle: "jobTitle",
  },
  agenda: {
    note: "note",
  },
};

export const userStateSelect = [
  {
    id: "ACTIVE",
    name: "Attivo",
  },
  {
    id: "INACTIVE",
    name: "Inattivo",
  },
  {
    id: "NEW",
    name: "Nuovo",
  },
];

// TODO come vedere liste
export const employeeGroupSelect = {
  ADMIN: [
    {
      id: userGroups.AREAMANAGER,
      name: userGroups.AREAMANAGER,
    },
    {
      id: userGroups.LOCALMANAGER,
      name: userGroups.LOCALMANAGER,
    },
    {
      id: userGroups.AGENT,
      name: userGroups.AGENT,
    },
    {
      id: userGroups.AGENTSTAFF,
      name: userGroups.AGENTSTAFF,
    },
    {
      id: userGroups.EMPLOYEE,
      name: userGroups.EMPLOYEE,
    },
  ],
  AGENT: [
    {
      id: userGroups.AGENTSTAFF,
      name: userGroups.AGENTSTAFF,
    },
    {
      id: userGroups.EMPLOYEE,
      name: userGroups.EMPLOYEE,
    },
    {
      id: userGroups.ENDUSER,
      name: userGroups.ENDUSER,
    },
  ],
  AGENTSTAFF: [
    {
      id: userGroups.AGENTSTAFF,
      name: userGroups.AGENTSTAFF,
    },
    {
      id: userGroups.EMPLOYEE,
      name: userGroups.EMPLOYEE,
    },
  ],
  AREAMANAGER: [
    {
      id: userGroups.AGENT,
      name: userGroups.AGENT,
    },
    {
      id: userGroups.AGENTSTAFF,
      name: userGroups.AGENTSTAFF,
    },
    {
      id: userGroups.EMPLOYEE,
      name: userGroups.EMPLOYEE,
    },
  ],
  REST: [],
};

export const menuIdentifiers = {
  MYAREA: "MYAREA",
  // Agenda
  AGENDA: "AGENDA",
  AGENDA_MANAGE_SHOWROOMS: "AGENDA_MANAGE_SHOWROOMS",
  AGENDA_SHOWROOMS: "AGENDA_SHOWROOMS",
  AGENDA_MANAGE_EVENTS: "AGENDA_MANAGE_EVENTS",
  AGENDA_EVENTS: "AGENDA_EVENTS",
  AGENDA_ARCHIVE: "AGENDA_ARCHIVE",

  // Anagrafiche
  REGISTRY: "REGISTRY",
  REGISTRY_PERMISSIONS: "REGISTRY_PERMISSIONS",
  REGISTRY_POLIFORM_USERS: "REGISTRY_POLIFORM_USERS",
  REGISTRY_BUSINESS_PARTNERS: "REGISTRY_BUSINESS_PARTNERS",
  REGISTRY_PROFESSIONALS: "REGISTRY_PROFESSIONALS",
  REGISTRY_PRESS: "REGISTRY_PRESS",
  REGISTRY_ENDUSERS: "REGISTRY_ENDUSERS",

  // Documents
  DOCUMENTS: "DOCUMENTS",
  DOCUMENTS_CIRCULAR_ARCHIVES: "DOCUMENTS_CIRCULAR_ARCHIVES",
  DOCUMENTS_LISTS: "DOCUMENTS_LISTS",
  DOCUMENTS_STATEMENTS: "DOCUMENTS_STATEMENTS",
  DOCUMENTS_ARCHIVES: "DOCUMENTS_ARCHIVES",
  DOCUMENTS_STOCK_ORDERS: "DOCUMENTS_STOCK_ORDERS",
  DOCUMENTS_ORDERS: "DOCUMENTS_ORDERS",

  // Marketing
  MARKETING: "MARKETING",
  MARKETING_MANUALS: "MARKETING_MANUALS",
  MARKETING_MATERIALS: "MARKETING_MATERIALS",

  // Press
  PRESS: "PRESS",
  PRESS_COMUNICATIONS: "PRESS_COMUNICATIONS",

  // KPI
  KPI: "KPI",
};

export const ListIconTypes = {
  DELETE: "delete",
  ADD: "add",
  MESSAGE: "message",
  ANALYTICS: "analylics",
  CALENDAR: "calendar",
  SHARE: "share",
  VIEW: "view",
  CHECK: "check",
  EDIT: "edit",
  PARTICIPANTS: "participants",
  MAPLOCATION: "maplocation",
  QRCODE: "qrcode",
};
export const agendaSlots = {
  AVAILABLE: "AVAILABLE",
  OCCUPIED: "OCCUPIED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  CONFIRMED: "CONFIRMED",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
};

export const eventTipology = {
  RESTRICTED: "RESTRICTED",
  OPEN: "OPEN",
};
export const slotTypology = {
  FREE: "FREE",
  FIXED: "FIXED",
};

export const errorDialogType = {
  AUTH: "AUTH",
  SERVER: "SERVER",
};
export const confirmParticipationResponse = {
  AVAILABLE: "AVAILABLE",
  OCCUPIED: "OCCUPIED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  CONFIRMED: "CONFIRMED",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
};

export const calendarDialogActions = {
  CREATEAVAILABILITY: "CREATEAVAILABILITY",
  DELETEAVAILABILITY: "DELETEAVAILABILITY",
  UPDATEAVAILIABILITY: "UPDATEAVAILIABILITY",
  DELETEAPPOINTMENT: "DELETEAPPOINTMENT",
};

export const eventManageParticipant = {
  APPROVE: "APPROVE",
  REFUSE: "REFUSE",
};
export const eventManageEvents = {
  DELETE: "DELETE",
};

export const guestUpdateActions = {
  NONE: "NONE",
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
};
