import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// react router dom
import { useLocation } from "react-router-dom";

// intl
import { IntlProvider } from "react-intl";
import * as Langs from "compiled-lang";

// menu
import { Menu } from "constants/menu";

// Context
import { AuthContext } from "context/AuthContext";

import { ErrorDialog } from "components/index";

// use-http
import { Provider } from "use-http";

// Constants
import { langs } from "constants/langs";
import { LOGIN } from "constants/routes";
import { errorDialogType } from "constants/enums";

export const MainContext = createContext();

const MainProvider = ({ children }) => {
  //context
  const { user, setUser } = useContext(AuthContext);
  // States
  const [locale, setLocale] = useState(langs.IT);
  const [openSidebar, setOpenSidebar] = useState(true);

  // states for error dialog
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState("");

  const location = useLocation();

  // here we should map the menu object to reflect the user permissions
  const [permissionMenu, setPermissionMenu] = useState(Menu);
  const [loadingMenu, setLoadingMenu] = useState(true);

  const options = {
    // Cache responses to improve speed and reduce amount of requests
    // Only one request to the same endpoint will be initiated unless cacheLife expires for 'cache-first'.
    cachePolicy: "no-cache",

    interceptors: {
      request: ({ options }) => {
        options.headers = {
          Authorization: `Bearer ${user?.token}`,
          "Content-Type": "application/json",
        };
        return options;
      },
      response: ({ response }) => {
        if (response.status === 401 && location.pathname !== LOGIN) {
          setError(true);
          setErrorType(errorDialogType.AUTH);
          setUser(null);
        }
        if (response.status === 500) {
          setErrorType(errorDialogType.SERVER);
          setError(true);
        }
        return response;
      },
    },
  };

  const handleIntlError = err => {
    if (err.code === "MISSING_TRANSLATION") {
      console.warn("Missing translation", err.message);
      return;
    }
    throw err;
  };

  useEffect(() => {
    if (user) {
      setLocale(langs[user?.language]);
    }
  }, [user]);

  return (
    <IntlProvider
      locale={locale}
      messages={Langs[locale === "en-US" ? "us" : locale]}
      onError={handleIntlError}
    >
      <Provider options={options} url={process.env.REACT_APP_API_URL}>
        <MainContext.Provider
          value={{
            permissionMenu,
            setPermissionMenu,
            openSidebar,
            setOpenSidebar,
            loadingMenu,
            setLoadingMenu,
          }}
        >
          {children}
          {error && (
            <ErrorDialog
              open={error}
              onClose={() => setError(false)}
              type={errorType}
            />
          )}
        </MainContext.Provider>
      </Provider>
    </IntlProvider>
  );
};

MainProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MainProvider;
