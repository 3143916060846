// react-intl
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import translations from "utils/translations";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// // react router dom
import { useNavigate } from "react-router-dom";

// components
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { MainLayout, CheckBoxControl, BackArrow } from "components/index";

// react-hook-form
import { useForm } from "react-hook-form";

// API
import { apiUser } from "constants/api";

// utils
import { registerDTO } from "utils/users";

import PrivateFields from "templates/ProfileForm/Fields/Private.fields";

// form schema yup
import { BusinessPartnerSchema } from "templates/ProfileForm/Schema/BusinessPartner.schema";
import { AnagraficheSchema } from "templates/ProfileForm/Schema/Anagrafiche.schema";
import { GenericSchema } from "templates/ProfileForm/Schema/Generic.schema";

// use-http
import { useFetch } from "use-http";

const CreateEndUser = () => {
  // Hooks
  // eslint-disable-next-line
  const { post, response, error: fetchError } = useFetch();

  const navigate = useNavigate();
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const userId = ""; // TODO:  id utente

  useEffect(() => {
    //TODO: getUserData e resetForm()
  }, [userId]);

  const schema = yup
    .object({
      ...BusinessPartnerSchema,
      ...GenericSchema,
      ...AnagraficheSchema,
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      "select-type": "private",
      nation: "",
      city: "",
      province: "",
      postalCode: "",
      foreignCity: "",
      foreignPostalCode: "",
      street: "",
      profiling: false,
      email: "",
    },
  });

  const onReset = () => {
    reset();
  };

  const onSubmit = async data => {
    // eslint-disable-next-line
    const newUser = await post(
      `${apiUser.API_BASE}${apiUser.SIGN_UP}`,
      registerDTO(data)
    );
    if (response.ok) {
      navigate(-1);
    }
  };
  return (
    <MainLayout>
      <BackArrow />

      <Typography variant="h4">
        {(userId
          ? i18n.employees.editUserTitle
          : i18n.employees.createUserTitle) + " End User"}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <PrivateFields
          reset={reset}
          watch={watch}
          control={control}
          errors={errors}
        />
        <Grid item xs={12}>
          <CheckBoxControl
            control={control}
            name={"profiling"}
            placeholder={i18n.employees.informative.label}
            label={i18n.employees.informative.label}
          />
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item>
            <Button onClick={onReset}>{i18n.employees.cancel}</Button>
          </Grid>
          <Grid item lg={11}>
            <Button type="submit">{i18n.employees.submit}</Button>
          </Grid>
          {userId && (
            <Grid item>
              <Button onClick={() => setOpen(true)}>
                {i18n.employees.delete}
              </Button>
            </Grid>
          )}
        </Grid>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>{i18n.employees.dialog.title}</DialogTitle>
          <DialogContent>
            <Typography mb={1}>{i18n.employees.dialog.content}</Typography>
          </DialogContent>
          <DialogActions>
            <>
              <Button onClick={handleClose}>{i18n.employees.dialog.no}</Button>
              {/* TODO: implementing onClose */}
              <Button onClick={handleClose}>{i18n.employees.dialog.yes}</Button>
            </>
          </DialogActions>
        </Dialog>
      </form>
    </MainLayout>
  );
};
export { CreateEndUser };
