import { useState, useEffect } from "react";

// constants
import { apiUser } from "constants/api";

// use-http
import { useFetch } from "use-http";

const useRegistryDataListFetch = (
  pageIndex,
  pageSize,
  queryParameters,
  currentOrderId,
  currentOrder,
  queryUserParameters,
  setPageIndex
) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [elementCount, setElementCount] = useState(0);
  const { loading, get, error } = useFetch(
    `${apiUser.API_BASE}${apiUser.INTERNALS}`
  );

  useEffect(() => {
    setPageCount(0);
    setPageIndex(0);
    get(
      `?page=${pageIndex}&pageSize=${pageSize}${
        currentOrderId != "" ? `&sortBy=${currentOrderId}` : ``
      }${currentOrder != "" ? `&order=${currentOrder}` : ``}${queryParameters}${
        (queryUserParameters &&
          !queryParameters.includes("Group") &&
          "&" + queryUserParameters + "&") ||
        "&" + queryUserParameters
      }`
    ).then(res => {
      setElementCount(res.count);
      setRows(res.data);
      setPageCount(Math.ceil(res.count / pageSize));
    });
  }, [pageIndex, pageSize, queryParameters, currentOrderId, currentOrder]);

  return [error, loading, rows, pageCount, elementCount];
};

export { useRegistryDataListFetch };
