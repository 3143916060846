import * as yup from "yup";

export const GenericSchema = {
  name: yup
    .string()
    .when("select-type", {
      is: "press",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  surname: yup
    .string()
    .when("select-type", {
      is: "press",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  email: yup
    .string()
    .email()
    .when("select-type", {
      is: "press",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().required(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  category: yup
    .string()
    .when("select-type", {
      is: "press",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  sectors: yup
    .array()
    // .when("select-type", {
    //   is: "press",
    //   then: yup.array().min(1, "The error message if length === 0"),
    // })
    .when("select-type", {
      is: "professional",
      then: yup.array().min(1, "The error message if length === 0"),
    }),
  qualification: yup.string(),

  website: yup
    .string()
    .matches(
      /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .when("select-type", {
      is: "press",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  company: yup
    .string()
    .when("select-type", {
      is: "press",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "professional",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "private",
      then: yup.string().notRequired(),
    })
    .when("select-type", {
      is: "business-partner",
      then: yup.string().notRequired(),
    }),
  telephone: yup.string().matches(/^$|^\d+$/, "enter valid number"),
  mobile: yup.string().matches(/^$|^\d+$/, "enter valid number"),
};
