export default {
  Actions: intl => ({
    submit: intl.formatMessage({
      description: "Actions submit",
      defaultMessage: "invio",
    }),
    cancel: intl.formatMessage({
      description: "Actions cancel",
      defaultMessage: "annulla",
    }),
    modify: intl.formatMessage({
      description: "Actions modify",
      defaultMessage: "modifica",
    }),
    modifyTime: intl.formatMessage({
      description: "Actions modify",
      defaultMessage: "modifica orario",
    }),
    close: intl.formatMessage({
      description: "Actions cancel",
      defaultMessage: "chiudi",
    }),
    refusal: intl.formatMessage({
      description: "Actions refusal",
      defaultMessage: "rifiuta",
    }),
    confirm: intl.formatMessage({
      description: "Actions refusal",
      defaultMessage: "conferma",
    }),
    congratulations: intl.formatMessage({
      description: "Actions congratulations",
      defaultMessage: "complimenti",
    }),
    save: intl.formatMessage({
      description: "Actions save",
      defaultMessage: "salva",
    }),
    filter: intl.formatMessage({
      description: "Actions filter",
      defaultMessage: "filtra",
    }),
    remove: intl.formatMessage({
      description: "Actions remove",
      defaultMessage: "rimuovi",
    }),
    delete: intl.formatMessage({
      description: "Actions remove",
      defaultMessage: "elimina",
    }),
    create: intl.formatMessage({
      description: "Actions create",
      defaultMessage: "crea",
    }),
    createNew: intl.formatMessage({
      description: "Actions create",
      defaultMessage: "crea nuovo",
    }),
    resendMail: intl.formatMessage({
      description: "Actions resendMail",
      defaultMessage: "reinvia mail",
    }),
    operationSuccess: intl.formatMessage({
      description: "Actions operationSuccess",
      defaultMessage: "Operazione conclusa con successo.",
    }),
    operationError: intl.formatMessage({
      description: "Actions operationError",
      defaultMessage:
        "Operazione non conclusa con successo, riprovare più tardi.",
    }),

    fetchError: {
      server: intl.formatMessage({
        description: "Register page fetch error server 500",
        defaultMessage:
          "Errore di comunicazione con il server, riprovare più tardi",
      }),
    },
  }),
  Commons: intl => ({
    yes: intl.formatMessage({
      description: "Commons yes",
      defaultMessage: "Si",
    }),
    no: intl.formatMessage({
      description: "Commons no",
      defaultMessage: "No",
    }),
    guestList: intl.formatMessage({
      description: "Commons guestList",
      defaultMessage: "Lista Ospiti",
    }),
    addGuest: intl.formatMessage({
      description: "Commons addGuest",
      defaultMessage: "Aggiungi Ospite",
    }),

    name: {
      label: intl.formatMessage({
        description: "Commons name label",
        defaultMessage: "nome",
      }),
      errors: {
        required: intl.formatMessage({
          description: "Commons name errors reqeuired",
          defaultMessage: "nome obbligatorio",
        }),
      },
    },
    lastname: {
      label: intl.formatMessage({
        description: "Commons lastname label",
        defaultMessage: "cognome",
      }),
      errors: {
        required: intl.formatMessage({
          description: "Commons lastname errors reqeuired",
          defaultMessage: "cognome obbligatorio",
        }),
      },
    },
    email: {
      label: intl.formatMessage({
        description: "Commons email label",
        defaultMessage: "email",
      }),
      errors: {
        required: intl.formatMessage({
          description: "Commons email errors reqeuired",
          defaultMessage: "email obbligatoria",
        }),
        email: intl.formatMessage({
          description: "Commons email errors email",
          defaultMessage: "inserire una email valida",
        }),
      },
    },
  }),
};
