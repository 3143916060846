import { styled } from "@mui/system";

export const RightPanel = styled("div")(({ theme }) => ({
  flex: 1,
  background:
    "url('images/cover-area-riservata.jpg') transparent no-repeat bottom center  / cover",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

function ReservedAreaCover() {
  return <RightPanel />;
}
export { ReservedAreaCover };
