export default {
  PrivacyFooter: intl => ({
    privacyLink: intl.formatMessage({
      description: "Login page privacy link",
      defaultMessage: "Privacy",
    }),
    legalNotesLink: intl.formatMessage({
      description: "Login page legal notes link",
      defaultMessage: "Note Legali",
    }),
  }),
};
