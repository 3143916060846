import PropTypes from "prop-types";
// react mui
import { Grid } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  AutocompleteControlAPI,
  TextFieldControl,
  DatePickerControl,
  SelectControl,
} from "components/index";

// constants
import { ITALY } from "constants/countries";
import { apiAddresses } from "constants/api";

import { languages } from "constants/languages";
import { useState } from "react";

// import { GoogleMaps } from "components/index;

const AnagraficheFields = ({
  control,
  errors,
  watch,
  reset,
  noGeo = false,
}) => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl).page.anagrafiche;

  // state
  const [nationValue, setNationValue] = useState(watch("nation") || null);
  const [provinceValue, setProvinceValue] = useState(watch("province") || null);
  const [cityValue, setCityValue] = useState(watch("city") || null);
  const [postalCodeValue, setPostalCodeValue] = useState(
    watch("postalCode") || null
  );

  const nation = watch("nation");

  // TODO refactor
  const handleResetNation = data => {
    reset({
      ...data,
      city: undefined,
      province: undefined,
      postalCode: undefined,
      foreignCity: undefined,
      foreignPostalCode: undefined,
      street: undefined,
    });
  };

  watch((data, { name }) => {
    if (name == "nation") {
      if (!data[name]) {
        return handleResetNation(data);
      }
      if (data[name].id != ITALY.id) {
        handleResetNation(data);
      }
    }
    // if we undo the selection of province
    // then subsequentially the city and the
    // postalCode will be ""
    if (name == "province") {
      // if (!data[name]) {
      setCityValue(undefined);
      setPostalCodeValue(undefined);
      reset({
        ...data,
        city: null,
        postalCode: null,
        street: null,
      });
      // }
    }
    // if we undo the selection of city
    // then subsequentially the postalCode
    //  will be null
    if (name == "city") {
      // if (!data[name]) {
      setPostalCodeValue(undefined);
      reset({
        ...data,
        postalCode: null,
        street: null,
      });
      // }
    }
  });

  return (
    <>
      <Grid item xs={12} sm={3}>
        <DatePickerControl
          control={control}
          name={"birthDate"}
          placeholder={i18n.birthDate.label}
          label={i18n.birthDate.label}
          maxDate={new Date()}
          errors={errors}
          errorMsg={i18n.birthDate.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SelectControl
          control={control}
          name={"language"}
          placeholder={i18n.language.label}
          label={i18n.language.label}
          errors={errors}
          errorObj={i18n.language.errors}
          isRequired
          defaultValue={watch("language")}
          options={languages}
        />
      </Grid>
      {!noGeo && (
        <>
          <Grid item xs={12} sm={3}>
            <AutocompleteControlAPI
              control={control}
              name={"nation"}
              label={i18n.nation.label}
              errors={errors}
              errorObj={i18n.nation.errors}
              endpoint={`${apiAddresses.API_BASE}${apiAddresses.COUNTRIES}`}
              queryParam={"q"}
              value={nationValue}
              noOptionsText={i18n.nation.noOptionsText}
              onChange={setNationValue}
              isRequired
            />
          </Grid>
          {nation?.id == ITALY.id && (
            <>
              <Grid item xs={12} sm={3}>
                <AutocompleteControlAPI
                  control={control}
                  name={"province"}
                  label={i18n.province.label}
                  errors={errors}
                  errorObj={i18n.province.errors}
                  endpoint={`${apiAddresses.API_BASE}${apiAddresses.PROVINCES}`}
                  queryParam={`q`}
                  noOptionsText={i18n.province.noOptionsText}
                  disabled={!nationValue}
                  value={provinceValue}
                  defaultValue={provinceValue}
                  onChange={setProvinceValue}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AutocompleteControlAPI
                  control={control}
                  name={"city"}
                  label={i18n.city.label}
                  errors={errors}
                  errorObj={i18n.city.errors}
                  endpoint={`${apiAddresses.API_BASE}${apiAddresses.CITIES}`}
                  queryParam={`ParentId=${provinceValue?.id}&q`}
                  noOptionsText={i18n.city.noOptionsText}
                  disabled={!provinceValue}
                  value={cityValue}
                  defaultValue={cityValue}
                  onChange={setCityValue}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AutocompleteControlAPI
                  control={control}
                  name={"postalCode"}
                  label={i18n.postalCode.label}
                  errors={errors}
                  errorObj={i18n.postalCode.errors}
                  endpoint={`${apiAddresses.API_BASE}${apiAddresses.ZIPCODES}`}
                  queryParam={`ParentId=${cityValue?.id}&q`}
                  noOptionsText={i18n.postalCode.noOptionsText}
                  disabled={!cityValue}
                  value={postalCodeValue}
                  defaultValue={postalCodeValue}
                  onChange={setPostalCodeValue}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={"street"}
                  placeholder={i18n.street.label}
                  label={i18n.street.label}
                  errors={errors}
                  errorObj={i18n.street.errors}
                  isRequired
                  disabled={!postalCodeValue}
                  defaultValue={watch("street")}
                />
              </Grid>
            </>
          )}
          {nation?.id != ITALY.id && (
            <>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={"foreignCity"}
                  placeholder={i18n.foreignCity.label}
                  label={i18n.foreignCity.label}
                  errors={errors}
                  errorObj={i18n.foreignCity.errors}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={"foreignPostalCode"}
                  placeholder={i18n.foreignPostalCode.label}
                  label={i18n.foreignPostalCode.label}
                  errors={errors}
                  errorObj={i18n.foreignPostalCode.errors}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextFieldControl
                  control={control}
                  name={"street"}
                  placeholder={i18n.street.label}
                  label={i18n.street.label}
                  errors={errors}
                  errorObj={i18n.street.errors}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

AnagraficheFields.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  reset: PropTypes.func,
  noGeo: PropTypes.bool,
};
export default AnagraficheFields;
