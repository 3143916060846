export default {
  ProfileForm: intl => ({
    userCreation: {
      success: intl.formatMessage({
        description: "user creation success message",
        defaultMessage: "Utente creato con successo",
      }),
    },
    userEdit: {
      success: intl.formatMessage({
        description: "user creation success message",
        defaultMessage: "Utente modificato con successo",
      }),
    },
    page: {
      selectType: {
        label: intl.formatMessage({
          description: "Template Profile Form select type label",
          defaultMessage: "tipo utente",
        }),
        error: intl.formatMessage({
          description: "Template Profile Form select type Error",
          defaultMessage: "Il campo tipologia è obbligatorio",
        }),
        businessPartner: intl.formatMessage({
          description: "Template Profile Form select type Business Partner",
          defaultMessage: "Business Partner",
        }),
        press: intl.formatMessage({
          description: "Template Profile Form select type Press",
          defaultMessage: "Press",
        }),
        professional: intl.formatMessage({
          description: "Template Profile Form select type Professional",
          defaultMessage: "Professional",
        }),
        private: intl.formatMessage({
          description: "Template Profile Form select type private",
          defaultMessage: "End user",
        }),
      },
      businessPartnerFields: {
        businessName: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field business-name error",
              defaultMessage: "La ragione sociale è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field business-name label",
            defaultMessage: "Ragione Sociale",
          }),
        },
        vatID: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field vat ID error required",
              defaultMessage: "La Partita IVA è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field vat ID label",
            defaultMessage: "Partita IVA",
          }),
        },
        currency: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field Currency error required",
              defaultMessage: "La valuta è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field Currency label",
            defaultMessage: "Valuta",
          }),
        },
        billBook: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field billBook error",
              defaultMessage: "La billBook è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field billBook label",
            defaultMessage: "billBook",
          }),
        },
        zone: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field zone error",
              defaultMessage: "La zona è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field zone label",
            defaultMessage: "Zona",
          }),
        },
        division: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field division error",
              defaultMessage: "La divisione è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field division label",
            defaultMessage: "Divisione",
          }),
        },
        emailPoliform: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field emailPoliform error",
              defaultMessage:
                "L'email di notifica ordini Poliform è obbligatoria",
            }),
            email: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field emailPoliform error mail ",
              defaultMessage: "Inserire una mail Poliform valida",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field emailPoliform label",
            defaultMessage: "email di notifica ordini Poliform",
          }),
        },
        emailVarenna: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field emailVarenna error",
              defaultMessage:
                "L'email di notifica ordini Varenna è obbligatoria",
            }),
            email: intl.formatMessage({
              description:
                "Template Profile Form Business Partner Field emailVarenna error mail ",
              defaultMessage: "Inserire una mail Varenna valida",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Business Partner Field emailVarenna label",
            defaultMessage: "email di notifica ordini Varenna",
          }),
        },
      },
      professional: {
        professionalName: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field name error required",
              defaultMessage: "il Nome del professional è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Professional Field name label",
            defaultMessage: "Nome",
          }),
        },
        professionalSurname: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field surname error required",
              defaultMessage: "il Cognome del professional è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professional Field surname label",
            defaultMessage: "Cognome",
          }),
        },
        email: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field email error required",
              defaultMessage: "L'email del professional è obbligatorio",
            }),
            email: intl.formatMessage({
              description:
                "Template Profile Form Professional Field email error email",
              defaultMessage: "Inserire una mail valida",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Professional Field email label",
            defaultMessage: "E-Mail",
          }),
        },

        sector: {
          errors: {
            fetchError: intl.formatMessage({
              description:
                "Template Profile Form Press Field sector error fetch error",
              defaultMessage: "Errore di comunicazione con il server",
            }),
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field sector error required",
              defaultMessage:
                "Almeno un settore del Professional è obbligatorio",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Professional Field sector error min",
              defaultMessage: "Selezionare almeno un settore",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professional Field sector label",
            defaultMessage: "Settore di interesse",
          }),
        },
        qualification: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field qualification error required",
              defaultMessage: "La qualifica del professionista è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professional Field qualification label",
            defaultMessage: "Qualifica",
          }),
        },
        website: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professional Field website error required",
              defaultMessage: "Il sito web del professional è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form Professional Field website error matches",
              defaultMessage: "Inserire un sito web valido",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professional Field website label",
            defaultMessage: "Sito Web",
          }),
        },
        mobile: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professionale Field mobile phone required",
              defaultMessage: "Il numero di Cellulare è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form Professionale Field mobile phone matches",
              defaultMessage: "Inserire un numero di Cellulare valido",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professionale Field mobile phone label",
            defaultMessage: "Cellulare",
          }),
        },
        company: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Professionale Field  company required",
              defaultMessage: "L'azienda è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Professionale Field company label",
            defaultMessage: "Azienda",
          }),
        },
      },
      press: {
        pressName: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field name error required",
              defaultMessage: "il Nome del Press è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field name label",
            defaultMessage: "Nome",
          }),
        },
        pressSurname: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field surname error required",
              defaultMessage: "il Cognome del Press è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field surname label",
            defaultMessage: "Cognome",
          }),
        },
        email: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field email error required",
              defaultMessage: "L'email del Press è obbligatorio",
            }),
            email: intl.formatMessage({
              description:
                "Template Profile Form Press Field email error email",
              defaultMessage: "Inserire una mail valida",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field email label",
            defaultMessage: "E-Mail",
          }),
        },
        category: {
          errors: {
            fetchError: intl.formatMessage({
              description:
                "Template Profile Form Press Field categories error fetch error",
              defaultMessage: "Errore di comunicazione con il server",
            }),
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field categories error required",
              defaultMessage: "Almeno una categoria del Press è obbligatorio",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Press Field categories error min",
              defaultMessage: "Selezionare almeno una categoria",
            }),
          },

          label: intl.formatMessage({
            description: "Template Profile Form Press Field category label",
            defaultMessage: "Categoria",
          }),
          categories: {
            firstCategoryLabel: intl.formatMessage({
              description:
                "Template Profile Form Press Field category firstCategory label",
              defaultMessage: "Categoria uno",
            }),
            secondCategoryLabel: intl.formatMessage({
              description:
                "Template Profile Form Press Field category secondCategory label",
              defaultMessage: "Categoria due",
            }),
          },
        },
        sector: {
          errors: {
            fetchError: intl.formatMessage({
              description:
                "Template Profile Form Press Field sector error fetch error",
              defaultMessage: "Errore di comunicazione con il server",
            }),
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field sector error required",
              defaultMessage: "Almeno un settore del Press è obbligatorio",
            }),
            min: intl.formatMessage({
              description: "Template Profile Form Press Field sector error min",
              defaultMessage: "Selezionare almeno un settore",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field sector label",
            defaultMessage: "Settore (selezione multipla)",
          }),
        },
        website: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field website error required",
              defaultMessage: "Il sito web del Press è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form Press Field website error matches",
              defaultMessage: "Inserire un sito web valido",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field website label",
            defaultMessage: "Sito Web",
          }),
        },
        company: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field  company required",
              defaultMessage: "L'azienda è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field company label",
            defaultMessage: "Azienda",
          }),
        },
        telephone: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field telephone required",
              defaultMessage: "Il numero di telefono è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form Press Field telephone matches",
              defaultMessage: "Inserire un numero di telefono valido",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field telephone label",
            defaultMessage: "Telefono",
          }),
        },
        mobile: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Press Field mobile phone required",
              defaultMessage: "Il numero di Cellulare è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form Press Field mobile phone matches",
              defaultMessage: "Inserire un numero di Cellulare valido",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Press Field mobile phone label",
            defaultMessage: "Cellulare",
          }),
        },
      },
      private: {
        privateName: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Private Field name error required",
              defaultMessage: "il Nome del privato è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form private Field name label",
            defaultMessage: "Nome",
          }),
        },
        privateSurname: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form private Field surname error required",
              defaultMessage: "il Cognome del privato è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form private Field surname label",
            defaultMessage: "Cognome",
          }),
        },
        email: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form private Field email error required",
              defaultMessage: "L'email del privato è obbligatorio",
            }),
            email: intl.formatMessage({
              description:
                "Template Profile Form private Field email error email",
              defaultMessage: "Inserire una mail valida",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form private Field email label",
            defaultMessage: "E-Mail",
          }),
        },
        mobile: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form private Field mobile phone required",
              defaultMessage: "Il numero di Cellulare è obbligatorio",
            }),
            matches: intl.formatMessage({
              description:
                "Template Profile Form private Field mobile phone matches",
              defaultMessage: "Inserire un numero di Cellulare valido",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form private Field mobile phone label",
            defaultMessage: "Cellulare",
          }),
        },
      },
      anagrafiche: {
        nation: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field nation error",
              defaultMessage: "La Nazione è obbligatoria",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field nation type error",
              defaultMessage: "La Nazione è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Anagrafiche Field nation label",
            defaultMessage: "Nazione",
          }),
          noOptionsText: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field nation no options text",
            defaultMessage: "Nessuna nazione corrispondente",
          }),
        },
        language: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field language error required",
              defaultMessage: "La lingua è obbligatoria",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field language label",
            defaultMessage: "Lingua",
          }),
        },
        birthDate: {
          error: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field birthDate error",
            defaultMessage: "La data di nascita è obbligatoria",
          }),
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field birthDate label",
            defaultMessage: "Data di nascita",
          }),
        },
        foreignCity: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error required",
              defaultMessage: "La città è obbligatoria",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error min",
              defaultMessage: "La città deve contenere almeno 3 caratteri",
            }),
            max: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error max",
              defaultMessage: "La città non può contenere più di 40 caratteri",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error typeerror",
              defaultMessage: "Inserire una città valida",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Anagrafiche Field city label",
            defaultMessage: "Città",
          }),
        },
        city: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error required",
              defaultMessage: "La città è obbligatoria",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error min",
              defaultMessage: "La città deve contenere almeno 3 caratteri",
            }),
            max: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error max",
              defaultMessage: "La città non può contenere più di 40 caratteri",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field city error typeerror",
              defaultMessage: "Inserire una città valida",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Anagrafiche Field city label",
            defaultMessage: "Città",
          }),
          noOptionsText: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field city no options text",
            defaultMessage: "Nessuna Città corrispondente",
          }),
        },

        province: {
          errors: {
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field Province error typeError",
              defaultMessage: "Selezionare una provincia valida",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field Province label",
            defaultMessage: "Provincia",
          }),
          noOptionsText: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field Province no options text",
            defaultMessage: "Nessuna Provincia corrispondente",
          }),
        },
        location: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field location error required",
              defaultMessage: "La Location è obbligatoria",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field location error min",
              defaultMessage: "La Location deve contenere almeno 3 caratteri",
            }),
            max: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field location error max",
              defaultMessage:
                "La Location non può contenere più di 40 caratteri",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field location error typeError",
              defaultMessage: "Inserire un indirizzo valido",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field location label",
            defaultMessage: "Location",
          }),
        },
        street: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field street error required",
              defaultMessage: "L'indirizzo è obbligatorio",
            }),
            min: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field street error min",
              defaultMessage: "L'indirizzo deve contenere almeno 3 caratteri",
            }),
            max: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field street error max",
              defaultMessage:
                "L'indirizzo non può contenere più di 40 caratteri",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field street error typeError",
              defaultMessage: "Inserire un indirizzo valido",
            }),
          },
          label: intl.formatMessage({
            description: "Template Profile Form Anagrafiche Field street label",
            defaultMessage: "Indirizzo",
          }),
        },
        foreignPostalCode: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field postalCode error required",
              defaultMessage: "Il CAP è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field postalCode label",
            defaultMessage: "CAP",
          }),
        },
        postalCode: {
          errors: {
            required: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field postalCode error required",
              defaultMessage: "Il CAP è obbligatorio",
            }),
            typeError: intl.formatMessage({
              description:
                "Template Profile Form Anagrafiche Field postalCode error typeError",
              defaultMessage: "Il CAP è obbligatorio",
            }),
          },
          label: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field postalCode label",
            defaultMessage: "CAP",
          }),
          noOptionsText: intl.formatMessage({
            description:
              "Template Profile Form Anagrafiche Field postalCode no options text",
            defaultMessage: "Nessun CAP corrispondente",
          }),
        },
      },
      fetchError: {
        server: intl.formatMessage({
          description: "Template Profile Form fetch error server 500",
          defaultMessage:
            "Errore di comunicazione con il server, riprovare più tardi",
        }),
        userExists: intl.formatMessage({
          description:
            "Template Profile Form fetch error server 409 user exists",
          defaultMessage: "La mail inserita è già presente a sistema",
        }),
      },
    },
    employees: {
      createUserTitle: intl.formatMessage({
        description: "Create new employee user title",
        defaultMessage: "Crea Nuovo",
      }),
      editUserTitle: intl.formatMessage({
        description: "Edit new employee user title",
        defaultMessage: "Modifica",
      }),
      submit: intl.formatMessage({
        description: "Create new employe form submit",
        defaultMessage: "Salva",
      }),
      cancel: intl.formatMessage({
        description: "Create new employee form cancel",
        defaultMessage: "Annulla",
      }),
      delete: intl.formatMessage({
        description: "Create new employee form delete",
        defaultMessage: "Elimina",
      }),
      informative: {
        label: intl.formatMessage({
          description: "Create new employee informative label",
          defaultMessage: "Profilazione per finalità marketing",
        }),
      },
      dialog: {
        title: intl.formatMessage({
          description: "Delete employee dialog title",
          defaultMessage: "Elimina",
        }),
        content: intl.formatMessage({
          description: "Delete employee dialog content",
          defaultMessage: "Sei sicuro di voler eliminare l'utente?",
        }),
        yes: intl.formatMessage({
          description: "Delete employee dialog content yes response",
          defaultMessage: "OK",
        }),
        no: intl.formatMessage({
          description: "Delete employee dialog content no response",
          defaultMessage: "CHIUDI",
        }),
      },
      employeeName: {
        errors: {
          required: intl.formatMessage({
            description: "Create new employee name error required",
            defaultMessage: "Il nome è obbligatorio",
          }),
        },
        label: intl.formatMessage({
          description: "Create new employee name label",
          defaultMessage: "Nome",
        }),
      },
      employeeSurname: {
        errors: {
          required: intl.formatMessage({
            description: "Create new employee surname error required",
            defaultMessage: "Il cognome è obbligatorio",
          }),
        },
        label: intl.formatMessage({
          description: "Create new employee surname label",
          defaultMessage: "Cognome",
        }),
      },
      employeeEmail: {
        errors: {
          required: intl.formatMessage({
            description: "Create new employee surname error required",
            defaultMessage: "L'email è obbligatoria",
          }),
          email: intl.formatMessage({
            description: "Create new employee surname error email invalid",
            defaultMessage: "Inserire una mail valida",
          }),
        },

        label: intl.formatMessage({
          description: "Create new employee surname label",
          defaultMessage: "Email",
        }),
      },
    },
    professionalUser: {
      rating: {
        title: intl.formatMessage({
          description: "Create Professional User rating title",
          defaultMessage: "Rating",
        }),
        add: intl.formatMessage({
          description: "Create Professional User rating add ",
          defaultMessage: "Aggiungi",
        }),
        areaOfInterest: {
          label: intl.formatMessage({
            description: "Create Professional User area Of Interest label",
            defaultMessage: "Settore di interesse",
          }),
          errors: intl.formatMessage({
            description: "Create Professional User area Of Interest error",
            defaultMessage: "Settore di interesse obbligatorio",
          }),
        },
        score: intl.formatMessage({
          description: "Create Professional User score ",
          defaultMessage: "Rating",
        }),
      },
    },
  }),
};
