import PropTypes from "prop-types";

// react mui
import { Grid } from "@mui/material";

// components
import { TextFieldControl } from "components";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

const MainParticipantControl = ({
  participant,
  control,
  errors,
  disabled = false,
  marginTop = false,
}) => {
  const intl = useIntl();
  const i18nCommons = translations.Commons(intl);

  return (
    <Grid spacing={2} mt={marginTop ? 2 : 0} item xs={12} container>
      <Grid item xs={12} md={3}>
        <TextFieldControl
          control={control}
          name={`participant.name`}
          defaultValue={`${participant?.name}`}
          placeholder={i18nCommons.name.label}
          label={i18nCommons.name.label}
          errors={errors}
          isRequired
          errorObj={i18nCommons.name.errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextFieldControl
          control={control}
          name={`participant.lastname`}
          defaultValue={`${participant?.lastname}`}
          placeholder={i18nCommons.lastname.label}
          label={i18nCommons.lastname.label}
          errors={errors}
          isRequired
          errorObj={i18nCommons.lastname.errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
        <TextFieldControl
          control={control}
          name={`participant.email`}
          defaultValue={`${participant?.email}`}
          placeholder={i18nCommons.email.label}
          label={i18nCommons.email.label}
          isRequired
          errors={errors}
          errorObj={i18nCommons.email.errors}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

MainParticipantControl.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  participant: PropTypes.shape({
    company: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    isGuest: PropTypes.bool,
    isMain: PropTypes.bool,
    lastname: PropTypes.string,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  marginTop: PropTypes.bool,
};
export { MainParticipantControl };
