export default {
  Home: intl => ({
    page: {
      title: intl.formatMessage({
        description: "My Area Home page title",
        defaultMessage: "Benvenuto/a",
      }),
      reservedArea: intl.formatMessage({
        description: "My Area Home page title",
        defaultMessage: "Area Riservata",
      }),
    },
  }),
};
