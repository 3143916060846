import { useState } from "react";

import PropTypes from "prop-types";

// use-http
import useFetch from "use-http";

// react mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// react hook form
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  SelectControl,
  TextFieldControl,
  AutocompleteControlAPI,
} from "components/index";

// constants

const FiltersPanel = ({ filtersList, setQueryParameters, setPageIndex }) => {
  const intl = useIntl();
  const i18n = translations.components.FiltersList(intl);

  const [resetValues, setResetValues] = useState(false);

  const {
    data: rolesData,
    loading: loadingRoles,
    error: errorRoles,
  } = useFetch("/CorporateGroups", []);

  const { control, handleSubmit, reset } = useForm();

  const onSubmit = data => {
    data = { ...data, GeoCountryId: data?.GeoCountryId?.id || "" };
    let tmpQuery = "";
    Object.keys(data).map(el => {
      tmpQuery = `${tmpQuery}&${el}=${data[el]}`;
    });
    setPageIndex(0);
    setQueryParameters(tmpQuery);
  };

  const onReset = () => {
    setQueryParameters("");
    setPageIndex(0);
    setResetValues(!resetValues);
    reset();
  };

  const getSelectValue = endpoint => {
    console.log("C.LOG ~ file: FiltersPanel.js ~ line 51 ~ endpoint", endpoint);
  };

  const renderFilters = () => {
    return filtersList.map(
      ({ key, autocompleteKey, label, type, endpoint, value, endIcon }) => {
        switch (type) {
          case "select":
            return (
              <Grid item xs={12} md={3}>
                <SelectControl
                  control={control}
                  name={key}
                  placeholder={label}
                  label={label}
                  options={
                    (endpoint && getSelectValue(endpoint)) || value || []
                  }
                  fullWidth
                />
              </Grid>
            );
          case "text":
            return (
              <Grid item xs={12} md={3}>
                <TextFieldControl
                  control={control}
                  name={key}
                  placeholder={label}
                  label={label}
                  onlyIcon
                  endIcon={endIcon}
                  errorObj={{ required: "Inserire un nome" }}
                />
              </Grid>
            );
          case "autocomplete":
            return (
              <Grid item xs={12} md={3}>
                <AutocompleteControlAPI
                  control={control}
                  resetValue={resetValues}
                  name={key}
                  label={label}
                  endpoint={endpoint}
                  queryParam={autocompleteKey}
                />
              </Grid>
            );
          case "role":
            return (
              <Grid item xs={12} sm={3}>
                {errorRoles && <Typography>{i18n.roles.fetchError}</Typography>}
                {loadingRoles && <CircularProgress size={25} />}
                {rolesData && !errorRoles && (
                  <SelectControl
                    control={control}
                    name={key}
                    label={i18n.roles.label}
                    options={rolesData}
                    fullWidth
                    returnName
                  />
                )}
              </Grid>
            );
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container fullWidth spacing={2} marginTop={1}>
        {renderFilters()}
      </Grid>
      <Grid container fullWidth spacing={2} marginTop={1}>
        <Grid item>
          <Button onClick={onReset}>{i18n.reset}</Button>
        </Grid>
        <Grid item>
          <Button type="submit">{i18n.send}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

FiltersPanel.propTypes = {
  filtersList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      endpoint: PropTypes.string,
      endIcon: PropTypes.object,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  queryParameters: PropTypes.string,
  setQueryParameters: PropTypes.func,
  setPageIndex: PropTypes.func,
};

export { FiltersPanel };
