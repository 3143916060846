import { Button, Link } from "@mui/material";
import { styled } from "@mui/system";

export const HeaderContainer = styled("div")(() => ({
  width: "100%",
  height: 30,
  marginTop: 10,
}));

export const CreateButton = styled(Button)(() => ({
  padding: 10,
}));

export const ExportButton = styled(Link)(() => ({}));
