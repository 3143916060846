import PropTypes from "prop-types";

// components
import { Link } from "components";
import { ReactComponent as LockerIcon } from "assets/icons/lockpick.svg";

// constants
import {
  SCHEDULE_EVENT_DETAIL,
  SCHEDULE_EVENT_PRENOTATION,
} from "constants/routes";
import { eventTipology } from "constants/enums";

const EventItem = ({ event }) => {
  return (
    <Link
      to={
        event.typology === eventTipology.RESTRICTED
          ? SCHEDULE_EVENT_PRENOTATION
          : SCHEDULE_EVENT_DETAIL
      }
      state={event}
    >
      <div
        style={{
          fontSize: ".7rem",
          backgroundColor: "black",
          padding: 5,
          borderRadius: 5,
          color: "white",
          position: "relative",
        }}
      >
        {event.typology === eventTipology.RESTRICTED && (
          <LockerIcon style={{ position: "absolute", right: "5px" }} />
        )}
        {event.title}
      </div>
    </Link>
  );
};
EventItem.propTypes = {
  event: PropTypes.object,
};
export { EventItem };
