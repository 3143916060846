import { useIntl } from "react-intl";
import translations from "utils/translations";

const useManageParticipantsFilters = () => {
  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);

  const filtersList = [
    { key: "Email", label: i18n.header.email, type: "text" },

    { key: "Group", type: "role" },

    { key: "jobTitle", label: i18n.header.qualification, type: "text" },
  ];

  return filtersList;
};
export default useManageParticipantsFilters;
