import { useContext } from "react";

import { useIntl } from "react-intl";
import translations from "utils/translations";

import { useNavigate } from "react-router-dom";

import { AuthContext } from "context/AuthContext";
import { ListIconTypes } from "constants/enums";
import { REGISTRY_POLIFORM_USERS_EDIT } from "constants/routes";
import { ActionIconManager } from "components";

const useHeader = () => {
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);
  // eslint-disable-next-line
  const { user } = useContext(AuthContext);

  let navigate = useNavigate();

  const header = [
    {
      name: i18n.header.name,
      accessor: "name",
    },
    {
      name: i18n.header.lastname,
      accessor: "lastname",
    },
    {
      name: i18n.header.email,
      accessor: "email",
    },
    {
      name: i18n.header.mobilePhone,
      accessor: "mobilePhone",
    },
    {
      name: i18n.header.role,
      accessor: "group",
    },
    {
      name: i18n.header.numberCustomer,
      accessor: "numberCustomer",
      Cell: element => (!element.value ? "0" : element.value),
    },
    {
      name: i18n.header.numberStaff,
      accessor: "numberStaff",
      Cell: element => (!element.value ? "0" : element.value),
    },
    {
      name: i18n.header.state,
      accessor: "status",
    },
    {
      // Header: "Cognome",
      accessor: "editrights", // accessor is the "key" in the data
      Cell: element => (
        <ActionIconManager
          listActions={[
            {
              type: ListIconTypes.EDIT,
              callback: () =>
                navigate(REGISTRY_POLIFORM_USERS_EDIT, {
                  state: { id: element.row.original.id },
                }),
            },
          ]}
        />
      ),
    },
  ];

  return [header];
};

export default useHeader;
