import { useEffect, useState } from "react";

// react-router-dom
import { useNavigate, useSearchParams } from "react-router-dom";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react-hook-form
import { useForm } from "react-hook-form";

// use-http
import { useFetch } from "use-http";

// mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// components
import {
  Link,
  ReservedAreaCover,
  ReservedAreaTitle,
  TextFieldControl,
  PrivacyFooter,
} from "components/index";

// custom styles
import { Wrapper, LeftPanel, FormContainer } from "./CreatePassword.styles";

// constants
import { LOGIN } from "constants/routes";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// API
import { apiOnboarding, apiUser } from "constants/api";

const schema = yup
  .object({
    password: yup
      .string()
      .required("No password provided.")
      // Minimum eight characters, at least one uppercase letter,
      // one lowercase letter, one number and one special character:
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        "Password is too short - should be 8 chars minimum."
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const CreatePassword = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.CreatePassword(intl).page;
  const { put, response, loading, error: fetchError } = useFetch();

  // react router dom params
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: searchParams.get("username"),
      code: searchParams.get("code"),
    },
  });

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async data => {
    if (searchParams.get("code")) {
      // eslint-disable-next-line
      const newUser = await put(
        `${apiUser.API_BASE}${apiUser.RESET_PASSWORD}`,
        data
      );
      if (response.ok) {
        setSubmitted(true);
      }
    } else {
      // eslint-disable-next-line
      const newUser = await put(
        `${apiOnboarding.API_BASE}${apiOnboarding.SETPASSWORD}`,
        data
      );
      if (response.ok) {
        setSubmitted(true);
      }
    }
  };
  useEffect(() => {
    if (
      searchParams.get("username") == null ||
      searchParams.get("username") == ""
    ) {
      navigate(LOGIN, { replace: true });
    }
  }, []);

  return (
    <Wrapper>
      <LeftPanel>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <ReservedAreaTitle />
            </Grid>

            {fetchError && (response.status == 500 || response.status == 404) && (
              <Grid item>
                <Typography variant={"body1"} color="error">
                  {i18n.fetchError.server}
                </Typography>
              </Grid>
            )}
            {fetchError && response.status == 400 && (
              <Grid item>
                <Typography variant={"body1"} color="error">
                  {i18n.badRequest.server}
                </Typography>
              </Grid>
            )}
            {loading && <CircularProgress />}
            {!fetchError && !loading && !submitted && (
              <>
                <Grid item>
                  <Typography fontSize={18} variant={"body1"}>
                    {i18n.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextFieldControl
                    control={control}
                    name={"password"}
                    placeholder={i18n.form.password.label}
                    label={i18n.form.password.label}
                    errors={errors}
                    errorObj={i18n.form.password.errors}
                    isRequired
                    type={"password"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextFieldControl
                    control={control}
                    name={"passwordConfirmation"}
                    placeholder={i18n.form.confirmPassword.label}
                    label={i18n.form.confirmPassword.label}
                    errors={errors}
                    errorObj={i18n.form.confirmPassword.errors}
                    isRequired
                    type={"password"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button style={{ fontSize: 16 }} type="submit">
                    {i18n.form.submit}
                  </Button>
                </Grid>
              </>
            )}
            {submitted && (
              <>
                <Grid item>
                  <Typography variant={"h6"}>{i18n.success.title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"body1"}>
                    {i18n.success.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={LOGIN}>
                    <Button variant="outlined" type="submit">
                      {i18n.success.button.label}
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </FormContainer>
        <PrivacyFooter />
      </LeftPanel>
      <ReservedAreaCover />
    </Wrapper>
  );
};
export { CreatePassword };
