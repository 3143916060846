export default {
  UnauthorizedDialog: intl => ({
    component: {
      title: intl.formatMessage({
        description: "UnauthorizedDialog title",
        defaultMessage: "Errore",
      }),
      AUTH: intl.formatMessage({
        description: "UnauthorizedDialog title",
        defaultMessage: "Non hai i permessi per visualizzare questa sezione",
      }),
      SERVER: intl.formatMessage({
        description: "UnauthorizedDialog serverError",
        defaultMessage:
          "Errore di comunicazione con il server, riprovare più tardi",
      }),
      close: intl.formatMessage({
        description: "UnauthorizedDialog close dialog label",
        defaultMessage: "Chiudi",
      }),
    },
  }),
};
