// date-fns
import { addDays, differenceInDays, sub } from "date-fns";

const useEvents = () => {
  // Main parse of events used in Agenda.js
  const parseEvents = events => {
    let tmpEvents = [];
    events.map(event => {
      const result = differenceInDays(
        new Date(event.endDate),
        new Date(event.startDate)
      );
      let tmpEvent = {
        ...event,
        id: event.id,
        title: event.name,
        description: event.description,
        location: event.address.location,
        address: event.address,
        start: new Date(event.startDate),
        end:
          result === 0
            ? new Date(event.endDate)
            : addDays(new Date(event.endDate), 1),
      };
      tmpEvents.push(tmpEvent);
    });
    return tmpEvents;
  };

  // Main parse of single event in EventPrenotation
  const parseEvent = evt => {
    let tmpObj = {};
    let tmpSlots = [];
    evt.slots.map(slot => {
      let tmpSlot = {};
      let tmpAvailabilities = [];
      slot.availabilities.map(availability => {
        let tmpAvailability = {};

        tmpAvailability = {
          ...availability,
          // start: new Date(slot.start),
          // end: new Date(slot.end),
          start: sub(new Date(slot.start), {
            minutes: new Date(slot.start).getTimezoneOffset(),
          }),
          end: sub(new Date(slot.end), {
            minutes: new Date(slot.end).getTimezoneOffset(),
          }),
        };
        tmpAvailabilities.push(tmpAvailability);
      });

      tmpSlot = {
        ...slot,
        // start: new Date(slot.start),
        // end: new Date(slot.end),
        start: sub(new Date(slot.start), {
          minutes: new Date(slot.start).getTimezoneOffset(),
        }),
        end: sub(new Date(slot.end), {
          minutes: new Date(slot.end).getTimezoneOffset(),
        }),
        availabilities: tmpAvailabilities,
      };
      tmpSlots.push(tmpSlot);
    });
    tmpObj = {
      ...evt,
      start: sub(new Date(evt.calendarRangeMin), {
        minutes: new Date(evt.calendarRangeMin).getTimezoneOffset(),
      }),
      end: sub(new Date(evt.calendarRangeMax), {
        minutes: new Date(evt.calendarRangeMax).getTimezoneOffset(),
      }),

      slots: tmpSlots,
    };
    return tmpObj;
  };

  return { parseEvents, parseEvent };
};
export { useEvents };
