import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const SlotTooltip = ({ content = <p></p>, children }) => {
  return (
    <Tooltip
      title={content}
      placement="top"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "solid black 1px",
            minWidth: 200,
            minHeight: 100,
            background: "white",
            color: "black",
            borderRadius: 0,
            display: "grid",
            placeContent: "center",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

SlotTooltip.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
};
export { SlotTooltip };
