import PropTypes from "prop-types";
import {
  usePagination,
  useTable,
  // useSortBy,
} from "react-table/dist/react-table.development";
import { MenuItem, Pagination, Typography } from "@mui/material";
import { useIntl } from "react-intl";

// styled components
import {
  TableRow,
  TableWrapper,
  TablePaginationWrapper,
  TablePaginationPageSizeWrapper,
} from "./ReactTable.styles";
import { useEffect } from "react";
import { LinearProgress } from "components/index";

import translations from "utils/translations";

const ReactTable = ({
  columns,
  data,
  setFetchPageSize,
  setFetchPageIndex,
  loading,
  elementCount,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    // useSortBy,
    usePagination
  );

  useEffect(() => {
    setFetchPageSize(pageSize);
    setFetchPageIndex(pageIndex);
  }, [setFetchPageSize, setFetchPageIndex, pageIndex, pageSize]);

  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);

  return (
    <>
      {loading && <LinearProgress />}
      <TableWrapper {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <TableRow
                key={Math.random() * 20}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th
                      key={Math.random() * 20}
                      {...column
                        .getHeaderProps
                        // column.getSortByToggleProps()
                        ()}
                    >
                      <Typography variant="body1">
                        <b>
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </b>
                      </Typography>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))
                }
              </TableRow>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map(row => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <TableRow key={Math.random() * 20} {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <td key={Math.random() * 20} {...cell.getCellProps()}>
                          <Typography
                            lineHeight={1}
                            variant="body1"
                            fontSize={10}
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </Typography>
                        </td>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </tbody>
      </TableWrapper>
      {!loading && (
        <TablePaginationWrapper>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <Typography variant="body1" marginRight={1}>
              {i18n.footer.pageSize}{" "}
            </Typography>
            <TablePaginationPageSizeWrapper
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={"page"}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </TablePaginationPageSizeWrapper>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">
              {i18n.footer.pageFrom} <strong>{pageIndex * pageSize + 1}</strong>{" "}
              {i18n.footer.pageTo}{" "}
              <strong>
                {elementCount < pageSize
                  ? elementCount
                  : pageIndex + 1 != pageCount
                  ? pageIndex * pageSize + pageSize
                  : elementCount}
              </strong>{" "}
              {i18n.footer.pageOf} <strong>{elementCount}</strong>{" "}
              {i18n.footer.pageElement}
            </Typography>

            <Pagination
              count={pageCount}
              page={pageIndex + 1}
              onChange={(e, indexPage) => gotoPage(indexPage - 1)}
              size="small"
            />
          </div>
        </TablePaginationWrapper>
      )}
    </>
  );
};
ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  setFetchPageIndex: PropTypes.func,
  setFetchPageSize: PropTypes.func,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  elementCount: PropTypes.number,
};
export { ReactTable };
