// mui
import { Grid, Typography } from "@mui/material";

// components
import { ReservedAreaCover, PrivacyFooter } from "components/index";

// custom styles
import {
  Wrapper,
  LeftPanel,
  FormContainer,
  TitleWrapper,
} from "./DownloadApp.styles";

const DownloadApp = () => {
  return (
    <Wrapper>
      <LeftPanel>
        <TitleWrapper>
          <Typography variant="h3" textAlign={"center"}>
            Scarica la App ufficiale
            <br />
            per gli eventi Poliform
          </Typography>
          <Typography textAlign={"center"} variant={"h5"} mt={4}>
            Potrai accogliere gli ospiti,
            <br />
            gestire la fase di check-in e di check-out
            <br />
            comodamente dall&apos;App
          </Typography>
        </TitleWrapper>
        <FormContainer>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Typography fontSize={16}>
                Al seguente{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  download
                  href="https://poliform-s3bucket-mobile.s3.amazonaws.com/poliform.apk"
                >
                  link
                </a>{" "}
                è possibile scaricare la App Android per accogliere gli ospiti
                degli eventi Poliform
              </Typography>
            </Grid>
          </Grid>
        </FormContainer>
        <PrivacyFooter />
      </LeftPanel>
      <ReservedAreaCover />
    </Wrapper>
  );
};
export { DownloadApp };
