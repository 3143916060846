import { useIntl } from "react-intl";
import translations from "utils/translations";

const useHeader = () => {
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);

  const header = [
    {
      name: i18n.header.name,
      accessor: "name",
    },
    {
      name: i18n.header.lastname,
      accessor: "lastname",
    },
    {
      name: i18n.header.country,
      accessor: "country",
    },
    {
      name: i18n.header.city,
      accessor: "city",
    },
    {
      name: i18n.header.email,
      accessor: "email",
    },
    {
      name: i18n.header.companyName,
      accessor: "companyName",
    },
    {
      name: i18n.header.qualification,
      accessor: "qualification",
    },
    {
      name: i18n.header.sector,
      accessor: "sector",
    },
    {
      name: i18n.header.rating,
      accessor: "rating",
    },
    {
      name: i18n.header.agent,
      accessor: "agent",
    },
    {
      name: i18n.header.state,
      accessor: "state",
    },
  ];

  return [header];
};

export default useHeader;
