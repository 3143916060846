import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// components
import { GreyCustomLabel, MainLayout } from "components/index";

import { ReactComponent as BackArrow } from "assets/icons/Icon_arrow_left.svg";

// utils
import translations from "utils/translations";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { ROOT } from "constants/routes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageEventDetail = () => {
  const intl = useIntl();
  // eslint-disable-next-line
  const i18n = translations.components.AgendaLists(intl);
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state) navigate(ROOT);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <BackArrow onClick={() => navigate(-1)} />
      <Typography variant="h4">
        {/* {state && state.name
          ? i18n.eventDetail.editTitle + `- ${state.name}`
          : i18n.eventDetail.newTitle} */}
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="EVENTI" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GreyCustomLabel
          firstLabel={"Tipologia"}
          tooltipText={
            "Un evento chiuso prevede la partecipazione degli utenti tramite appuntamento. Un evento aperto prevede la partecipazione libera entro l'orario dell'evento."
          }
        />
      </TabPanel>
    </MainLayout>
  );
};

export { ManageEventDetail };
