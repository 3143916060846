import { createContext } from "react";
import { useStorage } from "hooks/index";
import PropTypes from "prop-types";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useStorage("poliform-user", null);

  const authShared = {
    user,
    setUser,
  };

  return (
    <AuthContext.Provider value={authShared}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AuthProvider;
