import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// use-http
import useFetch from "use-http";

// constants
import { apiAvailabilities } from "constants/api";

// react mui
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// date fns
import { format, isSameDay } from "date-fns";
import { updateAvailability } from "utils/Agenda";

const FreeSlotsUpdateDialog = ({
  onClose,
  open,
  originalAvailability,
  newStartHour,
  newEndHour,
}) => {
  const { put, error: updateError } = useFetch(
    `${apiAvailabilities.API_BASE}/${originalAvailability?.id}`
  );

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sameDay, setSameDay] = useState();

  const intl = useIntl();
  const i18n = translations.Agenda(intl).dialogs;

  const handleClose = () => {
    setSuccess(false);
    setLoading(false);
    setSameDay();
    onClose();
  };

  const onSubmit = () => {
    setLoading(true);
    put(
      updateAvailability(originalAvailability, newStartHour, newEndHour)
    ).then(() => {
      setLoading(false);
      setSuccess(true);
    });
  };

  useEffect(() => {
    setSameDay(isSameDay(originalAvailability?.start, newStartHour));
  }, [originalAvailability, newStartHour, newEndHour]);

  return (
    <>
      {open && (
        <Dialog onClose={handleClose} open={open || false}>
          {success && !updateError && (
            <DialogTitle>{i18n.success.title}</DialogTitle>
          )}
          {!success && <DialogTitle>{i18n.updateFreeSlot.title}</DialogTitle>}
          <DialogContent>
            {loading && <CircularProgress />}
            {updateError && <Typography color="error">{i18n.error}</Typography>}
            {originalAvailability?.booked && (
              <Typography color="error">{i18n.alreadyBookedError}</Typography>
            )}
            {!sameDay && (
              <Typography color="error">{i18n.sameDayError}</Typography>
            )}
            {success && !updateError && (
              <Typography>{i18n.availability.success}</Typography>
            )}
            {!loading && !success && (
              <>
                {sameDay &&
                  originalAvailability &&
                  !originalAvailability?.booked && (
                    <>
                      <Typography>
                        {format(originalAvailability.start, "yyyy-MM-dd")}
                      </Typography>
                      <Typography>
                        {i18n.updateFreeSlot.originalDescription}
                      </Typography>
                      <Typography>
                        {format(originalAvailability.start, "HH:mm")} /{" "}
                        {format(originalAvailability.end, "HH:mm")}
                      </Typography>

                      <Typography>
                        {i18n.updateFreeSlot.newDescription}
                      </Typography>
                      <Typography>
                        {format(newStartHour, "HH:mm")} /{" "}
                        {format(newEndHour, "HH:mm")}
                      </Typography>
                    </>
                  )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            {!loading && (
              <>
                <Button onClick={handleClose}>{i18n.cancel}</Button>
                {sameDay &&
                  !updateError &&
                  !originalAvailability?.booked &&
                  !success && <Button onClick={onSubmit}>{i18n.submit}</Button>}
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
FreeSlotsUpdateDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  originalAvailability: PropTypes.object,
  newStartHour: PropTypes.object,
  newEndHour: PropTypes.object,
};

export { FreeSlotsUpdateDialog };
