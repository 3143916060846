export default {
  RegistryLists: intl => ({
    header: {
      email: intl.formatMessage({
        description: "Table email header column lable",
        defaultMessage: "Email",
      }),
      name: intl.formatMessage({
        description: "Table name header column lable",
        defaultMessage: "Nome",
      }),
      lastname: intl.formatMessage({
        description: "Table lastname header column lable",
        defaultMessage: "Cognome",
      }),
      mobilePhone: intl.formatMessage({
        description: "Table mobilePhone header column lable",
        defaultMessage: "Cellulare",
      }),
      role: intl.formatMessage({
        description: "Table role header column lable",
        defaultMessage: "Ruolo",
      }),
      numberCustomer: intl.formatMessage({
        description: "Table numberCustomer header column lable",
        defaultMessage: "N. Clienti",
      }),
      numberStaff: intl.formatMessage({
        description: "Table numberStaff header column lable",
        defaultMessage: "N. Staff",
      }),
      state: intl.formatMessage({
        description: "Table state header column lable",
        defaultMessage: "Stato",
      }),
      code: intl.formatMessage({
        description: "Table code header column lable",
        defaultMessage: "Codice",
      }),
      companyName: intl.formatMessage({
        description: "Table companyName header column lable",
        defaultMessage: "Ragione Sociale",
      }),
      company: intl.formatMessage({
        description: "Table company header column lable",
        defaultMessage: "Azienda",
      }),
      sector: intl.formatMessage({
        description: "Table sector header column lable",
        defaultMessage: "Settore",
      }),
      country: intl.formatMessage({
        description: "Table country header column lable",
        defaultMessage: "Nazione",
      }),
      city: intl.formatMessage({
        description: "Table city header column lable",
        defaultMessage: "Città",
      }),
      qualification: intl.formatMessage({
        description: "Table numberStaff header column lable",
        defaultMessage: "Qualifica",
      }),
      rating: intl.formatMessage({
        description: "Table rating header column lable",
        defaultMessage: "Potenzialità",
      }),
      agent: intl.formatMessage({
        description: "Table rating header column lable",
        defaultMessage: "Agente",
      }),
      category: intl.formatMessage({
        description: "Table category header column lable",
        defaultMessage: "Categoria",
      }),
    },
    footer: {
      pageSize: intl.formatMessage({
        description: "Footer table page size number",
        defaultMessage: "Visualizza elementi",
      }),
      pageFrom: intl.formatMessage({
        description: "Footer table page from label",
        defaultMessage: "Mostra da ",
      }),
      pageTo: intl.formatMessage({
        description: "Footer table page to label",
        defaultMessage: "a",
      }),
      pageOf: intl.formatMessage({
        description: "Footer table page of label",
        defaultMessage: "di",
      }),
      pageElement: intl.formatMessage({
        description: "Footer table page element label",
        defaultMessage: "elementi",
      }),
    },
    employees: {
      title: intl.formatMessage({
        description: "Dipendenti Poliform header title label",
        defaultMessage: "Dipendenti Poliform",
      }),
      createbtnText: intl.formatMessage({
        description: "Dipendenti Poliform header action button new text",
        defaultMessage: "CREA NUOVO",
      }),
    },
    businessPartner: {
      title: intl.formatMessage({
        description: "Business Partner header title label",
        defaultMessage: "Business Partner",
      }),
    },
    professionals: {
      title: intl.formatMessage({
        description: "Professionals header title label",
        defaultMessage: "Professionals",
      }),
    },
    press: {
      title: intl.formatMessage({
        description: "Press header title label",
        defaultMessage: "Press",
      }),
    },
    endUsers: {
      title: intl.formatMessage({
        description: "End Users header title label",
        defaultMessage: "End Users",
      }),
    },
    errors: {
      missingDataServer: intl.formatMessage({
        description: "General error for missing data from server fetch",
        defaultMessage:
          "Errore di comunicazione con il server, riprovare più tardi",
      }),
    },
  }),
  AgendaLists: intl => ({
    header: {
      email: intl.formatMessage({
        description: "AgendaLists Table email header column lable",
        defaultMessage: "Email",
      }),
      event: intl.formatMessage({
        description: "AgendaLists Table event header column lable",
        defaultMessage: "Evento",
      }),
      startDate: intl.formatMessage({
        description: "AgendaLists Table startDate header column lable",
        defaultMessage: "Data inizio",
      }),
      requestDate: intl.formatMessage({
        description: "AgendaLists Table requestDate header column lable",
        defaultMessage: "Data richiesta",
      }),
      agente: intl.formatMessage({
        description: "AgendaLists Table agente header column lable",
        defaultMessage: "Agente",
      }),
      nameLastname: intl.formatMessage({
        description: "AgendaLists Table nameLastname header column lable",
        defaultMessage: "Nominativo",
      }),
      endDate: intl.formatMessage({
        description: "AgendaLists Table endDate header column lable",
        defaultMessage: "Data fine",
      }),
      typologies: intl.formatMessage({
        description: "AgendaLists Table endDate header column lable",
        defaultMessage: "Tipologia",
      }),
      guest: intl.formatMessage({
        description: "AgendaLists Table endDate header column lable",
        defaultMessage: "Partecipanti",
      }),
      checkIn: intl.formatMessage({
        description: "AgendaLists Table endDate header column lable",
        defaultMessage: "Check In",
      }),
      name: intl.formatMessage({
        description: "AgendaLists Table name header column lable",
        defaultMessage: "Nome",
      }),
      lastname: intl.formatMessage({
        description: "AgendaLists Table lastname header column lable",
        defaultMessage: "Cognome",
      }),
      mobilePhone: intl.formatMessage({
        description: "AgendaLists Table mobilePhone header column lable",
        defaultMessage: "Cellulare",
      }),
      role: intl.formatMessage({
        description: "AgendaLists Table role header column lable",
        defaultMessage: "Ruolo",
      }),
      numberCustomer: intl.formatMessage({
        description: "AgendaLists Table numberCustomer header column lable",
        defaultMessage: "N. Clienti",
      }),
      numberStaff: intl.formatMessage({
        description: "AgendaLists Table numberStaff header column lable",
        defaultMessage: "N. Staff",
      }),
      state: intl.formatMessage({
        description: "AgendaLists Table state header column lable",
        defaultMessage: "Stato",
      }),
      code: intl.formatMessage({
        description: "AgendaLists Table code header column lable",
        defaultMessage: "Codice",
      }),
      companyName: intl.formatMessage({
        description: "AgendaLists Table companyName header column lable",
        defaultMessage: "Ragione Sociale",
      }),
      company: intl.formatMessage({
        description: "AgendaLists Table company header column lable",
        defaultMessage: "Azienda",
      }),
      sector: intl.formatMessage({
        description: "AgendaLists Table sector header column lable",
        defaultMessage: "Settore",
      }),
      country: intl.formatMessage({
        description: "AgendaLists Table country header column lable",
        defaultMessage: "Nazione",
      }),
      city: intl.formatMessage({
        description: "AgendaLists Table city header column lable",
        defaultMessage: "Città",
      }),
      qualification: intl.formatMessage({
        description: "AgendaLists Table numberStaff header column lable",
        defaultMessage: "Qualifica",
      }),
      rating: intl.formatMessage({
        description: "AgendaLists Table rating header column lable",
        defaultMessage: "Potenzialità",
      }),
      agent: intl.formatMessage({
        description: "AgendaLists Table rating header column lable",
        defaultMessage: "Agente",
      }),
      category: intl.formatMessage({
        description: "AgendaLists Table category header column lable",
        defaultMessage: "Categoria",
      }),
      invited: intl.formatMessage({
        description: "AgendaLists Table invited header column lable",
        defaultMessage: "Invitato",
      }),
    },
    footer: {
      pageSize: intl.formatMessage({
        description: "AgendaLists Footer table page size number",
        defaultMessage: "Visualizza elementi",
      }),
      pageFrom: intl.formatMessage({
        description: "AgendaLists Footer table page from label",
        defaultMessage: "Mostra da ",
      }),
      pageTo: intl.formatMessage({
        description: "AgendaLists Footer table page to label",
        defaultMessage: "a",
      }),
      pageOf: intl.formatMessage({
        description: "AgendaLists Footer table page of label",
        defaultMessage: "di",
      }),
      pageElement: intl.formatMessage({
        description: "AgendaLists Footer table page element label",
        defaultMessage: "elementi",
      }),
    },
    manageEvents: {
      title: intl.formatMessage({
        description: "AgendaLists Gestisci Eventi header title label",
        defaultMessage: "Gestisci Eventi",
      }),
      createbtnText: intl.formatMessage({
        description:
          "AgendaLists Gestisci Eventi create new buton header title",
        defaultMessage: "Nuovo evento",
      }),
    },
    manageParticipnts: {
      title: intl.formatMessage({
        description: "AgendaLists Gestisci Eventi header title label",
        defaultMessage: "Gestisci Partecipanti",
      }),
      addbtnText: intl.formatMessage({
        description:
          "AgendaLists Gestisci Eventi create new buton header title",
        defaultMessage: "Invita Partecipanti",
      }),
    },
    errors: {
      missingDataServer: intl.formatMessage({
        description:
          "AgendaLists General error for missing data from server fetch",
        defaultMessage:
          "Errore di comunicazione con il server, riprovare più tardi",
      }),
    },
  }),
};
