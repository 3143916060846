export default {
  FiltersList: intl => ({
    send: intl.formatMessage({
      description: "Filters send lable in lists pages",
      defaultMessage: "Cerca",
    }),
    reset: intl.formatMessage({
      description: "Filters reset lable in lists pages",
      defaultMessage: "Reset",
    }),
    roles: {
      fetchError: intl.formatMessage({
        description: "Filters reset lable in lists pages",
        defaultMessage: "Errore di comunicazione",
      }),
      label: intl.formatMessage({
        description: "Filters reset lable in lists pages",
        defaultMessage: "Ruolo",
      }),
    },
  }),
};
