import { useContext } from "react";
import PropTypes from "prop-types";

// context
import { AuthContext } from "context/AuthContext";

// react mui
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// constants
import { userGroups } from "constants/enums";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// use-http
import useFetch from "use-http";

// hooks
import { useProfessionalSchema } from "hooks";

// components
import { ErrorMessage } from "components";

// containers
import { ProfessionalControl } from "containers";

// constants
import { apiUser } from "constants/api";

const CreateProfessional = ({ open, setOpen }) => {
  const intl = useIntl();
  const i18nActions = translations.Actions(intl);
  const i18nProfessional = translations.templates.ProfileForm(intl);

  const { user } = useContext(AuthContext);

  const { professionalSchema } = useProfessionalSchema();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(professionalSchema) });

  const { post, loading, error, response } = useFetch(
    `${apiUser.API_BASE}${apiUser.CREATE}`
  );

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = data => {
    data.group = userGroups.PROFESSIONAL;
    data.creatorOwnerId = user.id;
    post(data);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {i18nActions.create} {i18nProfessional.page.selectType.professional}
      </DialogTitle>
      <DialogContent>
        {loading && <CircularProgress />}
        {error && <ErrorMessage />}
        {response.status === 409 && (
          <Typography color="error">
            {i18nProfessional.page.fetchError.userExists}
          </Typography>
        )}
        {!loading && response.ok && (
          <Typography>{i18nActions.operationSuccess}</Typography>
        )}
        {!response.ok && !loading && (
          <ProfessionalControl control={control} errors={errors} fullWidth />
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={handleClose}>{i18nActions.close}</Button>
          {!response.ok && !loading && (
            <Button onClick={handleSubmit(onSubmit)}>
              {i18nActions.create}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

CreateProfessional.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export { CreateProfessional };
