import { useState, useEffect } from "react";

// constants
import { apiAgenda } from "constants/api";

// use-http
import { useFetch } from "use-http";
import { useEvents } from "hooks";

const useAgendaDataListFetch = (
  pageIndex,
  pageSize,
  queryParameters,
  currentOrderId,
  currentOrder,
  queryUserParameters,
  setPageIndex,
  open
) => {
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [elementCount, setElementCount] = useState(0);
  const { loading, get, error } = useFetch(`${apiAgenda.API_BASE}`);

  const { parseEvents } = useEvents();

  useEffect(() => {
    setPageIndex(0);
    setPageCount(0);
    get(
      `?page=${pageIndex}&pageSize=${pageSize}${
        currentOrderId != "" ? `&sortBy=${currentOrderId}` : ``
      }${currentOrder != "" ? `&order=${currentOrder}` : ``}${queryParameters}${
        (queryUserParameters && "&" + queryUserParameters + "&") || ""
      }`
    ).then(res => {
      setElementCount(res.count);
      setRows(parseEvents(res.data));
      setPageCount(Math.ceil(res.count / pageSize));
    });
  }, [
    pageIndex,
    pageSize,
    queryParameters,
    currentOrderId,
    currentOrder,
    open,
  ]);

  return [error, loading, rows, pageCount, elementCount];
};

export { useAgendaDataListFetch };
