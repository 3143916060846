import PropTypes from "prop-types";

// react mui
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// constants
import { apiExhibitionSlots, apiUser } from "constants/api";

// hooks
import { useUserRoles } from "hooks";

// date-fns
import { format, sub } from "date-fns";
import { userGroups } from "constants/enums";
import { AutocompleteControlAPI } from "components/Autocomplete/Autocomplete";
import { createAvailability, parseAvailability } from "utils/Agenda";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { SCHEDULE_EVENT_APPOINTMENT_CREATE } from "constants/routes";

const CreateAvailabilityDialog = ({
  post,
  creationError,
  handleClose,
  loading,
  setLoading,
  success,
  setSuccess,
  error,
  setError,
  slot,
  mainSlot,
  event,
}) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).dialogs;
  const i18nAutocomplete = translations.components.Autocomplete(intl).agents;

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const { isLoggedUserInGroup } = useUserRoles();

  const schema = isLoggedUserInGroup(userGroups.ADMIN)
    ? yup
        .object({
          client: yup.object().required("Client required"),
        })
        .required()
    : yup.object({});

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // eslint-disable-next-line
  const onSubmitAvailability = data => {
    setLoading(true);
    post(
      `${apiExhibitionSlots.API_BASE}/${mainSlot.id}${apiExhibitionSlots.AVAILABILITY}`,
      createAvailability(user, data, slot)
    )
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };
  const onSubmitAppointment = data => {
    setLoading(true);

    post(
      `${apiExhibitionSlots.API_BASE}/${mainSlot.id}${apiExhibitionSlots.AVAILABILITY}`,
      createAvailability(user, data, slot)
    )
      .then(createdAvailability => {
        const parsedAvailability = parseAvailability(
          mainSlot,
          createdAvailability
        );

        navigate(SCHEDULE_EVENT_APPOINTMENT_CREATE, {
          state: { slot: parsedAvailability, event },
        });
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      {success && !creationError && (
        <DialogTitle>{i18n.success.title}</DialogTitle>
      )}
      {creationError && <DialogTitle>{i18n.error}</DialogTitle>}

      {mainSlot?.fromId && (
        <>
          {!success && !error && (
            <DialogTitle>{i18n.prenotation.title}</DialogTitle>
          )}
        </>
      )}
      {!mainSlot?.fromId && (
        <>
          {!success && !error && (
            <DialogTitle>{i18n.availability.title}</DialogTitle>
          )}
        </>
      )}
      <DialogContent>
        {loading && <CircularProgress />}
        {success && (
          <>
            {creationError && <Typography>{i18n.errorDescription}</Typography>}
            {mainSlot?.fromId && !creationError && (
              <Typography>{i18n.prenotation.success}</Typography>
            )}
            {!mainSlot?.fromId && !creationError && (
              <Typography>{i18n.availability.success}</Typography>
            )}
          </>
        )}
        {!success && !loading && <Typography mb={1}>{event.name}</Typography>}
        {mainSlot?.fromId && !loading && (
          <>
            <Typography mb={1}>{mainSlot?.fromDisplayName}</Typography>
            <Typography mb={1}>
              {format(mainSlot?.start, "dd/MM/yyyy")}
            </Typography>
            <Typography mb={1}>
              {format(mainSlot?.start, "HH:mm")} -{" "}
              {format(mainSlot?.end, "HH:mm")}
            </Typography>
          </>
        )}

        {!mainSlot?.fromId && slot && !loading && (
          <>
            <Typography mb={1}>{slot?.fromDisplayName}</Typography>
            <Typography mb={1}>
              {format(
                sub(mainSlot?.start, {
                  minutes: mainSlot?.start.getTimezoneOffset(),
                }),
                "dd/MM/yyyy"
              )}
            </Typography>
            <Typography mb={1}>
              {format(slot?.start, "HH:mm")} - {format(slot?.end, "HH:mm")}
            </Typography>
          </>
        )}
        {isLoggedUserInGroup(userGroups.AGENT) &&
          mainSlot?.fromId &&
          !loading &&
          !success && (
            <>
              <AutocompleteControlAPI
                control={control}
                name={"client"}
                label={"cliente"}
                errors={errors}
                errorObj={{
                  required: "obbligatorio",
                  typeError: "obbligatorio",
                }}
                endpoint={`${apiUser.API_BASE}${apiUser.AUTOCOMPLETE}`}
                queryParam={`&Groups=${userGroups.AGENT}&Groups=${userGroups.AGENTSTAFF},${userGroups.AREAMANAGER}&q`}
                noOptionsText={"nessun cliente trovato"}
                isRequired
              />
            </>
          )}
        {isLoggedUserInGroup(userGroups.ADMIN) && !loading && !success && (
          <>
            <AutocompleteControlAPI
              control={control}
              name={"client"}
              label={i18nAutocomplete.label}
              errors={errors}
              errorObj={{
                required: i18nAutocomplete.required,
                typeError: i18nAutocomplete.required,
              }}
              endpoint={`${apiUser.API_BASE}${apiUser.INTERNALS}`}
              // queryParam={`&Groups=[${userGroups.AGENT},${userGroups.AGENTSTAFF},${userGroups.AREAMANAGER}]&q`}
              queryParam={`&q`}
              noOptionsText={i18nAutocomplete.noOptions}
              isRequired
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!success && !loading && (
          <>
            <Button onClick={handleClose}>{i18n.cancel}</Button>
            {/* <Button onClick={handleSubmit(onSubmitAvailability)}>
            {i18n.createAvailability}
          </Button> */}
            <Button onClick={handleSubmit(onSubmitAppointment)}>
              {i18n.createAppointment}
            </Button>
          </>
        )}
        {success && <Button onClick={handleClose}>{i18n.submit}</Button>}
      </DialogActions>
    </>
  );
};
CreateAvailabilityDialog.propTypes = {
  post: PropTypes.func,
  handleClose: PropTypes.func,
  creationError: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  mainSlot: PropTypes.object,
  slot: PropTypes.object,
  event: PropTypes.object,
};
export default CreateAvailabilityDialog;
