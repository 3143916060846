/* eslint-disable react/prop-types */

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// mui
import { Dialog, Grid, Typography } from "@mui/material";

// components
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
// custom styles
import { Wrapper, Panel, DialogContentWrapper } from "./LegalNotes.styles";
import { BackArrow, PoliformLogo } from "components";

const LegalNotes = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.LegalNotes(intl).page;

  return (
    <Wrapper>
      <Panel>
        <Grid container spacing={2} direction="column" minWidth={"100%"}>
          <Grid item>
            <BackArrow />
          </Grid>
          <Grid item xs={12}>
            <PoliformLogo />
          </Grid>
          <Grid item xs={12}>
            <hr style={{ width: "100%" }} />
            <Typography my={2} variant={"h4"}>
              {i18n.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography my={2} variant={"body1"}>
              {i18n.description}
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </Wrapper>
  );
};

const LegalNotesPopup = ({ open, setOpen }) => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.LegalNotes(intl).page;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen open={open} keepMounted onClose={handleClose}>
      <DialogContentWrapper>
        <Wrapper>
          <Panel>
            <Grid container spacing={2} direction="column" minWidth={"100%"}>
              <Grid item>
                <LeftArrow className="svg-hover" onClick={handleClose} />
              </Grid>
              <Grid item xs={12}>
                <PoliformLogo />
              </Grid>
              <Grid item xs={12}>
                <hr style={{ width: "100%" }} />
                <Typography my={2} variant={"h4"}>
                  {i18n.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography my={2} variant={"body1"}>
                  {i18n.description}
                </Typography>
              </Grid>
            </Grid>
          </Panel>
        </Wrapper>
      </DialogContentWrapper>
    </Dialog>
  );
};

export { LegalNotesPopup, LegalNotes };
