import { Button, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/icons/icon_info_tooltip.svg";
import PropTypes from "prop-types";
import { GreyLabelContent, GreyLabelWrapper } from "./GreyCustomLabel.styles";

const GreyCustomLabel = ({
  firstLabel,
  fontWeightFirstLabel,
  secondLable,
  fontWeightSecondLabel,
  addLabel,
  handleAdd,
  tooltipText,
}) => {
  return (
    <GreyLabelWrapper>
      <GreyLabelContent>
        <Typography
          variant="body1"
          display={"inline"}
          marginRight={5}
          sx={{ fontWeight: fontWeightFirstLabel }}
        >
          {firstLabel}
          {tooltipText && (
            <>
              {/* TODO: add to styles file*/}
              <Tooltip title={tooltipText} placement="top-end">
                <IconButton
                  style={{
                    padding: "1px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Typography>

        <Typography
          variant="body1"
          display={"inline"}
          sx={{ fontWeight: fontWeightSecondLabel }}
        >
          {secondLable}
        </Typography>
      </GreyLabelContent>
      <Link style={{ marginRight: 10 }} onClick={() => handleAdd()}>
        <Button variant="body1" display={"inline"}>
          {addLabel}
        </Button>
      </Link>
    </GreyLabelWrapper>
  );
};

GreyCustomLabel.propTypes = {
  firstLabel: PropTypes.string.isRequired,
  secondLable: PropTypes.string,
  addLabel: PropTypes.string,
  handleAdd: PropTypes.func,
  fontWeightFirstLabel: PropTypes.string,
  fontWeightSecondLabel: PropTypes.string,
  tooltipText: PropTypes.string,
};

GreyCustomLabel.defaultProps = {
  fontWeightFirstLabel: "bold",
  fontWeightSecondLabel: "bold",
  tooltipText: null,
};

export { GreyCustomLabel };
