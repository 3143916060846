// react-mui
import { Typography } from "@mui/material";

// components
import { MainLayout } from "components/index";

const NotFound = () => {
  return (
    <MainLayout>
      <Typography variant="h2">404</Typography>
      <Typography variant="body2">Pagina non trovata</Typography>
    </MainLayout>
  );
};
export { NotFound };
