// {
//   "name": "event_name_2",
//   "description": "event_description_2",
//   "typology": "OPEN",
//   "address":{
//       "location": "Mediolanum Forum",
//       "street": "street_event",
//       "geoCountryId":110,
//       "geoRegionId": 9,
//       "geoProvinceId": 17,
//       "geoCityId": 3360,
//       "geoZipcodeId": 9028
//   },
//   "slots":[
//       {
//           "startDate": "2022-04-13",
//           "endDate": "2022-04-14",
//           "startTime": "16:00:00",
//           "endTime": "10:00:00"
//       }
//   ],
//   "visibility": "PUBLIC",
//   "slotTypology": "FIXED",
//   "slotDuration": 30
// }

import { slotTypology } from "constants/enums";
import { format } from "date-fns";

export const createEventDTO = data => {
  console.log("C.LOG ~ file: manageEvents.js ~ line 30 ~ data", data);
  let tmpObj = {};
  tmpObj.name = data.name;
  tmpObj.description = data.description;
  tmpObj.typology = data.typology;
  tmpObj.checkin = data.checkin;
  // TODO remove hardcoded PUBLIC
  tmpObj.visibility = "PUBLIC";
  tmpObj.slotTypology = data.slotTypology;
  tmpObj.slotDuration =
    data.slotTypology === slotTypology.FREE ? null : data.slotDuration || null;
  tmpObj.address = {
    location: data.location,
    street: data.street,
    geoCountryId: data.nation.id,
    geoRegionId: null,
    geoProvinceId: data.province?.id || null,
    geoCityId: data.city?.id || null,
    foreignCity: data.foreignCity || null,
    foreignZipCode: data.foreignPostalCode || null,
  };

  const remappedSlots = data.slots?.map(el => {
    // new Date("2022-04-30T16:30:00").toISOString()
    const startDateTimeISO = new Date(
      `${format(new Date(el.startDate), "yyyy-MM-dd")}T${format(
        new Date(el.startTime),
        "HH:mm:ss"
      )}`
    ).toISOString();
    const endDateTimeISO = new Date(
      `${format(new Date(el.endDate), "yyyy-MM-dd")}T${format(
        new Date(el.endTime),
        "HH:mm:ss"
      )}`
    ).toISOString();

    const startDateISO = startDateTimeISO.split("T")[0];
    const startTimeISO = startDateTimeISO.split("T")[1].slice(0, -5);
    const endDateISO = endDateTimeISO.split("T")[0];
    const endTimeISO = endDateTimeISO.split("T")[1].slice(0, -5);
    el.startDate = startDateISO;
    el.endDate = endDateISO;
    el.startTime = startTimeISO;
    el.endTime = endTimeISO;
    // const tmpStart = el.startTime.split(":");
    // el.startTime = format(
    //   new Date(1972, 0, 1, tmpStart[0], tmpStart[1], 0),
    //   "HH:mm:ss"
    // );
    // const tmpEnd = el.endTime.split(":");
    // el.endTime = format(
    //   new Date(1972, 0, 1, tmpEnd[0], tmpEnd[1], 0),
    //   "HH:mm:ss"
    // );
    return el;
  });
  tmpObj.slotRequests = remappedSlots;

  return tmpObj;
};
