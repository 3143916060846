import { styled } from "@mui/system";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const SidebarWrapper = styled("div")(({ theme }) => ({
  overflow: "auto",
  flex: 1,
  maxWidth: " 250px",
  background: theme.palette.secondary.main,
}));
export const ContentWrapper = styled("div")({
  flex: 1,
  overflow: "hidden",
});

export const TopbarWrapper = styled("div")(({ theme }) => ({
  height: "50px",
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  padding: "10px 50px",
  display: "flex",
  position: "sticky",
  justifyContent: "space-between",
  alignItems: "center",
  top: 0,
}));

export const ChildrenWrapper = styled("div")({
  overflow: "auto",
  padding: "30px 50px",
  height: "calc(100% - 130px)",
  position: "relative",

  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const UserContainer = styled("div")({
  display: "flex",
});
export const UserInfoContainer = styled("div")({
  alignSelf: "flex-end",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  marginRight: "20px",
});

export const LogoutIconContainer = styled("div")(({ theme }) => ({
  padding: "10px",
  background: theme.palette.secondary.main,
  borderRadius: "50%",
  display: "grid",
  placeContent: "center",
  "&:hover": {
    cursor: "pointer",
  },
}));
