export const deepFind = (f, obj = {}) => {
  if (Object(obj) === obj) {
    if (f(obj) === true) return obj;
    // eslint-disable-next-line
    for (const [k, v] of Object.entries(obj)) {
      const res = deepFind(f, v);

      if (res !== undefined) return res;
    }
  }

  return undefined;
};

export const createPermissionMenu = (menuDTO, templateMenu) => {
  let tmpMenu = {};
  Object.values(templateMenu).map(el => {
    let tmpEntryMenu = {};
    if (menuDTO.some(x => x.identifier === el.identifier)) {
      tmpEntryMenu.icon = el.icon;
      tmpEntryMenu.label = el.label;
      tmpEntryMenu.path = el.path;
      tmpEntryMenu.identifier = el.identifier;
      tmpEntryMenu.submenu = [];

      if (el.submenu.length >= 1) {
        el.submenu.map(subel => {
          if (menuDTO.some(y => y.identifier === subel.identifier)) {
            const tmpSubEl = {
              label: subel.label,
              path: subel.path,
              identifier: subel.identifier,
            };
            tmpEntryMenu.submenu.push(tmpSubEl);
          }
        });
      }
      tmpMenu[el.identifier] = { ...tmpEntryMenu };
    }
  });
  return tmpMenu;
};
