import { useIntl } from "react-intl";
import { Navigate, useLocation } from "react-router-dom";

import { ROOT } from "constants/routes";

// components
import { GreyCustomLabel, MainLayout } from "components/index";

// containers
import { FilteredList, ParticipantActionDialog } from "containers";

// utils
import translations from "utils/translations";

import useManageParticipantsFilters from "./ManageParticipantsList.filters";

import useHeader from "./ManageParticipantsList.header";

import { useParticipantsDataListFetch } from "hooks";
import { useState } from "react";

const ManageParticipantsList = () => {
  // dialog state
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState();
  const [appointment, setAppointment] = useState();

  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);
  const { state: mainEvent } = useLocation();
  const [header] = useHeader({ setAction, setOpen, setAppointment, mainEvent });

  const filtersList = useManageParticipantsFilters();

  // eslint-disable-next-line
  const [requestCount, setRequestCount] = useState(0); //TODO: add fetch for get info
  // eslint-disable-next-line
  const [participantsCount, setParticipantsCount] = useState(0);

  if (!mainEvent) return <Navigate to={ROOT} />;

  // eslint-disable-next-line
  const addParticipants = () => {
    console.log("add"); //TODO: add modal
  };

  const renderHeaderAction = () => {
    return (
      <div style={{ marginTop: 10 }}>
        <GreyCustomLabel
          firstLabel={"Totale Richieste: " + requestCount}
          secondLable={"Totale partecipanti: " + participantsCount}
          // addLabel="INVITA PARTECIPANTI"
          // handleAdd={addParticipants}
        />
      </div>
    );
  };

  return (
    <MainLayout>
      <FilteredList
        backArrow
        useCustomFetch={useParticipantsDataListFetch}
        i18n={i18n.manageParticipnts}
        titleAddictions={` - ${(mainEvent && mainEvent.name) || ""}`}
        headerAction={renderHeaderAction()}
        i18nErrors={i18n.errors}
        header={header}
        filtersList={filtersList}
        queryUserTypeParams={`ExhibitionId=${mainEvent.id}&`}
      />
      <ParticipantActionDialog
        open={open}
        action={action}
        setOpen={setOpen}
        appointment={appointment}
        mainEvent={mainEvent}
      />
    </MainLayout>
  );
};

export { ManageParticipantsList };
