import { useContext, useEffect, useState } from "react";

// import PropTypes from "prop-types";

// react router dom
import { matchPath, useLocation, useNavigate } from "react-router-dom";

// hooks
import { useIntl } from "react-intl";

// Context
import { MainContext } from "context/MainContext";

// mui
import { Typography } from "@mui/material";

// styled-components
import {
  SidebarMenuItem,
  SidebarWrapper,
  SidebarHeader,
  SidebarSubMenuWrapper,
  SidebarSubMenuItem,
  IconContainer,
  ResponsiveCloseSidebar,
  MainLink,
  SubLink,
} from "./Sidebar.styles";

// assets
import { LinearProgress, PoliformLogo } from "components/index";
import { isMobile } from "react-device-detect";

const Sidebar = () => {
  // i18n
  const intl = useIntl();

  //context
  const { openSidebar, setOpenSidebar, permissionMenu, loadingMenu } =
    useContext(MainContext);

  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [submenuId, setSubmenuId] = useState("");

  const handleClickSubmenu = id => {
    setSubmenuId(id);
    if (id == submenuId) {
      setSubmenuId("");
    }
  };

  useEffect(() => {}, [location.pathname]);

  return (
    <SidebarWrapper
      openmenu={openSidebar}
      onClick={() => (isMobile ? setOpenSidebar(false) : null)}
    >
      <SidebarHeader openmenu={openSidebar}>
        {openSidebar && (
          <>
            <PoliformLogo />
            <ResponsiveCloseSidebar
              onClick={() => setOpenSidebar(false)}
              style={{ width: "20px" }}
              src="/images/icons/cancel.svg"
            />
          </>
        )}
      </SidebarHeader>
      {loadingMenu && <LinearProgress style={{ top: "70px" }} />}
      {!loadingMenu &&
        Object.values(permissionMenu).map((menuItem, idx) => (
          <SidebarMenuItem
            openmenu={openSidebar}
            key={idx}
            onClick={() => {
              handleClickSubmenu(menuItem.identifier);
            }}
            className={
              matchPath(location.pathname.split("/")[1], menuItem.path)
                ? "active"
                : ""
            }
          >
            {openSidebar && (
              <>
                <Typography color={"primary"} variant="body1">
                  <MainLink
                    className={
                      matchPath(location.pathname.split("/")[1], menuItem.path)
                        ? "active"
                        : ""
                    }
                    to={menuItem.path}
                  >
                    {menuItem.label(intl)}
                  </MainLink>
                  {menuItem.submenu && menuItem.submenu.length >= 1 && (
                    <></>
                    // <DownArrow
                    //   style={{
                    //     position: "absolute",
                    //     right: "10px",
                    //     transition: ".5s all ease",
                    //     zIndex: 12,
                    //     width: 10,
                    //     transform:
                    //       submenuId == menuItem.identifier
                    //         ? "rotate(180deg)"
                    //         : "rotate(0deg)",
                    //   }}
                    // />
                  )}
                </Typography>
                {menuItem.submenu && menuItem.submenu.length >= 1 && (
                  <SidebarSubMenuWrapper
                    isactive={matchPath(
                      location.pathname.split("/")[1],
                      menuItem.path
                    )}
                    opensidebar={openSidebar}
                    opensubmenu={
                      submenuId == menuItem.identifier ||
                      matchPath(location.pathname.split("/")[1], menuItem.path)
                    }
                  >
                    {menuItem.submenu.map((submenuItem, subIdx) => (
                      <SidebarSubMenuItem key={subIdx}>
                        <Typography
                          variant="body1"
                          fontWeight={
                            matchPath(location.pathname, submenuItem.path)
                              ? "bold"
                              : "regular"
                          }
                        >
                          <SubLink
                            className={
                              matchPath(
                                location.pathname.split("/")[1],
                                menuItem.path
                              )
                                ? "active"
                                : ""
                            }
                            to={submenuItem.path}
                          >
                            {submenuItem.label(intl)}
                          </SubLink>
                        </Typography>
                      </SidebarSubMenuItem>
                    ))}
                  </SidebarSubMenuWrapper>
                )}
              </>
            )}
            {!openSidebar && (
              <>
                <IconContainer
                  onClick={() => {
                    if (!menuItem.submenu.length >= 1) {
                      navigate(menuItem.path);
                    }
                  }}
                  src={menuItem.icon}
                />
                {menuItem.submenu && menuItem.submenu.length >= 1 && (
                  <SidebarSubMenuWrapper
                    opensidebar={openSidebar}
                    opensubmenu={submenuId == menuItem.identifier}
                  >
                    <Typography color={"primary"} variant="body1">
                      <MainLink
                        className={
                          matchPath(
                            location.pathname.split("/")[1],
                            menuItem.path
                          )
                            ? "active"
                            : ""
                        }
                        to={menuItem.path}
                      >
                        {menuItem.label(intl)}
                      </MainLink>
                    </Typography>
                    {menuItem.submenu.map((submenuItem, subIdx) => (
                      <SidebarSubMenuItem key={subIdx}>
                        <Typography variant="body1">
                          <SubLink
                            className={
                              matchPath(
                                location.pathname.split("/")[1],
                                menuItem.path
                              )
                                ? "active"
                                : ""
                            }
                            to={submenuItem.path}
                          >
                            {submenuItem.label(intl)}
                          </SubLink>
                        </Typography>
                      </SidebarSubMenuItem>
                    ))}
                  </SidebarSubMenuWrapper>
                )}
              </>
            )}
          </SidebarMenuItem>
        ))}
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  // openSidebar: PropTypes.bool,
  // setOpenSidebar: PropTypes.func,
};
export { Sidebar };
