// TODO refactor
import { useEffect, useState } from "react";
// react-mui
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
} from "@mui/material";

// react-hook-form
import { useForm } from "react-hook-form";

// use-http
import useFetch from "use-http";

// constants
import { apiPolicies, apiRoles } from "constants/api";

// utils
import { createPolicyDTO, updatePolicyDTO } from "utils/users";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { LinearProgress, MainLayout, SelectControl } from "components/index";
import { ReactComponent as DownArrow } from "assets/icons/arrow-down.svg";

const PermissionsPage = () => {
  // state
  const [role, setRole] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  // Hooks
  const intl = useIntl();
  const i18n = translations.MyArea.Registry.PermissionsPage(intl).page;

  const {
    control,
    formState: { errors },
    watch,
  } = useForm();

  // watch
  const roles = watch("role");

  //  use http
  const {
    data: rolesData,
    loading: loadingRoles,
    error: errorRoles,
  } = useFetch(apiRoles.API_BASE, []);

  const {
    get,
    put,
    post,
    loading: loadingPolicies,
    error: errorPolicies,
    response: policiesResponse,
  } = useFetch(`${apiPolicies.API_BASE}`);

  useEffect(() => {
    setRole(roles);
    // TODO remove the hardcoded 9
    get("?roleId=9").then(newPolicies => {
      setPolicyData(newPolicies);
    });
  }, [roles]);

  const handleSettingChange = row => {
    // if the row.id exists, that means that the role that is being modded has
    // the policy by default, otherwise we have to create it, given
    // that the account making the mods has the permission
    if (row.id) {
      // TODO remove the hardcoded 9
      put(`/${row.id}`, updatePolicyDTO(row)).then(() => {
        get("?roleId=9").then(newPolicies => {
          setPolicyData(newPolicies);
        });
      });
    } else {
      // TODO remove the hardcoded 9
      post("?roleId=9", createPolicyDTO(row)).then(() => {
        get("?roleId=9").then(newPolicies => {
          setPolicyData(newPolicies);
        });
      });
    }
  };

  return (
    <MainLayout>
      <Typography variant="h6">{i18n.title}</Typography>
      <Grid container>
        <Grid item xs={12} md={3}>
          {errorRoles ||
            (errorPolicies && (
              <Typography color="error">{i18n.fetchError}</Typography>
            ))}
          {rolesData?.data && (
            <SelectControl
              control={control}
              name={"role"}
              placeholder={"Ruolo"}
              label={"Ruolo"}
              errors={errors}
              errorObj={{ required: "Inserire un ruolo" }}
              options={rolesData.data}
              value={role}
              defaultValue={role}
              fullWidth
            />
          )}
        </Grid>
      </Grid>
      {loadingRoles || (loadingPolicies && <LinearProgress size={25} />)}
      {policiesResponse.ok &&
        !loadingRoles &&
        policyData?.map(policy => (
          <Accordion key={policy.name}>
            <AccordionSummary expandIcon={<DownArrow />}>
              <Typography variant="h6">{policy.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={300}>
                        {/* TODO add translations */}
                        <Typography>Azione</Typography>
                      </TableCell>
                      <TableCell>
                        {/* TODO add translations */}
                        <Typography>Leggi</Typography>
                      </TableCell>
                      <TableCell>
                        {/* TODO add translations */}
                        <Typography>Crea</Typography>
                      </TableCell>
                      <TableCell>
                        {/* TODO add translations */}
                        <Typography>Modifica</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Cancella</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {policy.policies.map((row, idx) => (
                      <TableRow
                        key={`${row.name}${idx}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell width={300} component="th" scope="row">
                          <Typography>{row.name}</Typography>
                        </TableCell>
                        <TableCell>
                          {/* {row.read?.editable !== undefined && ( */}
                          <Checkbox
                            // control={control}
                            // name={`${row.name}-read`}
                            errors={errors}
                            disabled={!row.read?.editable}
                            defaultChecked={row.read?.state}
                            onChange={(e, v) => {
                              if (row.read.editable) {
                                handleSettingChange({
                                  ...row,
                                  read: {
                                    editable: row.read.editable,
                                    state: v,
                                  },
                                });
                              }
                            }}
                          />
                          {/* )} */}
                        </TableCell>
                        <TableCell>
                          {/* {row.create?.editable !== undefined && ( */}
                          <Checkbox
                            // control={control}
                            // name={`${row.name}-create`}
                            // errors={errors}
                            defaultChecked={row.create?.state}
                            disabled={!row.create?.editable}
                            onChange={(e, v) => {
                              if (row.create.editable) {
                                handleSettingChange({
                                  ...row,
                                  create: {
                                    editable: row.create.editable,
                                    state: v,
                                  },
                                });
                              }
                            }}
                          />
                          {/* )} */}
                        </TableCell>
                        <TableCell>
                          {/* {row.update?.editable !== undefined && ( */}
                          <Checkbox
                            // control={control}
                            // name={`${row.name}-update`}
                            // errors={errors}
                            defaultChecked={row.update?.state}
                            disabled={!row.update?.editable}
                            onChange={(e, v) => {
                              if (row.update.editable) {
                                handleSettingChange({
                                  ...row,
                                  update: {
                                    editable: row.update.editable,
                                    state: v,
                                  },
                                });
                              }
                            }}
                          />
                          {/* )} */}
                        </TableCell>
                        <TableCell>
                          {/* {row.delete?.editable !== undefined && ( */}
                          <Checkbox
                            // control={control}
                            // name={`${row.name}-delete`}
                            // errors={errors}
                            defaultChecked={row.delete?.state}
                            disabled={!row.delete?.editable}
                            onChange={(e, v) => {
                              if (row.delete.editable) {
                                handleSettingChange({
                                  ...row,
                                  delete: {
                                    editable: row.delete.editable,
                                    state: v,
                                  },
                                });
                              }
                            }}
                          />
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
    </MainLayout>
  );
};
export { PermissionsPage };
