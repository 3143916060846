export const langs = {
  IT: "it",
  EN: "en",
  US: "en-US",
  CN: "cn",
  DE: "de",
  FR: "fr",
  ES: "es",
  RU: "ru",
};
