import { Typography } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

const ErrorMessage = () => {
  const intl = useIntl();
  const i18nActions = translations.Actions(intl);
  return <Typography color={"error"}>{i18nActions.operationError}</Typography>;
};
export { ErrorMessage };
