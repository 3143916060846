/* eslint react/prop-types: 0 */
/* eslint-disable */
import { useMemo } from "react";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// import PropTypes from "prop-types";

// components
import { EventDayItem, EventItem } from "components";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import { ReactComponent as RightArrow } from "assets/icons/arrow-right.svg";

function RBCToolbar({ label, date, view, views, onView, onNavigate }) {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);

  const onChange = event => {
    const current = event.target.value;
    onNavigate("DATE", new Date(current));
  };

  const goToView = view => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        {/* <button onClick={goToToday}>{i18n.calendar.today}</button> */}
        <button onClick={goToBack}>
          <LeftArrow />
        </button>
        <button onClick={goToNext}>
          <RightArrow />
        </button>
      </div>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        {views?.map(item => (
          <button
            onClick={() => goToView(item)}
            key={item}
            className={view === item ? "rbc-active" : ""}
          >
            {i18n.calendar[item]}
          </button>
        ))}
      </div>
    </div>
  );
}

const useCalendar = () => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);

  const calendarMessages = {
    date: i18n.calendar.date,
    time: i18n.calendar.time,
    event: i18n.calendar.event,
    allDay: i18n.calendar.allDay,
    week: i18n.calendar.week,
    work_week: i18n.calendar.workWeek,
    day: i18n.calendar.day,
    month: i18n.calendar.month,
    previous: <LeftArrow />,
    next: <RightArrow />,
    yesterday: i18n.calendar.yesterday,
    tomorrow: i18n.calendar.tomorrow,
    // today: i18n.calendar.today,
    // today: <div className="display_none"></div>,
    agenda: i18n.calendar.agenda,

    noEventsInRange: i18n.calendar.noEventsInRange,

    showMore: total => `+${total} eventi`,
  };

  // Main Agenda components
  const components = useMemo(
    () => ({
      event: ({ event }) => <EventItem event={event} />,
      toolbar: RBCToolbar,
      day: {
        event: ({ event }) => <EventDayItem event={event} />,
      },
    }),
    []
  );

  return [calendarMessages, components];
};

export { useCalendar };
