import { useEffect, useMemo, useState } from "react";
// react  router dom
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  BlackWhiteRoundedButton,
  DatePickerControl,
  GreyCustomLabel,
  LinearProgress,
  LoadingDialog,
  MainLayout,
  MainParticipantControl,
  TextFieldControl,
  TimePickerControl,
} from "components";
import { Button, Grid, Typography } from "@mui/material";

// containers
import { ParticipantListControl } from "containers";

// constants
import { apiAppointments, apiAvailabilities } from "constants/api";
import { formControlNames } from "constants/enums";

//react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// use-http
import useFetch from "use-http";

// date fns
import { SCHEDULE, SCHEDULE_EVENT_PRENOTATION } from "constants/routes";

// utils
import { parseAppointment, updateAppointment } from "utils/Agenda";

// hooks
import { useAgendaSchema } from "hooks";

const EventUpdateAppointment = () => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);

  // schema
  const { appointmentSchema } = useAgendaSchema();

  const { state } = useLocation();
  if (!state) {
    return <Navigate to={SCHEDULE} />;
  }

  let navigate = useNavigate();

  const { slot: appointmentSlot, event: appointmentEvent } = state;

  // eslint-disable-next-line
  const { post, loading: creatingAppointment } = useFetch(
    `${apiAvailabilities.API_BASE}/${appointmentSlot?.id}${apiAvailabilities.APPOINTMENT}`
  );
  const {
    data: appointmentDetail,
    loading: loadingAppointmentDetail,
    put: putAppointment,
  } = useFetch(`${apiAppointments.API_BASE}/${appointmentSlot?.id}`, []);

  const { put: putAvailability, loading: loadingPutAvailability } = useFetch(
    `${apiAvailabilities.API_BASE}/${appointmentSlot?.availabilityId}`
  );

  const [appointmentDetailData, setAppointmentDetailData] = useState();

  const {
    control,
    formState: { errors, dirtyFields, isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return appointmentDetailData
        ? parseAppointment(appointmentDetailData)
        : {};
    }, [appointmentDetailData]),
    resolver: yupResolver(appointmentSchema),
  });

  const onSubmit = data => {
    let updatedAppointment = updateAppointment(data, appointmentSlot);
    if (!isDirty) {
      return navigate(SCHEDULE_EVENT_PRENOTATION, {
        state: { ...appointmentEvent },
      });
    }
    if (dirtyFields.note && !dirtyFields.startTime && !dirtyFields.endTime) {
      putAppointment(apiAppointments.NOTE, updatedAppointment).then(() => {
        navigate(SCHEDULE_EVENT_PRENOTATION, {
          state: { ...appointmentEvent },
        });
      });
    } else {
      putAppointment(updatedAppointment).then(() => {
        putAvailability({
          id: updatedAppointment.availabilityId,
          startHour: updatedAppointment.startHour,
          startMinutes: updatedAppointment.startMinutes,
          endHour: updatedAppointment.endHour,
          endMinutes: updatedAppointment.endMinutes,
        }).then(() => {
          navigate(SCHEDULE_EVENT_PRENOTATION, {
            state: { ...appointmentEvent },
          });
        });
      });
    }
  };

  useEffect(() => {
    if (appointmentDetail) {
      setAppointmentDetailData(parseAppointment(appointmentDetail));
      reset(parseAppointment(appointmentDetail));
    }
  }, [appointmentDetail]);

  return (
    <MainLayout>
      <Typography variant={"h4"}>
        {i18n.eventDetailAppointment.update.title} - {appointmentEvent.name}
      </Typography>
      {(loadingAppointmentDetail || loadingPutAvailability) && (
        <LinearProgress />
      )}
      {!loadingAppointmentDetail &&
        !loadingPutAvailability &&
        appointmentDetailData && (
          <>
            <GreyCustomLabel
              firstLabel={i18n.eventCreateAppointment.greyLabel}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container mt={2} spacing={2}>
                <Grid item xs={12} md={3}>
                  <DatePickerControl
                    control={control}
                    errors={errors}
                    name={"startDate"}
                    label={i18n.createEvent.form.date.startDate}
                    minDate={appointmentEvent.start}
                    maxDate={watch("endDate")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerControl
                    control={control}
                    errors={errors}
                    name={"startTime"}
                    label={i18n.createEvent.form.date.startTime}
                    minutesStep={appointmentEvent.slotDuration || 30}
                    minTime={appointmentEvent.start}
                    maxTime={watch("endTime")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DatePickerControl
                    control={control}
                    errors={errors}
                    name={"endDate"}
                    label={i18n.createEvent.form.date.endDate}
                    minDate={watch("endDate")}
                    maxDate={appointmentEvent.end}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerControl
                    control={control}
                    errors={errors}
                    name={"endTime"}
                    label={i18n.createEvent.form.date.endTime}
                    minutesStep={appointmentEvent.slotDuration || 30}
                    minTime={watch("startTime")}
                    maxTime={appointmentEvent.end}
                  />
                </Grid>
              </Grid>
              <GreyCustomLabel
                firstLabel={i18n.eventDetailAppointment.partecipants}
              />
              <MainParticipantControl
                participant={appointmentDetailData.participant}
                control={control}
                errors={errors}
                disabled
                marginTop
              />
              <ParticipantListControl
                control={control}
                errors={errors}
                disabled
              />

              <GreyCustomLabel firstLabel={i18n.confirmParticipation.note} />
              <Grid spacing={2} mt={2} item xs={12} container>
                <Grid item xs={12} md={6}>
                  <TextFieldControl
                    control={control}
                    errors={errors}
                    name={formControlNames.agenda.note}
                    label={"note"}
                    placeholder={"note"}
                    multiline
                    minRows={4}
                  />
                </Grid>
              </Grid>

              <Grid
                mt={2}
                item
                xs={12}
                container
                style={{ alignItems: "center" }}
              >
                <Grid item>
                  <BlackWhiteRoundedButton
                    type="submit"
                    label={i18nActions.save}
                  />
                </Grid>
                <Grid item>
                  <Link to={-1}>
                    <Button>{i18nActions.cancel}</Button>
                  </Link>
                </Grid>
              </Grid>
            </form>
            <LoadingDialog open={creatingAppointment} />
          </>
        )}
    </MainLayout>
  );
};

export { EventUpdateAppointment };
