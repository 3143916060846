export default {
  CreatePassword: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Create Password page title",
        defaultMessage: "Crea nuova password",
      }),
      description: intl.formatMessage({
        description: "Create Password page title",
        defaultMessage: "Scegli una nuova password per accedere",
      }),
      form: {
        password: {
          errors: {
            required: intl.formatMessage({
              description: "Create Password page form password error required",
              defaultMessage: "la password è obbligatoria",
            }),
            matches: intl.formatMessage({
              description: "Create Password page form password error matches",
              defaultMessage:
                "La password deve contenere almeno 8 caratteri di cui almeno: una lettera maiuscola, una minuscola e un numero ",
            }),
          },
          label: intl.formatMessage({
            description: "Create Password page form password label",
            defaultMessage: "password",
          }),
        },
        confirmPassword: {
          errors: {
            required: intl.formatMessage({
              description:
                "Create Password page form password confirmation error required",
              defaultMessage: "la conferma della passoword è obbligatoria",
            }),
            oneOf: intl.formatMessage({
              description:
                "Create Password page form password confirmation error oneof",
              defaultMessage: "le password non coincidono",
            }),
          },
          label: intl.formatMessage({
            description:
              "Create Password page form password confirmation label",
            defaultMessage: "conferma password",
          }),
        },
        submit: intl.formatMessage({
          description: "Create Password page form submit label",
          defaultMessage: "crea nuova password",
        }),
      },
      success: {
        title: intl.formatMessage({
          description: "Create Password page success title",
          defaultMessage: "Password creata con successo",
        }),
        description: intl.formatMessage({
          description: "Create Password page success description",
          defaultMessage:
            "Una mail di conferma è stata mandata al tuo indirizzo email. Per accedere effettua il login con la nuova password.",
        }),
        button: {
          label: intl.formatMessage({
            description: "Create Password page success button label",
            defaultMessage: "torna alla login",
          }),
        },
      },
      privacyLink: intl.formatMessage({
        description: "Create Password page privacy link",
        defaultMessage: "Privacy",
      }),
      legalNotesLink: intl.formatMessage({
        description: "Create Password page legal notes link",
        defaultMessage: "Note Legali",
      }),
      fetchError: {
        server: intl.formatMessage({
          description: "Create Password page fetch error server 500",
          defaultMessage:
            "Errore di comunicazione con il server, riprovare più tardi",
        }),
      },
      badRequest: {
        server: intl.formatMessage({
          description: "Create Password page fetch error server 400",
          defaultMessage:
            "Il codice risulta scaduto, assicurati di utilizzare il codice corretto",
        }),
      },
    },
  }),
};
