import PropTypes from "prop-types";

// react mui
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  // Tab,
  // Tabs,
  Typography,
} from "@mui/material";

// react hook form
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// react router dom
import { useNavigate } from "react-router-dom";

// use-http
import { useFetch } from "use-http";

// components
import {
  BackArrow,
  GreyCustomLabel,
  LinearProgress,
  MainLayout,
  SelectControl,
  TextFieldControl,
} from "components/index";

// constants
import { apiAgenda } from "constants/api";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// containers
import { LocationControl, TimeSlotsControl } from "containers";

// utils
import { createEventDTO } from "utils/Agenda/manageEvents";

const schema = yup
  .object({
    typology: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    nation: yup.object().required(),
    province: yup.object().when("nation", {
      is: { value: "IT", label: "Italy" },
      then: yup.object().required(),
    }),
    city: yup.object().when("nation", {
      is: { value: "IT", label: "Italy" },
      then: yup.object().required(),
      // otherwise: yup.object().nullable(),
    }),
    postalCode: yup.object().when("nation", {
      is: { value: "IT", label: "Italy" },
      then: yup.object().required(),
    }),
    location: yup.string().required(),
    street: yup.string().required().min(3).max(40),
    // visibility: yup.string().required(),
    slotTypology: yup.string().required(),
    slotDuration: yup.number().when("slotTypology", {
      is: "FIXED",
      then: yup.number().required(),
    }),
  })
  .required();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const CreateEvent = () => {
  // eslint-disable-next-line
  const intl = useIntl();
  const i18n = translations.Agenda(intl).createEvent;

  let navigate = useNavigate();

  // const [value, setValue] = useState(0);

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      checkin: false,
      typology: "RESTRICTED",
      slotDuration: 30,
      slots: [{ startDate: "", endDate: "", startTime: "", endTime: "" }],
    },
  });

  const { post, loading, error, response } = useFetch(apiAgenda.API_BASE);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const onSubmit = data => {
    const mappedDTO = createEventDTO(data);
    post(mappedDTO);
  };

  return (
    <MainLayout>
      <BackArrow />
      <Typography variant="h4">{i18n.title}</Typography>
      {error && <Typography color="error">{i18n.serverError}</Typography>}
      {response.ok && <Typography>{i18n.success}</Typography>}
      {loading && <LinearProgress />}
      {!loading && !response.ok && (
        <>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="EVENTI" {...a11yProps(0)} />
        </Tabs>
      </Box> */}
          {/* <TabPanel value={value} index={0}> */}
          {Object.keys(errors).length >= 1 && (
            <Typography color="error">{i18n.compilationErrors}</Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <GreyCustomLabel
              firstLabel={i18n.form.typology.label}
              tooltipText={i18n.form.typology.tooltip}
            />
            <Controller
              control={control}
              errors={errors}
              name="typology"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Grid container>
                    <Grid item>
                      <FormControlLabel
                        value="RESTRICTED"
                        control={<Radio size={"medium"} defaultChecked />}
                        label="Chiuso"
                        defaultChecked
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="OPEN"
                        control={<Radio size={"medium"} />}
                        label="Aperto"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
            <Grid container>
              <Grid item xs={12}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  label={i18n.form.name.label}
                  placeholder={i18n.form.name.label}
                  name="name"
                  errorObj={i18n.form.name.errors}
                  fullWidth
                  isRequired
                />
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={12}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  label={i18n.form.description.label}
                  placeholder={i18n.form.description.label}
                  name="description"
                  errorObj={i18n.form.description.errors}
                  multiline={true}
                  minRows={4}
                  fullWidth
                  isRequired
                />
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="checkin"
                  render={({ field }) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Switch {...field} />
                      <Typography>{i18n.form.checkin}</Typography>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
            <GreyCustomLabel
              firstLabel={i18n.form.location.label}
              tooltipText={i18n.form.location.tooltip}
            />
            <LocationControl
              control={control}
              reset={reset}
              errors={errors}
              watch={watch}
            />
            <TimeSlotsControl control={control} watch={watch} errors={errors} />
            {/* <GreyCustomLabel
          firstLabel={i18n.form.visibility.label}
          tooltipText={i18n.form.visibility.tooltip}
        />
        <Controller
          control={control}
          name="visibility"
          render={({ field }) => (
            <RadioGroup {...field}>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    value="PUBLIC"
                    control={<Radio size={"medium"} defaultChecked />}
                    label={i18n.form.visibility.public}
                    defaultChecked
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="PRIVATE"
                    control={<Radio size={"medium"} />}
                    label={i18n.form.visibility.private}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        /> */}
            <GreyCustomLabel
              firstLabel={i18n.form.slotTypology.label}
              tooltipText={i18n.form.slotTypology.tooltip}
            />
            <Controller
              control={control}
              name="slotTypology"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Grid container mt={2}>
                    <Grid container item xs={12} md={3}>
                      <Grid item>
                        <FormControlLabel
                          value="FREE"
                          control={<Radio size={"medium"} defaultChecked />}
                          label={i18n.form.slotTypology.free}
                          defaultChecked
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="FIXED"
                          control={<Radio size={"medium"} />}
                          label={i18n.form.slotTypology.fixed}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {watch("slotTypology") === "FIXED" && (
                        <SelectControl
                          name={"slotDuration"}
                          errors={errors}
                          label={i18n.form.slotTypology.durations.label}
                          control={control}
                          defaultValue={[
                            {
                              id: 30,
                              name: i18n.form.slotTypology.durations.halfour,
                            },
                          ]}
                          options={[
                            {
                              id: 30,
                              name: i18n.form.slotTypology.durations.halfour,
                            },
                            {
                              id: 60,
                              name: i18n.form.slotTypology.durations.hour,
                            },
                            {
                              id: 120,
                              name: i18n.form.slotTypology.durations.twohours,
                            },
                          ]}
                        />
                      )}
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
            <Grid container mt={1} spacing={2}>
              <Grid item>
                <Button onClick={() => navigate(-1)}>{i18n.form.cancel}</Button>
              </Grid>
              <Grid item>
                <Button type="submit" disabled={loading}>
                  {i18n.form.submit}
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* </TabPanel> */}
        </>
      )}
    </MainLayout>
  );
};

export { CreateEvent };
