import { userStateSelect } from "constants/enums";
import { ReactComponent as IconSearch } from "assets/icons/ico_search.svg";

export const filtersList = [
  { key: "Email", label: "Qualifica", type: "select", value: userStateSelect },
  {
    key: "Group",
    label: "Potenzialità",
    type: "select",
    value: userStateSelect,
  },
  {
    key: "state",
    label: "area/local menager",
    type: "text",
    endIcon: IconSearch,
  },
  { key: "agente", label: "agente", type: "text", endIcon: IconSearch },
  { key: "q", label: "Cerca", type: "text", endIcon: IconSearch },
];
