import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

// components
import { BlackWhiteRoundedButton, MainLayout } from "components/index";

// containers
import { EventsActionDialog, FilteredList } from "containers";

// utils
import translations from "utils/translations";

// import { filtersList } from "./ManageEventsList.filters";

import { HeaderContainer } from "./ManageEventsList.styles";

import useHeader from "./ManageEventsList.header";
import { useAgendaDataListFetch } from "hooks";
import { SCHEDULE_MANAGE_EVENT_CREATE } from "constants/routes";
import { useState } from "react";

const ManageEventsList = () => {
  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);
  const navigate = useNavigate();

  // state for action dialog
  const [selectedEvent, setSelectedEvent] = useState();
  const [action, setAction] = useState();
  const [open, setOpen] = useState(false);

  const { header } = useHeader({ setSelectedEvent, setOpen, setAction });

  const renderHeaderAction = () => {
    return (
      <>
        <HeaderContainer>
          <BlackWhiteRoundedButton
            onClick={() => navigate(SCHEDULE_MANAGE_EVENT_CREATE)}
            label={i18n.manageEvents.createbtnText}
          />
        </HeaderContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <FilteredList
        useCustomFetch={useAgendaDataListFetch}
        i18n={i18n.manageEvents}
        headerAction={renderHeaderAction()}
        i18nErrors={i18n.errors}
        header={header}
        open={open}
        // filtersList={filtersList}
      />
      <EventsActionDialog
        open={open}
        setOpen={setOpen}
        action={action}
        selectedEvent={selectedEvent}
      />
    </MainLayout>
  );
};

export { ManageEventsList };
