import { useContext, useEffect } from "react";
// import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// use-http
import useFetch from "use-http";

// Pages
import {
  MyArea,
  EmployeesList,
  EditProfile,
  ChangePassword,
  PermissionsPage,
  NotFound,
  Schedule,
  DocumentsPage,
  EventPrenotation,
  EventDetail,
  EventCreateAppointment,
  BusinessPartnerList,
  ProfessionalsList,
  PressList,
  EndUsersList,
  ManageEventsList,
  ManageParticipantsList,
  ManageEventDetail,
  CreateEvent,
  CreateEmployee,
  CreateEndUser,
  CreatePressUser,
  CreateProfessionalUser,
  EventDetailAppointment,
  EventUpdateAppointment,
} from "pages/index";

// Context
import { AuthContext } from "context/AuthContext";
import { MainContext } from "context/MainContext";

// constants
import { apiMenu } from "constants/api";
import { Menu } from "constants/menu";
import { userGroups } from "constants/enums";

// hooks
import { useUserRoles } from "hooks";

// utils
import { createPermissionMenu } from "utils/generic";

import {
  DOCUMENTS,
  LOGIN,
  REGISTRY,
  CHANGEPASSWORD,
  EDITPROFILE,
  REGISTRY_PERMISSIONS,
  REGISTRY_POLIFORM_USERS,
  REGISTRY_POLIFORM_USERS_CREATE,
  REGISTRY_POLIFORM_USERS_EDIT,
  REGISTRY_BUSINESS_PARTNERS,
  REGISTRY_PROFESSIONALS,
  REGISTRY_PROFESSIONALS_CREATE,
  REGISTRY_PRESS,
  REGISTRY_PRESS_CREATE,
  REGISTRY_ENDUSERS,
  REGISTRY_ENDUSERS_CREATE,
  ROOT,
  SCHEDULE,
  SCHEDULE_MANAGE_EVENTS,
  SCHEDULE_MANAGE_EVENT_PARTICIPANTS,
  SCHEDULE_MANAGE_EVENT_DETAIL,
  SCHEDULE_EVENT_PRENOTATION,
  SCHEDULE_EVENT_APPOINTMENT_CREATE,
  SCHEDULE_EVENT_DETAIL,
  SCHEDULE_MANAGE_EVENT_CREATE,
  SCHEDULE_EVENT_APPOINTMENT_DETAIL,
  SCHEDULE_EVENT_APPOINTMENT_UPDATE,
} from "constants/routes";

const Authenticated = () => {
  const { user } = useContext(AuthContext);
  const { setPermissionMenu, setLoadingMenu } = useContext(MainContext);
  const { isLoggedUserInGroup } = useUserRoles();

  const { get } = useFetch(`${apiMenu.API_BASE}/${user?.id}`);

  //check on change of the user the new menu
  useEffect(() => {
    if (user) {
      setLoadingMenu(true);
      get().then(newMenu => {
        setPermissionMenu(createPermissionMenu(newMenu, Menu));
        setLoadingMenu(false);
      });
    }
  }, [user]);

  // if there is not logged user object saved on local or session storage, redirect to login
  if (!user) {
    return <Navigate to={LOGIN} replace />;
  }

  return (
    <Routes>
      <Route path={REGISTRY} element={<EmployeesList />} />
      <Route path={REGISTRY_POLIFORM_USERS} element={<EmployeesList />} />
      <Route
        path={REGISTRY_POLIFORM_USERS_CREATE}
        element={<CreateEmployee />}
      />
      <Route path={REGISTRY_POLIFORM_USERS_EDIT} element={<CreateEmployee />} />

      <Route
        path={REGISTRY_BUSINESS_PARTNERS}
        element={<BusinessPartnerList />}
      />
      <Route path={EDITPROFILE} element={<EditProfile />} />
      <Route path={CHANGEPASSWORD} element={<ChangePassword />} />
      <Route path={REGISTRY_PERMISSIONS} element={<PermissionsPage />} />

      <Route path={REGISTRY_PROFESSIONALS} element={<ProfessionalsList />} />
      <Route
        path={REGISTRY_PROFESSIONALS_CREATE}
        element={<CreateProfessionalUser />}
      />

      <Route path={REGISTRY_PRESS} element={<PressList />} />
      <Route path={REGISTRY_PRESS_CREATE} element={<CreatePressUser />} />

      <Route path={REGISTRY_ENDUSERS} element={<EndUsersList />} />
      <Route path={REGISTRY_ENDUSERS_CREATE} element={<CreateEndUser />} />

      {/* Agenda */}
      <Route path={SCHEDULE} element={<Schedule />} />
      {isLoggedUserInGroup(userGroups.ADMIN) && (
        <>
          <Route path={SCHEDULE_MANAGE_EVENTS} element={<ManageEventsList />} />
          <Route
            path={SCHEDULE_MANAGE_EVENT_CREATE}
            element={<CreateEvent />}
          />
        </>
      )}
      <Route path={SCHEDULE_EVENT_PRENOTATION} element={<EventPrenotation />} />
      <Route path={SCHEDULE_EVENT_DETAIL} element={<EventDetail />} />
      <Route
        path={SCHEDULE_EVENT_APPOINTMENT_CREATE}
        element={<EventCreateAppointment />}
      />
      <Route
        path={SCHEDULE_EVENT_APPOINTMENT_DETAIL}
        element={<EventDetailAppointment />}
      />
      <Route
        path={SCHEDULE_EVENT_APPOINTMENT_UPDATE}
        element={<EventUpdateAppointment />}
      />

      <Route
        path={SCHEDULE_MANAGE_EVENT_PARTICIPANTS}
        element={<ManageParticipantsList />}
      />
      <Route
        path={SCHEDULE_MANAGE_EVENT_DETAIL}
        element={<ManageEventDetail />}
      />
      <Route path={DOCUMENTS} element={<DocumentsPage />} />
      {/* This always at the bottom of the tree */}
      <Route path={ROOT} element={<MyArea />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};

export { Authenticated };
