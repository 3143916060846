import { Link, Typography } from "@mui/material";
import PropTypes from "prop-types";

const handleExport = data => {
  console.log("export", data); //TODO: add exporting data logic
};

const ExportLinkCSV = ({ label, data }) => {
  return (
    <Link onClick={() => handleExport(data)}>
      <Typography variant="body1" display={"inline"}>
        {label}
      </Typography>
    </Link>
  );
};

ExportLinkCSV.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

ExportLinkCSV.defaultProps = {
  label: "export .csv",
  data: {},
};

export { ExportLinkCSV };
