import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

// components
import {
  BlackWhiteRoundedButton,
  // ExportLinkCSV,
  MainLayout,
} from "components/index";

// containers
import { FilteredList } from "containers";

// utils
import translations from "utils/translations";

import useEmployeesListFilters from "./EmployeesList.filters";

import { HeaderContainer } from "./EmployeesList.styles";

import useHeader from "./EmployeesList.header";
import { useRegistryDataListFetch } from "hooks";

//constants
import { REGISTRY_POLIFORM_USERS_CREATE } from "constants/routes";

const EmployeesList = () => {
  const [header] = useHeader();
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);
  const navigate = useNavigate();

  // filters
  const filtersList = useEmployeesListFilters();

  const renderHeaderAction = () => {
    return (
      <>
        <HeaderContainer>
          <BlackWhiteRoundedButton
            onClick={() => navigate(REGISTRY_POLIFORM_USERS_CREATE)}
            label={i18n.employees.createbtnText}
          />
          {/* <ExportLinkCSV /> */}
        </HeaderContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <FilteredList
        useCustomFetch={useRegistryDataListFetch}
        i18n={i18n.employees}
        headerAction={renderHeaderAction()}
        i18nErrors={i18n.errors}
        header={header}
        filtersList={filtersList}
        queryUserTypeParams={``}
      />
    </MainLayout>
  );
};

export { EmployeesList };
