import { useState } from "react";
import { InputAdornment, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { CustomTextField } from "./TextField.styles";
import { ReactComponent as IconSearch } from "assets/icons/ico_search.svg";

const TextField = ({ ...rest }) => <CustomTextField {...rest} />;

/**
 * @example <CheckBoxControl
 *            control={control}
              name={'usernameField'}
              errors={errors}
              placeholder={"john"}
              disabled={checkIsDisabled()}
              label={'username'}
              isRequired={true}
              errorMsg={"the username is required"}
            />
 * @param  control - [react-hook-form control Object](https://react-hook-form.com/api/useform/control)
 * @param  name - Textfield name for react-hook-form
 * @param  errors - errors object destructured from useForm()
 * @param  placeholder - Texfield placeholder value
 * @param  disabled - Condition handling checkbox disabled prop
 * @param  label - Textfield label
 * @param  isRequired - Boolean
 * @param  errorObj - The errors object from translations, hanlded by useForm()
 */

const TextFieldControl = ({
  control,
  name,
  defaultValue,
  placeholder,
  disabled,
  label,
  errors,
  errorObj,
  isRequired,
  startIcon,
  endIcon,
  onlyIcon,
  type,
  autoComplete,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(type || "text");

  const renderError = (key, errors, errorObj) => {
    if (errors[key] && errors[key].type)
      return (
        <Typography color={"error"}>{errorObj[errors[key].type]}</Typography>
      );
    return null;
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <TextField
            label={
              <Typography variant="text">
                {label}
                {isRequired && "*"}
              </Typography>
            }
            onChange={e => field.onChange(e.target.value)}
            value={field.value}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autoComplete || ""}
            type={showPassword}
            InputProps={{
              startAdornment: startIcon && (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ),
              endAdornment:
                endIcon &&
                !onlyIcon &&
                ((
                  <InputAdornment
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={() => {
                      if (type == "password") {
                        if (showPassword == "password") {
                          setShowPassword("text");
                        } else {
                          setShowPassword("password");
                        }
                      }
                    }}
                    position="end"
                  >
                    {endIcon}
                  </InputAdornment>
                ) || <IconSearch />), //TODO: implement custum ico for list filters
            }}
            {...field}
            {...rest}
          />
        )}
      />
      {errors && errorObj && renderError(name, errors, errorObj)}
    </>
  );
};

TextFieldControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  errors: PropTypes.object,
  errorObj: PropTypes.object,
  isRequired: PropTypes.bool,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  onlyIcon: PropTypes.bool,
};

TextFieldControl.defaultProps = {
  isRequired: false,
  onlyIcon: false,
};

export { TextField, TextFieldControl };
