import PropTypes from "prop-types";

// react mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// import { ReactComponent as IconDarkQRCode } from "assets/icons/ico_rounded_dark_scan.svg";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

const EventDetailAppointmentParticipantList = ({ participants }) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nRole = translations.components.RegistryLists(intl).header.role;

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size={"small"}>
              <Typography fontSize={12} fontWeight={"bold"}>
                {i18n.eventCreateAppointment.partecipant}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography fontSize={12} fontWeight={"bold"}>
                {i18nRole}{" "}
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {participants.map(row => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell size={"small"} scope="row">
                <Typography fontSize={12}>
                  {row.displayName || row.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={12}>
                  {row.isMain ? (
                    <>{i18n.appointmentOwner}</>
                  ) : (
                    <>{i18n.appointmentGuest}</>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                {/* <a
                  href="https://poliform-dev.nautes.eu/images/cover-area-riservata.jpg"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  <IconDarkQRCode style={{ width: 30 }} />
                </a> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

EventDetailAppointmentParticipantList.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      displayName: PropTypes.string,
      email: PropTypes.string,
      isGuest: PropTypes.bool,
      isMain: PropTypes.bool,
      lastname: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
export { EventDetailAppointmentParticipantList };
