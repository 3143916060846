import Actions from "./Actions";
import CreatePassword from "./CreatePassword";
import ForgotPassword from "./ForgotPassword";
import LoginPage from "./LoginPage";
import Privacy from "./PrivacyPage";
import LegalNotes from "./LegalNotes";
import InformativePage from "./InformativePage";
import Register from "./RegisterPage";
// MY Area
import Home from "./MyArea/MyArea";
import Permissions from "./MyArea/Registry/Permissions";
import EditProfile from "./MyArea/Registry/EditProfile";
import ChangePassword from "./MyArea/Registry/ChangePassword";

// components
import Autocomplete from "./components/Autocomplete";
import UnauthorizedDialog from "./components/UnauthorizedDialog";
import EventDetailBox from "./components/EventDetailBox";
import EventPrenotationDetailBox from "./components/EventPrenotationDetailBox";
import RegistryLists from "./components/ReactTable";
import FiltersList from "./components/FiltersList";
import PrivacyFooter from "./components/PrivacyFooter";

// Agenda
import Agenda from "./Agenda/Agenda";

// templates
import ProfileForm from "./templates/ProfileForm";

export default {
  ...Home,
  ...Actions,
  ...LoginPage,
  ...Register,
  ...ForgotPassword,
  ...CreatePassword,
  ...Privacy,
  ...LegalNotes,
  ...InformativePage,
  MyArea: {
    Registry: {
      ...Permissions,
      ...EditProfile,
      ...ChangePassword,
    },
  },
  ...Agenda,
  components: {
    ...Autocomplete,
    ...UnauthorizedDialog,
    ...EventDetailBox,
    ...EventPrenotationDetailBox,
    ...RegistryLists,
    ...FiltersList,
    ...PrivacyFooter,
  },
  templates: {
    ...ProfileForm,
  },
};
