import { styled } from "@mui/system";

export const SlotWrapper = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.agenda.available}`,
  color: theme.palette.agenda.available,
  display: "flex",
  alignItems: "center",
  height: "100%",
  maxHeight: "40px",
  borderRadius: "5px",
  position: "relative",
  padding: "5px",
  backgroundColor: theme.palette.background.default,
  marginBottom: 10,
  flex: 1,
  "&:hover": {
    cursor: "pointer",
  },
  /* Hide scrollbar for Chrome, Safari and Opera */
  "&::-webkit-scrollbar": {
    display: "none",
  },

  /* Hide scrollbar for IE, Edge and Firefox */
  "&": {
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
}));

export const PrenotationIconWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.primary.main,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  fontSize: "1rem",
  margin: "5px",
}));

export const DeleteAvailabilityWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.agenda.occupied,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  position: "absolute",
  top: 5,
  right: 5,
  fontSize: "1rem",
}));
