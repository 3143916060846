export const colors = {
  WHITESMOKE: "#f7f5f4",
  GAINSBORO: "#f7f5f4",
  DARKGAINSBORO: "#efebe9",
  DARKGREY: "#acacac",
  WHITE: "#ffffff",
  BLACK: "#000000",
  YELLOWGREEN: "#8cc63f",
  GOLEDNROD: "#e6a83b",
  LIGHTBLUE: "#58bfd5",
  RED: "#FF0000",
  OCCUPIEDSLOT: "#E67F83",
  PENDINGSLOT: "#FFC990",
  AVAILABLESLOT: "#000000",
  APPROVEDSLOT: "#ACDC87",
};
