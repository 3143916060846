import { Routes, Route } from "react-router-dom";

// Contexts
import AuthProvider from "context/AuthContext";
import MainProvider from "context/MainContext";
// Pages
import {
  Login,
  ForgotPassword,
  CreatePassword,
  Privacy,
  LegalNotes,
  Informative,
  ConfirmParticipation,
  Authenticated,
  DownloadApp,
} from "pages";

// Routes
import {
  CREATEPASSWORD,
  DOWNLOADAPP,
  FORGOTPASSWORD,
  INFORMATIVE,
  LEGALNOTES,
  LOGIN,
  ONBOARDING,
  PRIVACY,
  SCHEDULE_EVENTS_CONFIRMPARTICIPATION,
} from "constants/routes";

function App() {
  return (
    <AuthProvider>
      <MainProvider>
        <Routes>
          {/* Not Auth Routes */}
          <Route path={LOGIN} element={<Login />} />
          {/* <Route path={REGISTER} element={<Register />} /> */}
          <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={CREATEPASSWORD} element={<CreatePassword />} />
          <Route path={PRIVACY} element={<Privacy />} />
          <Route path={LEGALNOTES} element={<LegalNotes />} />
          <Route path={INFORMATIVE} element={<Informative />} />
          <Route path={DOWNLOADAPP} element={<DownloadApp />} />
          {/* Not Auth Routes - Confirm Participation */}
          <Route
            path={SCHEDULE_EVENTS_CONFIRMPARTICIPATION}
            element={<ConfirmParticipation />}
          />
          {/* Not Auth Routes - Onboarding (it uses the same component as CreatePassword)*/}
          <Route path={ONBOARDING} element={<CreatePassword />} />
          {/* Auth routes */}
          <Route path={"*"} element={<Authenticated />} />
        </Routes>
      </MainProvider>
    </AuthProvider>
  );
}

export default App;
