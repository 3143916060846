import { styled } from "@mui/system";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  background: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const Panel = styled("div")(({ theme }) => ({
  background: theme.palette.background.default,
  flex: 1,
  margin: "50px 80px",
  padding: "50px",
  position: "relative",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    margin: "15px",
  },
  // [theme.breakpoints.down("md")]: {
  //   margin: "0",
  //   boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
  //   color: theme.palette.primary.light,
  //   background:
  //     "url('https://picsum.photos/1920/1080') transparent no-repeat bottom center  / cover",
  // },
}));
