export default {
  Login: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Login page title",
        defaultMessage: "Questo è un titolo pronto alla traduzione",
      }),
      loginAction: intl.formatMessage({
        description: "Login page login action",
        defaultMessage:
          "Effettua il login o registrati per accedere all'area riservata",
      }),
      loginFormEmail: {
        label: intl.formatMessage({
          description: "Login page login form email input label",
          defaultMessage: "email",
        }),
        errors: {
          required: intl.formatMessage({
            description: "Login page login form email input error required",
            defaultMessage: "Il campo email è obbligatorio",
          }),
          email: intl.formatMessage({
            description:
              "Login page login form email input error email invalid",
            defaultMessage: "Inserire una mail valida",
          }),
        },
      },
      loginFormPassword: {
        label: intl.formatMessage({
          description: "Login page login form password input label",
          defaultMessage: "password",
        }),
        errors: {
          required: intl.formatMessage({
            description: "Login page login form password input error",
            defaultMessage: "Il campo password è obbligatorio",
          }),
        },
      },
      loginFormRetrievePassword: intl.formatMessage({
        description: "Login page login form retrieve password",
        defaultMessage: "Recupera Password",
      }),

      loginFormSubmit: intl.formatMessage({
        description: "Login page login form checkbox label",
        defaultMessage: "Entra",
      }),
      loginRegisterInvitation: intl.formatMessage({
        description: "Login page register invitation",
        defaultMessage: "Non disponi di un account?",
      }),
      loginRegister: intl.formatMessage({
        description: "Login page register label",
        defaultMessage: "Registrati",
      }),
      fetchError: {
        credential: intl.formatMessage({
          description: "Login page fetchError credential",
          defaultMessage:
            "Errore, assicurati di aver inserito correttamente le tue credenziali",
        }),
        server: intl.formatMessage({
          description: "Login page fetchError server",
          defaultMessage: "Problema con il server, riprovare più tardi",
        }),
      },
      privacyLink: intl.formatMessage({
        description: "Login page privacy link",
        defaultMessage: "Privacy",
      }),
      legalNotesLink: intl.formatMessage({
        description: "Login page legal notes link",
        defaultMessage: "Note Legali",
      }),
    },
  }),
};
