import { useContext, useEffect, useState } from "react";

// context
import { AuthContext } from "context/AuthContext";

// prop-types
import PropTypes from "prop-types";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

// use-http
import useFetch from "use-http";

// react-hook-form
import { Controller } from "react-hook-form";

const AutocompleteControlAPI = ({
  control,
  name,
  defaultValue,
  errors,
  errorObj,
  disabled,
  queryParam,
  endpoint,
  noOptionsText,
  onChange,
  // options,
  label,
  resetValue,
  isRequired = false,
  addSelf = false,
  ...rest
}) => {
  // state
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [options, setOptions] = useState([]);

  // user if addSelf is true

  const { user } = useContext(AuthContext);

  // hooks
  const intl = useIntl();
  const i18n = translations.components.Autocomplete(intl).component;

  const [noOptionsTextState, setNoOptionsTextState] = useState(
    i18n.atLeastThree
  );

  // use http
  const { get, response, abort, loading, error } = useFetch(endpoint);

  useEffect(async () => {
    if (loading) abort();
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (inputValue?.length <= 2) {
      return setNoOptionsTextState(i18n.atLeastThree);
    }
    const filtredCountries = await get(`?${queryParam}=${inputValue}`);
    if (response.ok) {
      // TODO refactor the error of some list that have
      // the structure of other queries
      if (filtredCountries.count >= 0) {
        setOptions(
          addSelf
            ? user.displayName.toLowerCase().includes(inputValue.toLowerCase())
              ? [
                  ...filtredCountries.data,
                  { id: user.id, displayName: user.displayName },
                ]
              : filtredCountries.data
            : filtredCountries.data
        );
      } else {
        setOptions(
          addSelf
            ? user.displayName.toLowerCase().includes(inputValue.toLowerCase())
              ? [
                  ...filtredCountries,
                  { id: user.id, displayName: user.displayName },
                ]
              : filtredCountries
            : filtredCountries
        );
      }
    }

    setNoOptionsTextState(noOptionsText ? noOptionsText : i18n.noOptionsText);
  }, [value, inputValue, get]);

  useEffect(() => {
    setOptions([]);
    setValue(null);
  }, [resetValue]);

  const renderError = (key, errors, errorObj) => {
    if (errors[key] && errors[key].type)
      return (
        <Typography color={"error"}>{errorObj[errors[key].type]}</Typography>
      );
    return null;
  };
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ""}
        render={({ field }) => (
          <Autocomplete
            getOptionLabel={option =>
              option.displayName ? option.displayName : option.name
            }
            filterOptions={x => x}
            options={options}
            isOptionEqualToValue={x => x}
            autoComplete
            noOptionsText={error ? i18n.fetchError : noOptionsTextState}
            disabled={disabled}
            includeInputInList
            loading={loading}
            loadingText={<CircularProgress size={20} />}
            value={value}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              field.onChange(newValue);
              if (onChange) {
                onChange(newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  <Typography variant="text">
                    {label}
                    {isRequired && "*"}
                  </Typography>
                }
                fullWidth
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="body1">
                        {option.displayName ? option.displayName : option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
            // {...field}
            {...rest}
          />
        )}
      />
      {errors && errorObj && renderError(name, errors, errorObj)}
    </>
  );
};

AutocompleteControlAPI.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  endpoint: PropTypes.string,
  noOptionsText: PropTypes.string,
  queryParam: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorObj: PropTypes.object,
  resetValue: PropTypes.bool,
  isRequired: PropTypes.bool,
  addSelf: PropTypes.bool,
};

AutocompleteControlAPI.defaultProps = {
  disabled: false,
};

export { AutocompleteControlAPI };
