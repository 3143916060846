import { useContext } from "react";
import PropTypes from "prop-types";

// react router dom
import { Link } from "react-router-dom";

// context
import { MainContext } from "context/MainContext";
import { AuthContext } from "context/AuthContext";

// mui
import { Typography } from "@mui/material";

// constants
import { EDITPROFILE } from "constants/routes";

// styled-components
import {
  Wrapper,
  ContentWrapper,
  ChildrenWrapper,
  TopbarWrapper,
  UserContainer,
  UserInfoContainer,
  LogoutIconContainer,
} from "./Layout.styles";

// components
import { Sidebar } from "components/index";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

const MainLayout = ({ children }) => {
  // context
  const { user, setUser } = useContext(AuthContext);
  const { openSidebar, setOpenSidebar } = useContext(MainContext);

  // handlers
  const handleLogout = () => {
    setUser(null);
  };

  return (
    <Wrapper>
      <Sidebar />

      <ContentWrapper>
        <TopbarWrapper>
          <MenuIcon
            style={{ height: "20px" }}
            onClick={() => {
              setOpenSidebar(!openSidebar);
            }}
          />
          <UserContainer>
            <UserInfoContainer>
              <Link
                to={EDITPROFILE}
                style={{ textDecoration: "none", color: "unset" }}
              >
                <Typography variant="body1" fontWeight={"bold"}>
                  {user?.displayName && <>{user.displayName}</>}
                  {!user.displayName && (
                    <>
                      {user?.name} {user?.lastname}
                    </>
                  )}
                </Typography>
                <Typography variant="body1" color="secondary.dark">
                  {user?.email}
                </Typography>
              </Link>
            </UserInfoContainer>
            <LogoutIconContainer>
              <LogoutIcon onClick={handleLogout} />
            </LogoutIconContainer>
          </UserContainer>
        </TopbarWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export { MainLayout };
