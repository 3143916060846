// yup
import * as yup from "yup";

const useProfessionalSchema = () => {
  const professionalSchema = yup
    .object({
      name: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().trim().email().required(),
      language: yup.string().required(),
      // TODO uncomment this when ready to accept sectors
      // sectors: yup.array().of(yup.number()),
    })
    .required();

  return { professionalSchema };
};
export { useProfessionalSchema };
