import { useContext, useEffect, useState } from "react";
// react  router dom
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  AutocompleteControlAPI,
  BlackWhiteRoundedButton,
  CheckBoxControl,
  DatePicker,
  GreyCustomLabel,
  LoadingDialog,
  MainLayout,
  MainParticipantControl,
  TextField,
  TimePicker,
  CreateProfessional,
  TextFieldControl,
} from "components";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

// containers
import { ParticipantListControl } from "containers";

// context
import { AuthContext } from "context/AuthContext";

// constants
import { apiAvailabilities, apiUser } from "constants/api";
import { formControlNames, userGroups } from "constants/enums";

//react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// use-http
import useFetch from "use-http";

// date fns
import { SCHEDULE, SCHEDULE_EVENT_PRENOTATION } from "constants/routes";

// utils
import { createAppointment } from "utils/Agenda";

// hooks
import { useAgendaSchema, useUserRoles } from "hooks";

const EventCreateAppointment = () => {
  const { user } = useContext(AuthContext);

  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);
  const i18nAutocompleteErr =
    translations.components.Autocomplete(intl).agents.client;
  const i18nAutocompletelabel =
    translations.templates.ProfileForm(intl).page.selectType;

  let navigate = useNavigate();

  const { isLoggedUserOneofGroups } = useUserRoles();

  // schema
  const { appointmentSchema } = useAgendaSchema();

  const { state } = useLocation();

  if (!state) {
    return <Navigate to={SCHEDULE} />;
  }

  const { slot: availabilitySlot, event: appointmentEvent } = state;

  const { post, loading: creatingAppointment } = useFetch(
    `${apiAvailabilities.API_BASE}/${availabilitySlot?.id}${apiAvailabilities.APPOINTMENT}`
  );

  const [usersCluster, setUsersCluster] = useState(userGroups.BUSINESSPARTNER);
  // dialog for Professionale creation
  const [openDialog, setOpenDialog] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    getValues,
  } = useForm({
    defaultValues: { searchRegisteredUser: true },
    resolver: yupResolver(appointmentSchema),
  });

  const onSubmit = data => {
    post(
      createAppointment(user, data, availabilitySlot, appointmentEvent)
    ).then(() => {
      navigate(
        `${SCHEDULE_EVENT_PRENOTATION}?currDate=${availabilitySlot?.start.getTime()}`,
        { state: appointmentEvent }
      );
    });
  };

  watch((data, { name }) => {
    if (name == "searchRegisteredUser") {
      reset({ ...data, client: null, participant: null });
    }
  });

  useEffect(() => {
    if (usersCluster !== userGroups.ENDUSER) {
      reset({ guests: getValues("guests"), searchRegisteredUser: true });
    }
  }, [usersCluster]);

  return (
    <MainLayout>
      <Typography variant={"h4"}>
        {i18n.eventCreateAppointment.title} - {appointmentEvent.name}
      </Typography>

      <GreyCustomLabel firstLabel={i18n.eventCreateAppointment.greyLabel} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={3}>
            <DatePicker value={availabilitySlot?.start} disabled />
          </Grid>
          <Grid item xs={12} md={3}>
            <TimePicker value={availabilitySlot?.start} disabled />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker value={availabilitySlot?.end} disabled />
          </Grid>
          <Grid item xs={12} md={3}>
            <TimePicker value={availabilitySlot?.end} disabled />
          </Grid>
        </Grid>
        <GreyCustomLabel firstLabel={i18n.eventCreateAppointment.partecipant} />
        {isLoggedUserOneofGroups([
          userGroups.ADMIN,
          userGroups.AGENT,
          userGroups.AGENTSTAFF,
          userGroups.AREAMANAGER,
          userGroups.LOCALMANAGER,
        ]) && (
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={userGroups.BUSINESSPARTNER}
                name="radio-buttons-group"
                onChange={(e, v) => {
                  setUsersCluster(v);
                }}
              >
                <Grid container mt={2} alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      value={userGroups.BUSINESSPARTNER}
                      control={<Radio />}
                      label={i18nAutocompletelabel.businessPartner}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={userGroups.PROFESSIONAL}
                      control={<Radio />}
                      label={i18nAutocompletelabel.professional}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={userGroups.PRESS}
                      control={<Radio />}
                      label={i18nAutocompletelabel.press}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={userGroups.ENDUSER}
                      control={<Radio />}
                      label={i18nAutocompletelabel.private}
                      onChange={() => {
                        reset({
                          guests: getValues("guests"),
                          participant: {
                            name: null,
                            lastname: null,
                            email: null,
                          },
                          searchRegisteredUser: false,
                        });
                      }}
                    />
                  </Grid>
                  {usersCluster === userGroups.PROFESSIONAL && (
                    <>
                      <Grid item>
                        <Button onClick={() => setOpenDialog(true)}>
                          {i18nActions.create}
                        </Button>
                      </Grid>
                      {openDialog && (
                        <CreateProfessional
                          open={openDialog}
                          setOpen={setOpenDialog}
                        />
                      )}
                    </>
                  )}
                  {usersCluster === userGroups.ENDUSER && (
                    <Grid item>
                      <CheckBoxControl
                        control={control}
                        name={"searchRegisteredUser"}
                        label={
                          i18n.eventCreateAppointment.searchRegisteredUsers
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </RadioGroup>
            </FormControl>
            {errors.participant && (
              <Typography color="error">
                {i18n.createEvent.compilationErrors}
              </Typography>
            )}
            <Grid container mb={2} spacing={2} alignItems="center">
              {watch("searchRegisteredUser") && (
                <Grid item xs={12} mt={2} md={3}>
                  <AutocompleteControlAPI
                    control={control}
                    name={"client"}
                    label={(() => {
                      switch (usersCluster) {
                        case userGroups.BUSINESSPARTNER:
                          return i18nAutocompletelabel.businessPartner;
                        case userGroups.PROFESSIONAL:
                          return i18nAutocompletelabel.professional;
                        case userGroups.PRESS:
                          return i18nAutocompletelabel.press;
                        case userGroups.ENDUSER:
                          return i18nAutocompletelabel.private;
                        default:
                          return i18nAutocompletelabel.private;
                      }
                    })()}
                    errorObj={{
                      required: i18nAutocompleteErr.required,
                      typeError: i18nAutocompleteErr.required,
                    }}
                    noOptionsText={i18nAutocompleteErr.noOptions}
                    errors={errors}
                    endpoint={`${apiUser.API_BASE}${apiUser.AUTOCOMPLETE}`}
                    queryParam={`&Group=${usersCluster}&q`}
                    isRequired
                  />
                </Grid>
              )}

              {!watch("searchRegisteredUser") && (
                <MainParticipantControl control={control} errors={errors} />
              )}
            </Grid>
            <GreyCustomLabel firstLabel={i18n.confirmParticipation.note} />
            <Grid spacing={2} mt={2} item xs={12} container>
              <Grid item xs={12} md={6}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  name={formControlNames.agenda.note}
                  label={"note"}
                  placeholder={"note"}
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!isLoggedUserOneofGroups([
          userGroups.ADMIN,
          userGroups.AGENT,
          userGroups.AGENTSTAFF,
          userGroups.AREAMANAGER,
          userGroups.LOCALMANAGER,
        ]) && (
          <Grid container mt={1} mb={2} spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField defaultValue={user.name} disabled label={"nome"} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                defaultValue={user.lastname}
                disabled
                label={"cognome"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField defaultValue={user.email} disabled label={"email"} />
            </Grid>
          </Grid>
        )}

        <ParticipantListControl control={control} errors={errors} />
        <BlackWhiteRoundedButton type="submit" label={i18nActions.save} />
        <Link
          to={`${SCHEDULE_EVENT_PRENOTATION}?currDate=${availabilitySlot?.start.getTime()}`}
          state={appointmentEvent}
        >
          <Button>{i18nActions.cancel}</Button>
        </Link>
      </form>
      <LoadingDialog open={creatingAppointment} />
    </MainLayout>
  );
};

export { EventCreateAppointment };
