import { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

// components
import { ReactTable, FiltersPanel, BackArrow } from "components/index";
// utils
import { createColumns } from "utils/components/ReactTable";

function FilteredList({
  i18n,
  i18nErrors,
  queryUserTypeParams,
  header,
  headerAction,
  filtersList,
  titleAddictions,
  backArrow,
  useCustomFetch,
  open = false,
}) {
  // State for ReactTable
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [queryParameters, setQueryParameters] = useState("");
  const [currentOrder, setCurrentOrder] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [error, loading, rows, pageCount, elementCount] = useCustomFetch(
    pageIndex,
    pageSize,
    queryParameters,
    currentOrderId,
    currentOrder,
    queryUserTypeParams,
    setPageIndex,
    open
  );

  const parsedColumns = useMemo(
    () =>
      createColumns(
        header,
        currentOrder,
        currentOrderId,
        setCurrentOrder,
        setCurrentOrderId
      ),
    [currentOrderId, currentOrder]
  );

  return (
    <>
      {backArrow && <BackArrow />}
      <Typography variant="h4">{i18n.title + titleAddictions} </Typography>
      {headerAction}
      {filtersList && (
        <FiltersPanel
          filtersList={filtersList}
          queryParameters={queryParameters}
          setQueryParameters={setQueryParameters}
          setPageIndex={setPageIndex}
        />
      )}

      {error && (
        <Typography varint={"body1"} color={"error"} marginTop={1}>
          {i18nErrors.missingDataServer}
        </Typography>
      )}
      {!error && (
        <ReactTable
          setFetchPageSize={setPageSize}
          setFetchPageIndex={setPageIndex}
          columns={parsedColumns}
          data={rows}
          loading={loading}
          pageCount={pageCount}
          elementCount={elementCount}
        />
      )}
    </>
  );
}

FilteredList.propTypes = {
  backArrow: PropTypes.bool,
  useCustomFetch: PropTypes.object,
  i18n: PropTypes.object,
  i18nErrors: PropTypes.object,
  titleAddictions: PropTypes.string,
  queryUserTypeParams: PropTypes.string,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.element,
    })
  ),
  filtersList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      endPoint: PropTypes.string,
      value: PropTypes.string,
      endIcon: PropTypes.string,
    })
  ),
  headerAction: PropTypes.element,
  open: PropTypes.bool,
};

FilteredList.defaultProps = {
  titleAddictions: "",
};

export { FilteredList };
