export default {
  EventDetailBox: intl => ({
    component: {
      fetchError: intl.formatMessage({
        description: "EventDetailBox component fetch error",
        defaultMessage: "Errore di comunicazione con il server",
      }),
      title: intl.formatMessage({
        description: "EventDetailBox component title",
        defaultMessage: "Dettaglio Evento",
      }),
      locationTitle: intl.formatMessage({
        description: "EventDetailBox component location title",
        defaultMessage: "Dove",
      }),
      dateTitle: intl.formatMessage({
        description: "EventDetailBox component date title",
        defaultMessage: "Quando",
      }),
      hour: {
        title: intl.formatMessage({
          description: "EventDetailBox component hour span title",
          defaultMessage: "A che ora",
        }),
        from: intl.formatMessage({
          description: "EventDetailBox component hour span from",
          defaultMessage: "Dalle",
        }),
        to: intl.formatMessage({
          description: "EventDetailBox component hour span to",
          defaultMessage: "alle",
        }),
      },
      description: intl.formatMessage({
        description: "EventDetailBox component description title",
        defaultMessage: "Più informazioni",
      }),
    },
  }),
};
