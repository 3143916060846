import { styled } from "@mui/system";

export const SlotWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.agenda.pending,
  alignItems: "center",
  height: "100%",
  maxHeight: "40px",
  display: "flex",
  borderRadius: "5px",
  position: "relative",
  padding: 10,
  marginBottom: 10,
  flex: 1,
  "&:hover": {
    cursor: "pointer",
  },
}));

export const DeleteAvailabilityWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.agenda.occupied,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  position: "absolute",
  top: 5,
  right: 5,
  fontSize: "1rem",
}));
