// react router dom
import { useNavigate } from "react-router-dom";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";
// components
import { ActionIconManager } from "components";

// constants
import { eventManageEvents, ListIconTypes } from "constants/enums";
import { SCHEDULE_MANAGE_EVENT_PARTICIPANTS } from "constants/routes";

// date-fns
import { format } from "date-fns";

const useHeader = ({ setSelectedEvent, setOpen, setAction }) => {
  const intl = useIntl();
  const i18n = translations.components.AgendaLists(intl);
  // eslint-disable-next-line
  const navigate = useNavigate();

  const header = [
    {
      name: i18n.header.code,
      accessor: "id",
      //Cell: p => <>cdsjunh {p.row.original.id}</>,
    },
    {
      name: i18n.header.event,
      accessor: "name",
    },
    {
      name: i18n.header.startDate,
      accessor: "startDate",
      Cell: p => {
        return format(new Date(p.value), "yyyy-MM-dd");
      },
    },
    {
      name: i18n.header.endDate,
      accessor: "endDate",
      Cell: p => {
        return format(new Date(p.value), "yyyy-MM-dd");
      },
    },
    {
      name: i18n.header.city,
      accessor: "address.location",
    },
    {
      name: i18n.header.typologies,
      accessor: "typologies",
    },
    {
      name: i18n.header.guest,
      accessor: "guest",
    },
    {
      name: i18n.header.checkIn,
      accessor: "checkIn",
    },
    {
      name: i18n.header.state,
      accessor: "state",
    },
    {
      // Header: "Cognome",
      accessor: "editrights", // accessor is the "key" in the data
      Cell: element => (
        <ActionIconManager
          listActions={
            element.row.original.deletable
              ? [
                  {
                    type: ListIconTypes.DELETE,
                    callback: () => {
                      setSelectedEvent(element.row.original);
                      setAction(eventManageEvents.DELETE);
                      setOpen(true);
                    },
                  },
                  {
                    type: ListIconTypes.PARTICIPANTS,
                    callback: () => {
                      navigate(SCHEDULE_MANAGE_EVENT_PARTICIPANTS, {
                        state: {
                          ...element.row.original,
                        },
                      });
                    },
                  },
                ]
              : [
                  {
                    type: ListIconTypes.PARTICIPANTS,
                    callback: () => {
                      navigate(SCHEDULE_MANAGE_EVENT_PARTICIPANTS, {
                        state: {
                          ...element.row.original,
                        },
                      });
                    },
                  },
                ]
          }
        />
      ),
    },
  ];

  return { header };
};

export default useHeader;
