import { styled } from "@mui/system";
import { Select } from "@mui/material";

export const TableWrapper = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  display: "flex",
  flexDirection: "column",

  // minHeight: "300px",
});

export const TableHead = styled("thead")(({ theme }) => ({
  textAlign: "left",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}));
export const TableRow = styled("tr")(({ theme }) => ({
  textAlign: "left",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  height: "60px",
  display: "flex",
  alignItems: "center",
  "& td, & th": {
    flex: "1",
    overflow: "hidden",
  },
}));
export const TablePaginationWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&>*": {
    margin: "0 10px",
  },
});

export const TablePaginationNumberWrapper = styled("div")({
  display: "flex",
  maxWidth: "150px",
  overflowX: "auto",
});

export const TablePaginationNumberContainer = styled("div")(({ focused }) => ({
  flex: 1,
  margin: "0 5px",
  opacity: focused ? "1" : "0.3",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const TablePaginationPageSizeWrapper = styled(Select)({
  "& fieldset": {
    border: "none",
  },
});
