export const ROOT = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const PRIVACY = "/privacy";
export const LEGALNOTES = "/legalnotes";
export const INFORMATIVE = "/informative";
export const FORGOTPASSWORD = "/forgot";
export const CREATEPASSWORD = "/resetPassword";
export const DOWNLOADAPP = "/downloadApp";

// Modifica profilo
export const EDITPROFILE = "/editprofile";
export const CHANGEPASSWORD = "/changepassword";

// Onboarding
export const ONBOARDING = "/onboarding";

// Agenda
export const SCHEDULE = "/schedule";
export const SCHEDULE_MANAGE_SHOWROOM = "/schedule/manageshowrooms";
export const SCHEDULE_SHOWROOM = "/schedule/showrooms";
export const SCHEDULE_MANAGE_EVENTS = "/schedule/manageevents";
export const SCHEDULE_MANAGE_EVENT_PARTICIPANTS =
  "/schedule/manageevents/manageparticipants";
export const SCHEDULE_MANAGE_EVENT_CREATE = "/schedule/manageevents/create";
export const SCHEDULE_MANAGE_EVENT_DETAIL = "/schedule/manageevents/detail";

export const SCHEDULE_EVENTS = "/schedule/events";
export const SCHEDULE_EVENTS_CONFIRMPARTICIPATION =
  "/schedule/confirmparticipation/:participationId";
export const SCHEDULE_EVENT_DETAIL = "/schedule/events/detail";
export const SCHEDULE_EVENT_PRENOTATION = "/schedule/events/prenotation";
export const SCHEDULE_EVENT_APPOINTMENT_CREATE =
  "/schedule/events/appointment/create";
export const SCHEDULE_EVENT_APPOINTMENT_DETAIL =
  "/schedule/events/appointment/detail";
export const SCHEDULE_EVENT_APPOINTMENT_UPDATE =
  "/schedule/events/appointment/update";
export const SCHEDULE_ARCHIVE = "/schedule/archive";

// Anagrafiche
export const REGISTRY = "/registry";
export const REGISTRY_PERMISSIONS = "/registry/editpermissions";
export const REGISTRYUSERSLIST = "/registry/userslist";
export const REGISTRY_POLIFORM_USERS = "/registry/poliformusers";
export const REGISTRY_POLIFORM_USERS_CREATE = "/registry/poliformusers/create";
export const REGISTRY_POLIFORM_USERS_EDIT = "/registry/poliformusers/edit";

export const REGISTRY_BUSINESS_PARTNERS = "/registry/businesspartners";
export const REGISTRY_PROFESSIONALS = "/registry/professionals";
export const REGISTRY_PROFESSIONALS_CREATE = "/registry/professionals/create";
export const REGISTRY_PRESS = "/registry/press";
export const REGISTRY_PRESS_CREATE = "/registry/press/create";
export const REGISTRY_ENDUSERS = "/registry/endusers";
export const REGISTRY_ENDUSERS_CREATE = "/registry/endusers/create";

// Documenti
export const DOCUMENTS = "/documents";
export const DOCUMENTS_CIRCULAR_ARCHIVE = "/documents/circulararchive";
export const DOCUMENTS_LISTS = "/documents/lists";
export const DOCUMENTS_STATEMENTS = "/documents/statements";
export const DOCUMENTS_ARCHIVES = "/documents/archive";
export const DOCUMENTS_STOCK_ORDERS = "/documents/stockorders";
export const DOCUMENTS_ORDERS = "/documents/stocks";

// Marketing
export const MARKETING = "/marketing";
export const MARKETING_MANUALS = "/marketing/manuals";
export const MARKETING_MATERIALS = "/marketing/materials";

// Press
export const PRESS = "/press";
export const PRESS_COMUNICATIONS = "/press/comunications";

// Kpi
export const KPI = "/kpi";
