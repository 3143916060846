import PropTypes from "prop-types";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import { Button, Grid, Typography } from "@mui/material";

// react hook form
import { useFieldArray } from "react-hook-form";

// components
import { GreyCustomLabel, TextFieldControl } from "components";

function ParticipantListControl({ control, errors, disabled = false }) {
  const intl = useIntl();
  const i18n = translations.Commons(intl);
  const actions = translations.Actions(intl);
  const compilationErrors = translations.Agenda(intl).createEvent;

  const { fields, append, remove } = useFieldArray({
    name: "guests",
    control,
  });

  const handleAddParticipants = () => {
    append({ name: "", lastname: "", email: "", disabled: false });
  };

  return (
    <>
      {!disabled && (
        <GreyCustomLabel
          firstLabel={i18n.guestList}
          addLabel={i18n.addGuest}
          handleAdd={handleAddParticipants}
        />
      )}
      {disabled && <GreyCustomLabel firstLabel={i18n.guestList} />}
      {errors.guests?.length && (
        <Typography color="error">
          {compilationErrors.compilationErrors}
        </Typography>
      )}
      {errors.guests?.type === "unique" && (
        <Typography color="error">
          {compilationErrors.duplicateEmailError}
        </Typography>
      )}
      <Grid container spacing={2} my={2}>
        {fields.map((participant, i) => (
          <Grid spacing={2} item xs={12} container key={participant.id}>
            <Grid item xs={12} md={3}>
              <TextFieldControl
                control={control}
                name={`guests[${i}].name`}
                defaultValue={`${participant.name}`}
                placeholder={i18n.name.label}
                label={i18n.name.label}
                errors={errors}
                isRequired
                errorObj={i18n.name.errors}
                disabled={disabled || participant.disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldControl
                control={control}
                name={`guests[${i}].lastname`}
                defaultValue={`${participant.lastname}`}
                placeholder={i18n.lastname.label}
                label={i18n.lastname.label}
                errors={errors}
                isRequired
                errorObj={i18n.lastname.errors}
                disabled={disabled || participant.disabled}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <TextFieldControl
                control={control}
                name={`guests[${i}].email`}
                defaultValue={`${participant.email}`}
                placeholder={i18n.email.label}
                label={i18n.email.label}
                isRequired
                errors={errors}
                errorObj={i18n.email.errors}
                disabled={disabled || participant.disabled}
              />
              {!disabled && !participant.disabled && (
                <Button
                  type="Button"
                  onClick={() => {
                    remove(i);
                  }}
                >
                  {actions.remove}
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

ParticipantListControl.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};
export { ParticipantListControl };
