import PropTypes from "prop-types";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

function ErrorDialog({ open, onClose, type }) {
  // hooks
  const intl = useIntl();
  const i18n = translations.components.UnauthorizedDialog(intl).component;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Typography>{i18n.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{i18n[type]}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{i18n.close}</Button>
      </DialogActions>
    </Dialog>
  );
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export { ErrorDialog };
