import PropTypes from "prop-types";

// react mui
import { Typography } from "@mui/material";

// react router dom
import { useNavigate } from "react-router-dom";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// styled components
import {
  DeleteAvailabilityWrapper,
  PrenotationIconWrapper,
  SlotWrapper,
} from "./Available.styles";

// constants
import { SCHEDULE_EVENT_APPOINTMENT_CREATE } from "constants/routes";
import { calendarDialogActions } from "constants/enums";

const AvailableSlot = ({
  setSelectedSlot,
  setOpen,
  slot,
  event,
  setAction,
}) => {
  const navigate = useNavigate();

  const intl = useIntl();
  const i18n = translations.Agenda(intl).slots.availableSlot;

  const handleDeleteAvailability = e => {
    e.stopPropagation();
    setSelectedSlot(slot);
    setAction(calendarDialogActions.DELETEAVAILABILITY);
    setOpen(true);
  };

  const handleCreateAppointment = () => {
    navigate(SCHEDULE_EVENT_APPOINTMENT_CREATE, {
      state: { slot, event },
    });
  };

  return (
    <>
      <SlotWrapper
        onClick={() => {
          if (!slot.exhibitionSlotId) {
            setSelectedSlot(slot);
            setOpen(true);
          } else {
            handleCreateAppointment();
          }
        }}
      >
        <DeleteAvailabilityWrapper onClick={handleDeleteAvailability}>
          ⨯
        </DeleteAvailabilityWrapper>
        <PrenotationIconWrapper>+</PrenotationIconWrapper>
        <Typography lineHeight={1} fontSize={10}>
          <b>{slot.fromDisplayName}</b> - {i18n.label}
        </Typography>
      </SlotWrapper>
    </>
  );
};

AvailableSlot.propTypes = {
  setSelectedSlot: PropTypes.func,
  setOpen: PropTypes.func,
  setAction: PropTypes.func,
  slot: PropTypes.object,
  event: PropTypes.object,
};

export { AvailableSlot };
