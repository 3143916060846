import { Typography } from "@mui/material";
import { MainLayout } from "components/index";

const DocumentsPage = () => {
  return (
    <MainLayout>
      <Typography variant="h3">DocumentsPage</Typography>
    </MainLayout>
  );
};
export { DocumentsPage };
