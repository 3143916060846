import { styled } from "@mui/system";

export const SlotWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.agenda.occupied,
  alignItems: "center",
  height: "100%",
  maxHeight: "40px",
  display: "flex",
  borderRadius: "5px",
  padding: "5px",
  marginBottom: 10,
  flex: 1,
}));
