import PropTypes from "prop-types";

// react mui
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// constants
import { apiAppointments, apiAvailabilities } from "constants/api";

const DeleteAppointmentDialog = ({
  del,
  creationError,
  handleClose,
  loading,
  setLoading,
  success,
  setSuccess,
  error,
  setError,
  slot,
}) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl).dialogs;

  const deleteAppointment = () => {
    setLoading(true);
    del(`${apiAppointments.API_BASE}/${slot.id}`)
      .then(() => {
        del(`${apiAvailabilities.API_BASE}/${slot.availabilityId}`).then(() => {
          setLoading(false);
          setSuccess(true);
        });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      {success && !creationError && (
        <DialogTitle>{i18n.success.title}</DialogTitle>
      )}
      {creationError && <DialogTitle>{i18n.error}</DialogTitle>}
      {!success && !error && (
        <DialogTitle>{i18n.deleteAppointment}</DialogTitle>
      )}
      <DialogContent>
        {loading && <CircularProgress />}
        {!success && !loading && slot && (
          <Typography>{i18n.deleteAppointmentContent}</Typography>
        )}
        {success && !loading && !creationError && (
          <>
            <Typography>{i18n.deleteAppointmentSuccess}</Typography>
          </>
        )}
        {!loading && creationError && (
          <>
            <Typography color="error">{i18n.error}</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!success && !loading && (
          <>
            <Button onClick={handleClose}>{i18n.cancel}</Button>
            <Button onClick={deleteAppointment}>
              {i18n.delete.deleteAction}
            </Button>
          </>
        )}
        {success && <Button onClick={handleClose}>{i18n.submit}</Button>}
      </DialogActions>
    </>
  );
};
DeleteAppointmentDialog.propTypes = {
  del: PropTypes.func,
  handleClose: PropTypes.func,
  creationError: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  slot: PropTypes.object,
};
export default DeleteAppointmentDialog;
