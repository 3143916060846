export default {
  Privacy: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Privacy page title",
        defaultMessage: `Privacy Policy di Poliform`,
      }),
      description: intl.formatMessage(
        {
          // id: "privacy.page.title",
          description: "Privacy page description",
          defaultMessage: `
          Le regole di riservatezza descritte nella presente Privacy Policy, comprese le eventuali modifiche di cui sarete informati, riguardano i servizi di Poliform disponibili e fruibili attraverso il presente sito web.. Visitando questo sito web, accettate i termini e le condizioni della presente Privacy Policy. Se non accettate tali termini vi preghiamo di non accedere ai servizi offerti da Poliform via web e di non utilizzarli. Nel presente documento vengono descritte le tipologie di dati personali raccolti da Poliform e il meccanismo di trattamento degli stessi. Secondo quanto affermato in questa Privacy Policy, Poliform si impegna a rispettare e a proteggere la vostra riservatezza trattando i dati personali da voi forniti nel rispetto delle disposizioni di legge atte a garantire la sicurezza, l'esattezza, l'aggiornamento e la pertinenza dei dati rispetto alle finalità dichiarate. Il presente documento è stato redatto in forma di "domande-risposte" in modo da renderne più agevole e chiara la consultazione. Per ulteriori ed eventuali chiarimenti dopo la lettura Vi invitiamo a contattarci al seguente indirizzo: privacy@poliform.it. Tale Privacy Policy è data anche come breve informativa ai sensi dell' art. 13 del D.lgs. 196/03 a coloro che interagiscono con i servizi web di questo sito, al fine della protezione dei dati personali, accessibili per via telematica.{br}
          <bold>Quali sono i tipi di dati raccolti da Poliform?</bold>{br}
          Poliform, attraverso il proprio sito, può raccogliere informazioni sui propri clienti principalmente in due modi:{br}
          1.Dati acquisiti durante la navigazione dell'utente: i sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.), etc. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento, e vengono conservati per il tempo strettamente necessario al raggiungimento delle finalità indicate. Tali dati potrebbero essere utilizzati, esclusivamente su richiesta delle Autorità competenti, per l'accertamento di responsabilità in caso di ipotetici reati informatici a danno del sito.{br}
          2.Dati forniti volontariamente dall'utente: l'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell'indirizzo del mittente, nonché degli eventuali altri dati personali inseriti nella missiva. Ciò è necessario per rispondere alle richieste inoltrate. Inviando e-mail agli indirizzi di posta elettronica indicati sul sito, si acconsente al trattamento dei dati trasmessi per le finalità e nelle modalità contenute nella presente Privacy Policy. La registrazione facoltativa, esplicita e volontaria tramite appositi moduli Web (form) presenti sul nostro sito, comporta la successiva acquisizione di tutti i dati riportati nei campi compilati dall'utente, ed il successivo trattamento degli stessi in conformità a quanto riportato nelle singole informative pubblicate.{br}
          <bold>In quale modo e con quali finalità Poliform tratta i dati personali forniti dagli utenti?</bold>{br}
          I dati personali sono trattati da Poliform con strumenti automatizzati e per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche ed idonee misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. I dati personali forniti dagli utenti che inoltrano richieste sono utilizzati al solo fine di dare esecuzione alla richiesta di volta in volta inoltrata, e sono comunicati a terzi nel solo caso in cui ciò sia strettamente necessario e funzionale a tale finalità.{br}
          <bold>Con chi e secondo quali modalità Poliform condivide le informazioni fornite dagli utenti?</bold>{br}
          I dati derivanti dal servizio web non sono oggetto di comunicazione, ad eccezione dei dati che gli utenti forniscono volontariamente per l'inoltro di richieste ogni qual volta la comunicazione sia necessaria e funzionale a dar corso alla richiesta stessa. Tali dati potranno poi essere trattati da personale appositamente incaricato al trattamento da parte di Poliform, solo qualora il trattamento stesso sia necessario allo svolgimento delle loro mansioni, e compiendo solo le operazioni occorrenti allo svolgimento delle stesse. Nessun dato derivante dal servizio web viene diffuso.{br}
          <bold>L'utente, accedendo al sito web di Poliform, è libero di scegliere se conferire oppure no i propri dati personali?</bold>{br}
          A parte quanto specificato per i dati di navigazione, ogni utente è libero di fornire i dati personali necessari al fine di dare esecuzione alle sue specifiche richieste. Il loro mancato conferimento potrà comportare, tuttavia, l'impossibilità di ottenere quanto richiesto. Per completezza va ricordato che in alcuni casi (non oggetto dell'ordinaria gestione di questo sito), gli organi giudiziari possono richiedere alcuni tipi di dati. In questo caso ovviamente la comunicazione da parte della società ai predetti organi risulta obbligatoria.{br}
          <bold>Che cosa sono i "cookie" e come vengono utilizzati da Poliform?</bold>{br}
          Un "cookie" è un piccolo file di testo creato da alcuni siti web per immagazzinare informazioni sul computer dell'utente almomento in cui questo accede al sito. I cookie sono inviati da un server web al browser dell'utente e memorizzati sulcomputer di quest'ultimo; vengono, quindi, re-inviati al sito web al momento delle visite successive. Il sito Web di Poliform utilizza due tipi di cookie:{br}
          1."cookie di sessione", che risiedono esclusivamente nella memoria del computer dell'utente e non vengonomemorizzati in modo persistente. Ciò implica la loro cancellazione una volta che il browser viene chiuso. L'usoè strettamente limitato alla trasmissione di identificativi di sessione, costituiti da numeri casuali generati dalserver, necessari per consentire l'esplorazione sicura ed efficiente del sito;{br}
          2."cookie permanenti", che risiedono all'interno di un file memorizzato nel disco rigido del computer per undeterminato periodo di tempo. I cookie permanenti utilizzati da Poliform rimangono nel computer dell'utenteanche dopo l'uscita dal sito Poliform e cessano di aver effetto al massimo dopo 5 anni dal primo accesso registrato.Poliform utilizza cookie permanenti al solo scopo di compilare statistiche sulla base di informazioni raccolte informa anonima e mediante l'utilizzo di dati in forma aggregata. I cookie utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per lariservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi degli stessi. Poliform attraverso questo sito web non fa uso di cookie per la trasmissione di informazioni di carattere personale o disistemi per il tracciamento degli utenti. L'utente ha la possibilità, in ogni momento, di impostare il proprio browser in modo da accettare tutti i cookie, solo alcuni,oppure di rifiutarli, disabilitandone l'uso da parte dei siti. L'utente, inoltre, può normalmente impostare le preferenze delproprio browser in modo tale da essere avvisato ogni volta che un cookie viene memorizzato nella memoria del suocomputer. Si rende noto, tuttavia, che il sito Web di Poliform, a causa della tecnologia su cui si basa, in presenza di mancataaccettazione dei cookie, potrebbe non funzionare adeguatamente e in alcuni casi determinare problemi di erogazione diservizio nell'ipotesi di accesso ad aree sotto autenticazione. Si ricorda, infine, che al termine di ogni sessione di navigazione, l'utente potrà in ogni caso cancellare dal proprio discofisso sia la cache-memory di navigazione, sia i cookie raccolti.{br}
          <bold>Gli utenti che hanno accesso all'area riservata possono annullare gli account che consentono loro l'accesso?</bold>{br}
          Attraverso questo sito Poliform mette a disposizione la possibilità agli utenti registrati di accedere ad una area riservata a servizi ed informazioni dedicate, mediante l'assegnazione di una password, associata ad un nome utente, che l'utente stesso può cambiare al primo accesso. La password cambiata dall'utente viene memorizzata nei sistemi di Poliform, e può essere successivamente modificata dallo stesso ogni qual volta egli lo reputi opportuno. L'account dell'utente presso Poliform può essere annullato o disattivato in qualsiasi momento; tuttavia, a seguito dell'annullamento, non sarà più possibile accedere all'area riservata se non a seguito di una nuova registrazione. Per annullare un account rivolgersi a privacy@poliform.it I dati inviati attraverso le funzionalità presenti nell'area riservata, vengono trasmessi mediante crittografia SSL (Secure Socket Layer). In ogni caso l'utente è responsabile per la protezione dei propri nomi utente e password; si raccomanda, quindi, di comportarsi con cautela e in modo responsabile durante i collegamenti in linea{br}
          <bold>Di quali diritti dispone l'utente rispetto ai propri dati personali conferiti a Poliform attraverso il sito?</bold>{br}
          In relazione ai dati inviati volontariamente a Poliform, l'utente può esercitare i diritti riconosciutigli dal D.Lgs. 196/03 all'art. 7, nei limiti ed alle condizioni previste dalla legge. In particolare i soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma dell'esistenza o meno dei medesimi dati e di conoscerne il contenuto e l'origine, verificarne l'esattezza o chiederne l'integrazione, l'aggiornamento, la rettificazione, la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Per esercitare i vostri diritti o per maggiori informazioni in merito contattare l'indirizzo generale privacy@poliform.it{br}
          <bold>Chi è il titolare del trattamento dei dati inviati attraverso questo sito?</bold>{br}
          Titolare del trattamento dei dati conferiti è la Società Poliform S.p.a. con sede in via Montesanto 28, 22044 Inverigo (CO). {br}
          Informativa ai clienti e fornitori resa ai sensi del D.Lgs. 196/03 art. 13 
          `,
        },
        {
          bold: str => <b>{str}</b>,
          br: (
            <>
              <br />
              <br />
            </>
          ),
        }
      ),
    },
  }),
};
