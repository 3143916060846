import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useWatch } from "react-hook-form";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
// import { CRGSelect, CRGText, CRGCheckBox } from "components/index";
import { Typography } from "@mui/material";

import { CustomSelect } from "./Select.styles";

const Select = ({ ...rest }) => <CustomSelect {...rest} />;

const MultiSelectControl = ({
  control,
  name,
  label,
  options,
  errors,
  errorObj,
  defaultValue = [],
}) => {
  // Hooks
  const valueWatch = useWatch({
    control,
    name,
  });
  // State
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  const renderError = (key, errors, errorObj) => {
    if (errors[key] && errors[key].type)
      return (
        <Typography color={"error"}>{errorObj[errors[key].type]}</Typography>
      );
    return null;
  };

  useEffect(() => {
    if (valueWatch === "") {
      setSelectedOptions([]);
    }
  }, [valueWatch]);
  // Functions
  const handleChange = (event, field) => {
    const {
      target: { value },
    } = event;
    const nextValue = typeof value === "string" ? value.split(",") : value;
    field.onChange(nextValue);
    setSelectedOptions(nextValue);
  };
  return control ? (
    <>
      <Controller
        name={name}
        control={control}
        multiple
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
              label={label}
              {...field}
              multiple
              labelId={label}
              renderValue={selected => {
                return selected
                  .map(s => options.find(o => o.id === s)?.name)
                  ?.join?.(", ");
              }}
              value={selectedOptions}
              onChange={e => {
                handleChange(e, field);
              }}
            >
              {options.map(o => {
                const isSelected = selectedOptions?.indexOf?.(o.id) > -1;
                return (
                  <MenuItem key={o.id} value={o.id}>
                    <Typography
                      fontSize={14}
                      style={{
                        textDecoration: isSelected ? "underline" : "none",
                      }}
                    >
                      {o.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      />
      {errors && errorObj && renderError(name, errors, errorObj)}
    </>
  ) : null;
};

MultiSelectControl.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.object,
  errorObj: PropTypes.object,
  defaultValue: PropTypes.array,
};
export { MultiSelectControl };
