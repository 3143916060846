import { useState, useEffect } from "react";
// react router dom
import { Navigate, useParams } from "react-router-dom";

// react mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// react intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import {
  BlackWhiteRoundedButton,
  EventPrenotationDetailBox,
  PoliformLogo,
  TextFieldControl,
} from "components";
import { Panel, Wrapper } from "./ConfirmParticipation.styles";

// constants
import { LOGIN } from "constants/routes";
import { apiExhibitionParticipants } from "constants/api";
import {
  confirmParticipationResponse,
  formControlNames,
} from "constants/enums";

// react-hook-form
import { useForm } from "react-hook-form";

// use-http
import useFetch from "use-http";

//datefns
import { format } from "date-fns";

// utils
import { parseParticipation } from "utils/Agenda";

const ConfirmParticipation = () => {
  let { participationId } = useParams();

  const [participationDetail, setParticipationDetail] = useState();
  const [submitted, setSubmitted] = useState(false);

  if (!participationId) return <Navigate to={LOGIN} />;

  const {
    data,
    loading: loadingData,
    response,
  } = useFetch(
    `${apiExhibitionParticipants.API_BASE}/${participationId}${apiExhibitionParticipants.APPOINTMENT}`,
    []
  );
  const { loading: loadingPut, put: putParticipation } = useFetch(
    `${apiExhibitionParticipants.API_BASE}/${participationId}`
  );

  const {
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const handleResponse = response => {
    putParticipation({
      status: response,
      note: getValues(formControlNames.agenda.note),
    }).then(() => {
      setSubmitted(true);
    });
  };

  useEffect(() => {
    if (data && response.status === 200) {
      setParticipationDetail(parseParticipation(data));
    }
  }, [data, response]);

  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);

  return (
    <Wrapper>
      <Panel>
        <Grid container>
          <Grid item xs={12}>
            <PoliformLogo />
          </Grid>
        </Grid>
        <hr />
        {(loadingData || loadingPut) && <CircularProgress />}
        {(response.status === 409 || response.status === 404) && (
          <Typography color="error">
            {i18n.confirmParticipation.expirationError}
          </Typography>
        )}
        {(!loadingData || !loadingPut) &&
          participationDetail &&
          response.status === 200 && (
            <>
              <Typography my={2} variant="h4">
                {participationDetail.exhibition.name}
              </Typography>
              <EventPrenotationDetailBox
                address={participationDetail.address}
                date={participationDetail.date}
                description={participationDetail.exhibition.description}
                slotFromHour={format(participationDetail.startTime, "HH:mm")}
                slotToHour={format(participationDetail.endTime, "HH:mm")}
                showStatus={false}
              />
              {!submitted && !loadingData && !loadingPut && (
                <form>
                  <Grid container my={3}>
                    <Grid item xs={12} md={6}>
                      <TextFieldControl
                        control={control}
                        errors={errors}
                        name={formControlNames.agenda.note}
                        label={i18n.confirmParticipation.note}
                        placeholder={i18n.confirmParticipation.note}
                        multiline
                        minRows={4}
                      />
                    </Grid>
                  </Grid>
                  <BlackWhiteRoundedButton
                    onClick={() =>
                      handleResponse(confirmParticipationResponse.CONFIRMED)
                    }
                    label={i18nActions.confirm}
                  ></BlackWhiteRoundedButton>
                  <Button
                    onClick={() =>
                      handleResponse(confirmParticipationResponse.REJECTED)
                    }
                  >
                    {i18nActions.refusal}
                  </Button>
                </form>
              )}
              {submitted && (
                <Typography mt={2}>
                  {i18n.confirmParticipation.success}
                </Typography>
              )}
            </>
          )}
      </Panel>
    </Wrapper>
  );
};
export { ConfirmParticipation };
