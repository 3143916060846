/* eslint react/prop-types: 0 */
/* eslint-disable */
import { cloneElement, useMemo, useContext, useState } from "react";

import PropTypes from "prop-types";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// constants
import { agendaSlots, userGroups } from "constants/enums";

// components
import {
  ApprovedSlot,
  AvailableSlot,
  CreateAvailabilitySlot,
  FreeSlot,
  OccupiedSlot,
  PendingSlot,
  ShowMore,
} from "components";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import { ReactComponent as RightArrow } from "assets/icons/arrow-right.svg";

// context
import { AuthContext } from "context/AuthContext";

// date-fns
import { isWithinInterval } from "date-fns";
import sub from "date-fns/sub";
import format from "date-fns/format";

// hooks
import { useUserRoles } from "hooks";
import { Typography } from "@mui/material";

import { it, enUS, enGB, zhCN, de, fr, es, ru } from "date-fns/locale";

//date-fns
const locales = {
  // TODO add american
  IT: it,
  EN: enGB,
  US: enUS,
  CN: zhCN,
  DE: de,
  FR: fr,
  ES: es,
  RU: ru,
};

function RBCToolbar({ label, date, view, views, onView, onNavigate }) {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);

  const onChange = event => {
    const current = event.target.value;
    onNavigate("DATE", new Date(current));
  };

  const goToView = view => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        {/* <button onClick={goToToday}>{i18n.calendar.today}</button> */}
        <button onClick={goToBack}>
          <LeftArrow />
        </button>
        <button onClick={goToNext}>
          <RightArrow />
        </button>
      </div>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        {views?.map(item => (
          <button
            onClick={() => goToView(item)}
            key={item}
            className={view === item ? "rbc-active" : ""}
            // className={clsx({ "rbc-active": view === item })}
          >
            {i18n.calendar[item]}
          </button>
        ))}
      </div>
    </div>
  );
}

const useCalendarSlots = ({
  event,
  setSelectedSlot,
  setOpen,
  setCurrentSlot,
  setAction,
}) => {
  const { isLoggedUserOneofGroups } = useUserRoles();

  const { user } = useContext(AuthContext);

  const [locale, setLocale] = useState(user.language);

  // handlers
  const renderAvailabilitySlots = slot => {
    if (
      !slot.appointments?.length ||
      slot.appointments?.every(el => el.status === agendaSlots.REJECTED)
    ) {
      return (
        <AvailableSlot
          setSelectedSlot={setSelectedSlot}
          setOpen={setOpen}
          slot={slot}
          event={event}
          setAction={setAction}
        />
      );
    }

    if (slot.appointments.length >= 1) {
      const notRejectedSlot = slot.appointments.find(
        el => el.status !== agendaSlots.REJECTED
      );

      switch (notRejectedSlot.status) {
        case agendaSlots.OCCUPIED:
          return (
            <OccupiedSlot
              slot={slot}
              event={event}
              appointment={notRejectedSlot}
            />
          );
        case agendaSlots.PENDING:
          return (
            <PendingSlot
              slot={slot}
              event={event}
              appointment={notRejectedSlot}
              setSelectedSlot={setSelectedSlot}
              setOpen={setOpen}
              setAction={setAction}
            />
          );
        case agendaSlots.CONFIRMED:
          return (
            <ApprovedSlot
              slot={slot}
              event={event}
              appointment={notRejectedSlot}
              setSelectedSlot={setSelectedSlot}
              setOpen={setOpen}
              setAction={setAction}
            />
          );
        default:
          return (
            <AvailableSlot
              setSelectedSlot={setSelectedSlot}
              setOpen={setOpen}
              slot={slot}
              event={event}
              setAction={setAction}
            />
          );
      }
    }
  };

  const isInEventRange = slot => {
    return event.slots.some(({ start, end }) =>
      isWithinInterval(new Date(slot), {
        start: new Date(start),
        end: new Date(end),
      })
    );
  };

  // components

  const TimeSlotWrapper = prop => {
    if (prop.resource === undefined || isInEventRange(prop.value)) {
      return prop.children;
    }

    const child = prop.children;
    return cloneElement(child, {
      className: child.props.className + " rbc-off-range-bg",
    });
  };

  const customEventFixedSlots = ({ event }) => (
    <div style={{ display: "flex", flex: 1 }}>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          display: "flex",
        }}
      >
        {!isLoggedUserOneofGroups([
          userGroups.ADMIN,
          userGroups.AREAMANAGER,
          userGroups.LOCALMANAGER,
        ]) &&
          event.availabilities?.length === 0 && (
            <CreateAvailabilitySlot
              setSelectedSlot={setSelectedSlot}
              setOpen={setOpen}
              slot={event}
              setAction={setAction}
            />
          )}
        {isLoggedUserOneofGroups([
          userGroups.ADMIN,
          userGroups.AREAMANAGER,
          userGroups.LOCALMANAGER,
        ]) && (
          <CreateAvailabilitySlot
            setSelectedSlot={setSelectedSlot}
            setOpen={setOpen}
            slot={event}
            setAction={setAction}
          />
        )}
        {event.availabilities?.length >= 2 && (
          <ShowMore slot={event}>
            {event.availabilities?.map(el => renderAvailabilitySlots(el))}
          </ShowMore>
        )}

        {event.availabilities?.length === 1 &&
          renderAvailabilitySlots(event.availabilities[0])}
      </div>
    </div>
  );
  const customEventFreeSlots = ({ event: slot }) => (
    <FreeSlot
      setAction={setAction}
      setOpen={setOpen}
      setCurrentSlot={setCurrentSlot}
      slot={slot}
      event={event}
    />
  );

  const customTimeSlotWrapper = timeSlotWrapperProps => {
    return (
      <>
        {timeSlotWrapperProps.resource !== null && (
          <Typography fontSize={12}>
            {/* {format(
              sub(timeSlotWrapperProps.value, {
                minutes: timeSlotWrapperProps.value.getTimezoneOffset(),
              }),
              "HH:mm"
            )} */}
            {format(timeSlotWrapperProps.value, "HH:mm")}
          </Typography>
        )}
      </>
    );
  };

  const componentsFixedSlots = useMemo(
    () => ({
      event: customEventFixedSlots,
      toolbar: RBCToolbar,
      timeSlotWrapper: customTimeSlotWrapper,
      day: {
        event: ({ event }) => {
          return (
            <>
              {/* TODO add check for user permission to create availability */}
              {!isLoggedUserOneofGroups([
                userGroups.ADMIN,
                userGroups.AREAMANAGER,
                userGroups.LOCALMANAGER,
              ]) &&
                event.availabilities?.length === 0 && (
                  <CreateAvailabilitySlot
                    setSelectedSlot={setSelectedSlot}
                    setOpen={setOpen}
                    slot={event}
                    setAction={setAction}
                  />
                )}
              {isLoggedUserOneofGroups([
                userGroups.ADMIN,
                userGroups.AREAMANAGER,
                userGroups.LOCALMANAGER,
              ]) && (
                <CreateAvailabilitySlot
                  setSelectedSlot={setSelectedSlot}
                  setOpen={setOpen}
                  slot={event}
                  setAction={setAction}
                />
              )}
              {event.availabilities?.map(el => renderAvailabilitySlots(el))}
            </>
          );
        },
      },
      week: {
        week: {
          // header: ({ date }) => {
          //   return (
          //     <Typography fontSize={12}>
          //       {format(
          //         sub(date, {
          //           minutes: date.getTimezoneOffset(),
          //         }),
          //         "dd ccc",
          //         { locale: locales[locale] }
          //       )}
          //     </Typography>
          //   );
          // },
          event: customEventFreeSlots,
        },
        event: customEventFixedSlots,
      },
    }),
    [event]
  );

  const componentsFreeSlots = useMemo(
    () => ({
      event: customEventFreeSlots,
      // timeSlotWrapper: TimeSlotWrapper,
      timeSlotWrapper: customTimeSlotWrapper,
      toolbar: RBCToolbar,
      week: {
        // header: ({ date }) => {
        //   return (
        //     <Typography fontSize={12}>
        //       {format(
        //         sub(date, {
        //           minutes: date.getTimezoneOffset(),
        //         }),
        //         "dd ccc",
        //         { locale: locales[locale] }
        //       )}
        //     </Typography>
        //   );
        // },
        event: customEventFreeSlots,
      },
    }),
    [event]
  );

  return { componentsFixedSlots, componentsFreeSlots };
};

useCalendarSlots.propTypes = {
  event: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedSlot: PropTypes.func.isRequired,
  setCurrentSlot: PropTypes.func.isRequired,
  setAction: PropTypes.func,
};

export { useCalendarSlots };
