import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "underline",
  "&:visited": {
    color: theme.palette.primary.main,
  },
}));
