import { styled } from "@mui/system";

export const SlotWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.agenda.createAvailability,
  display: "flex",
  alignItems: "center",
  height: "100%",
  maxHeight: "40px",
  borderRadius: "5px",
  padding: "5px",
  // position: "absolute",
  // bottom: 0,
  // right: 0,
}));

export const PrenotationIconWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.agenda.createAvailability,
  padding: "10px",
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  display: "grid",
  placeContent: "center",
  fontSize: "1rem",
  margin: "5px",
}));
