// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// mui
import { Grid, Typography } from "@mui/material";

// components
import { BackArrow, PoliformLogo } from "components";

// custom styles
import { Wrapper, Panel } from "./Informative.styles";

const Informative = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.Informative(intl).page;

  return (
    <Wrapper>
      <Panel>
        <Grid container spacing={2} direction="column" minWidth={"100%"}>
          <Grid item>
            <BackArrow />
          </Grid>
          <Grid item xs={12}>
            <PoliformLogo />
          </Grid>
          <Grid item xs={12}>
            <hr style={{ width: "100%" }} />
            <Typography my={2} variant={"h4"}>
              {i18n.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography my={2} variant={"body1"}>
              {i18n.description}
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </Wrapper>
  );
};
export { Informative };
