import { useContext } from "react";

import { useIntl } from "react-intl";
import translations from "utils/translations";

import { AuthContext } from "context/AuthContext";

const useHeader = () => {
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);
  const { user } = useContext(AuthContext);

  const header = [
    {
      name: i18n.header.code,
      accessor: "codefather",
    },
    {
      name: i18n.header.companyName,
      accessor: "lastname",
    },
    {
      name: i18n.header.email,
      accessor: "email",
    },
    {
      name: i18n.header.sector,
      accessor: "sector",
    },
    {
      name: i18n.header.country,
      accessor: "country",
    },
    {
      name: i18n.header.city,
      accessor: "city",
    },
    {
      name: i18n.header.qualification,
      accessor: "qualification",
    },
    {
      name: i18n.header.rating,
      accessor: "rating",
    },
    {
      name: i18n.header.agent,
      accessor: "agent",
    },
    {
      name: i18n.header.state,
      accessor: "state",
    },
    user.language === "IT"
      ? {
          // Header: "Cognome",
          accessor: "editrights", // accessor is the "key" in the data
          Cell: element => (
            <p
              onClick={() => {
                console.log("edit", element.row.original);
              }}
            >
              modifica {/*element.row.original.id*/}
            </p>
          ),
        }
      : {
          accessor: "editrights",
        },
  ];

  return [header];
};

export default useHeader;
