import { DatePicker, TimePicker } from "@mui/lab";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { styled } from "@mui/system";

export const CustomDateTimePicker = styled(DateTimePicker)({
  width: "100%",
});
export const CustomDatePicker = styled(DatePicker)({
  width: "100%",
});
export const CustomTimePicker = styled(TimePicker)({
  width: "100%",
});
