import { useState } from "react";
// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react-hook-form
import { useForm } from "react-hook-form";

// use-http
import useFetch from "use-http";

//constants
import { LOGIN } from "constants/routes";
// mui
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// components
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import {
  TextFieldControl,
  Link,
  ReservedAreaTitle,
  ReservedAreaCover,
  PrivacyFooter,
} from "components/index";

// custom styles
import { Wrapper, LeftPanel, FormContainer } from "./ForgotPassword.styles";

// yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// API
import { apiUser } from "constants/api";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const ForgotPassword = () => {
  // Hooks
  const intl = useIntl();
  const i18n = translations.ForgotPassword(intl).page;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { post, response, loading, error: fetchError } = useFetch();

  const [submitted, setSubmitted] = useState(false);

  // TODO refactor
  const onSubmit = async data => {
    // eslint-disable-next-line
    const forgotPassword = await post(
      `${apiUser.API_BASE}${apiUser.FORGOT_PASSWORD}`,
      data
    );
    if (response.ok) {
      setSubmitted(true);
    }
  };

  return (
    <Wrapper>
      <LeftPanel>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Link to={LOGIN}>
                <LeftArrow />
              </Link>
            </Grid>
            <Grid item>
              <ReservedAreaTitle />
            </Grid>
            {fetchError && response.status == 500 && (
              <Typography variant={"body1"}>
                {i18n.fetchError.server}
              </Typography>
            )}
            {loading && <CircularProgress />}
            {!fetchError && !loading && !submitted && (
              <>
                <Grid item>
                  <Typography fontSize={18} variant={"body1"}>
                    {i18n.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextFieldControl
                    control={control}
                    name={"email"}
                    placeholder={i18n.form.email.label}
                    label={i18n.form.email.label}
                    errors={errors}
                    errorObj={i18n.form.email.errors}
                    isRequired
                  />
                </Grid>
                <Grid item>
                  <Button
                    style={{ fontSize: 16 }}
                    variant="outlined"
                    type="submit"
                  >
                    {i18n.form.submit}
                  </Button>
                </Grid>
              </>
            )}
            {submitted && (
              <>
                <Grid item>
                  <Typography variant={"h6"}>{i18n.success.title}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"body1"}>
                    {i18n.success.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={LOGIN}>
                    <Button variant="outlined">
                      {i18n.success.button.label}
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </FormContainer>
        <PrivacyFooter />
      </LeftPanel>
      <ReservedAreaCover />
    </Wrapper>
  );
};
export { ForgotPassword };
