export default {
  Informative: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Informativa page title",
        defaultMessage: `Informativa art. 13 D.lgs 196/03`,
      }),
      description: intl.formatMessage(
        {
          // id: "privacy.page.title",
          description: "Informativa page description",
          defaultMessage: `
          Ai sensi dell'art. 13 del Decreto Legislativo 30 giugno 2003, n. 196, nuovo Testo Unico recante disposizioni "In materia di protezione dei dati personali"", La informiamo che, a seguito dell'inoltro dei dati che sta per effettuare tramite la compilazione dei form presenti in questo sito, si procederà al trattamento di dati personali nel rispetto della normativa sopra richiamata e degli obblighi di riservatezza cui è ispirata la nostra attività.{br}
          I dati di cui all'oggetto saranno trattati e conservati presso Poliform S.p.A., sita in Via Montesanto 28, 22044 Inverigo (CO).{br}
          I dati saranno trattati sia su supporto cartaceo che informatico, che in via telematica, mediante sistemi di protezione atti alla tutela della riservatezza, per dare esecuzione alla Sua richiesta.{br}
          I dati saranno comunicati a terzi, se necessario per l'esecuzione della Sua richiesta. Ad esempio potranno essere comunicati ai nostri punti vendita per dar seguito a Sue specifiche richieste. Potranno averne conoscenza, inoltre, tutti gli incaricati al trattamento (nominati ai fini di legge) secondo il loro profilo di autorizzazione ed eventuali responsabili. Poliform si premunirà di far garantire la massima riservatezza dai terzi su indicati, e di far trattare i dati solo per le finalità di cui sopra.{br}
          Il conferimento dei Suoi dati anagrafici è facoltativo, tuttavia l'eventuale rifiuto di fornirli in tutto o in parte può dar luogo all'impossibilità per la scrivente di dar seguito alla Sua richiesta.{br}
          Tutti i dati di cui all'oggetto verranno conservati per un tempo non superiore a quello necessario agli scopi per i quali essi sono raccolti e successivamente trattati.{br}
          La S.V. potrà in qualsiasi momento esercitare i diritti di cui all'art. 7 D.Lgs. 196/2003, rivolgendosi a Poliform S.p.A tramite e-mail a privacy@poliform.it o fax allo +39/031699444.{br}
          Titolare del trattamento è la scrivente società Poliform S.p.A., sita in Via Montesanto 28, 22044 Inverigo (CO).{br}{br}
          Art. 7 del D.lgs 196/03 1. L'interessato ha diritto di ottenere la conferma dell'esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.{br}
          2. L'interessato ha diritto di ottenere l'indicazione:{br}
          a. dell'origine dei dati personali;{br}
          b. delle finalità e modalità del trattamento;{br}
          c. della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;{br}
          d. degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell'articolo 5, comma 2;{br}
          e. dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati.{br}{br}
          3. L'interessato ha diritto di ottenere:{br}
          a. l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;{br}
          b. la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;{br}
          c. l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.{br}{br}
          4. L'interessato ha diritto di opporsi, in tutto o in parte:{br}
          a. per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;{br}
          b. al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.{br}


          `,
        },
        {
          bold: str => <b>{str}</b>,
          br: <br />,
        }
      ),
    },
  }),
};
