import { useState } from "react";
import PropTypes from "prop-types";
// react mui
import { CircularProgress, Grid, Typography } from "@mui/material";

// intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// react router dom
import { useLocation } from "react-router-dom";

// use-http
import useFetch from "use-http";

// fields
import AnagraficheFields from "./Anagrafiche.fields";

// constants
import { apiCategories } from "constants/api";
import { REGISTER } from "constants/routes";

// components
import { TextFieldControl, SelectControl } from "components/index";

function PressFields({ control, errors, watch, reset }) {
  // Hooks
  const intl = useIntl();
  const i18n = translations.templates.ProfileForm(intl).page.press;
  const { pathname } = useLocation();

  // state
  // eslint-disable-next-line
  const [sectors, setSectors] = useState(watch("sectors"));
  // eslint-disable-next-line
  const [categories, setCategories] = useState(watch("category"));

  const {
    data: categoriesData,
    loading: loadingCategories,
    error: errorCategories,
  } = useFetch(apiCategories.API_BASE, []);

  // const {
  //   data: sectorOfInterestsData,
  //   loading: loadingSectorOfInterests,
  //   error: errorSectorOfInterests,
  // } = useFetch(apiSectorOfInterests.API_BASE, []);

  return (
    <>
      <Grid mt={1} container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"name"}
            placeholder={i18n.pressName.label}
            label={i18n.pressName.label}
            errors={errors}
            errorObj={i18n.pressName.errors}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"surname"}
            placeholder={i18n.pressSurname.label}
            label={i18n.pressSurname.label}
            errors={errors}
            errorObj={i18n.pressSurname.errors}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"email"}
            placeholder={i18n.email.label}
            label={i18n.email.label}
            errors={errors}
            errorObj={i18n.email.errors}
            isRequired
            disabled={pathname !== REGISTER}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {errorCategories && (
            <Typography>{i18n.category.errors.fetchError}</Typography>
          )}
          {loadingCategories && <CircularProgress size={25} />}
          {categoriesData?.data && (
            <SelectControl
              control={control}
              name={"category"}
              placeholder={i18n.category.label}
              label={i18n.category.label}
              errors={errors}
              errorObj={i18n.category.errors}
              options={categoriesData.data}
              value={categories}
              defaultValue={categories}
              fullWidth
            />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          {errorSectorOfInterests && (
            <Typography>{i18n.sector.errors.fetchError}</Typography>
          )}
          {loadingSectorOfInterests && <CircularProgress size={25} />}
          {sectorOfInterestsData?.data && (
            <MultiSelectControl
              control={control}
              name={"sectors"}
              placeholder={i18n.sector.label}
              label={i18n.sector.label}
              errors={errors}
              errorObj={i18n.sector.errors}
              options={sectorOfInterestsData.data}
              value={sectors}
              defaultValue={sectors}
              fullWidth
            />
          )}
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"company"}
            placeholder={i18n.company.label}
            label={i18n.company.label}
            errors={errors}
            errorObj={i18n.company.errors}
          />
        </Grid>
        <AnagraficheFields
          reset={reset}
          watch={watch}
          control={control}
          errors={errors}
        />
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"website"}
            placeholder={i18n.website.label}
            label={i18n.website.label}
            errors={errors}
            errorObj={i18n.website.errors}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"telephone"}
            placeholder={i18n.telephone.label}
            label={i18n.telephone.label}
            errors={errors}
            errorObj={i18n.telephone.errors}
          />
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <TextFieldControl
            control={control}
            name={"mobile"}
            placeholder={i18n.mobile.label}
            label={i18n.mobile.label}
            errors={errors}
            errorObj={i18n.mobile.errors}
          />
        </Grid>
      </Grid>
    </>
  );
}

PressFields.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  reset: PropTypes.func,
};
export default PressFields;
