import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// use-http
import useFetch from "use-http";

// constants
import { eventManageEvents } from "constants/enums";
import { apiAgenda } from "constants/api";
import { LoadingDialog } from "components";

const EventsActionDialog = ({ open, setOpen, action, selectedEvent }) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);

  const { del: deleteEvent, loading } = useFetch(
    `${apiAgenda.API_BASE}/${selectedEvent?.id}`
  );
  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleDelete = () => {
    deleteEvent().then(() => handleClose());
  };

  useEffect(() => {}, [action]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {action === eventManageEvents.DELETE && <>{i18n.deleteEvent.title}</>}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {action === eventManageEvents.DELETE && !loading && (
              <>{i18n.deleteEvent.content}</>
            )}
          </Typography>
          <Typography fontWeight={"bold"}>{selectedEvent?.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{i18nActions.close}</Button>
          {action === eventManageEvents.DELETE && !submitted && (
            <Button onClick={handleDelete}>{i18nActions.delete}</Button>
          )}
        </DialogActions>
      </Dialog>
      <LoadingDialog open={loading} />
    </>
  );
};

EventsActionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  action: PropTypes.oneOf([eventManageEvents.DELETE]),
  selectedEvent: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    start: PropTypes.object,
    startDate: PropTypes.string,
    end: PropTypes.object,
    endDate: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.object,
  }),
};
export { EventsActionDialog };
