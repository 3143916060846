import { useState, useEffect } from "react";

const useStorage = (key, initial, storage) => {
  let item;
  if (storage === "session") {
    item = JSON.parse(window.sessionStorage.getItem(key));
  } else {
    item = JSON.parse(window.localStorage.getItem(key));
  }

  const [value, setValue] = useState(item || initial);

  useEffect(() => {
    if (storage === "session") {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, key, initial]);

  return [value, setValue];
};

export { useStorage };
