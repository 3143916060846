import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import translations from "utils/translations";

// react mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// use-http
import useFetch from "use-http";

// constants
import { eventManageParticipant } from "constants/enums";
import { apiAppointments } from "constants/api";
import { LoadingDialog } from "components";

const ParticipantActionDialog = ({
  open,
  setOpen,
  action,
  appointment,
  mainEvent,
}) => {
  const intl = useIntl();
  const i18n = translations.Agenda(intl);
  const i18nActions = translations.Actions(intl);

  const {
    put: confirmAppointment,
    del: deleteAppointment,
    loading,
  } = useFetch(`${apiAppointments.API_BASE}`);
  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleRefusal = () => {
    deleteAppointment(`${appointment.id}`).then(() => {
      setSubmitted(true);
    });
  };

  useEffect(() => {
    if (action === eventManageParticipant.APPROVE) {
      confirmAppointment(
        `${appointment.id}/${appointment.toGuestId || appointment.toId}`
      ).then(() => {
        setSubmitted(true);
      });
    }
  }, [action]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {action === eventManageParticipant.APPROVE && (
            <>{i18nActions.congratulations}</>
          )}
          {action === eventManageParticipant.REFUSE && (
            <>{i18nActions.refusal}</>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography>{mainEvent?.name}</Typography>
          <Typography>{appointment?.displayName}</Typography>
          <Typography>
            {action === eventManageParticipant.APPROVE && !loading && (
              <>{i18n.eventManageParticipant.dialog.approve.content}</>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{i18nActions.close}</Button>
          {action === eventManageParticipant.REFUSE && !submitted && (
            <Button onClick={handleRefusal}>{i18nActions.refusal}</Button>
          )}
        </DialogActions>
      </Dialog>
      <LoadingDialog open={loading} />
    </>
  );
};
ParticipantActionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  action: PropTypes.oneOf([
    eventManageParticipant.APPROVE,
    eventManageParticipant.REFUSE,
  ]),
  appointment: PropTypes.object,
  mainEvent: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    start: PropTypes.object,
    end: PropTypes.object,
  }),
};
export { ParticipantActionDialog };
