import PropTypes from "prop-types";

// react mui
import { Typography } from "@mui/material";

// react-intl
import { useIntl } from "react-intl";
import translations from "utils/translations";

// components
import { ActionIconManager } from "components";

// styled components
import { Content, Header, Wrapper } from "./EventPrenotationDetailBox.styles";

// constants
import { agendaSlots, ListIconTypes } from "constants/enums";
import { StatusCircle } from "components/StatusCircle/StatusCircle";

const EventPrenotationDetailBox = ({
  // agent,
  slotPrenotationState,
  address,
  date,
  slotFromHour,
  slotToHour,
  description,
  showStatus = true,
}) => {
  // hooks
  const intl = useIntl();
  const i18n =
    translations.components.EventPrenotationDetailBox(intl).component;

  return (
    <Wrapper>
      <Header>
        <Typography variant="body1" color={"white"} textTransform={"uppercase"}>
          {i18n.title}
        </Typography>
      </Header>
      <Content>
        {showStatus && (
          <>
            <Typography variant="body1">
              <StatusCircle status={slotPrenotationState} />
              {"  "}
              {slotPrenotationState === agendaSlots.PENDING && (
                <>{i18n.pendingState}</>
              )}
              {slotPrenotationState === agendaSlots.REJECTED && (
                <>{i18n.refusedState}</>
              )}
              {slotPrenotationState === agendaSlots.APPROVED ||
                (slotPrenotationState === agendaSlots.CONFIRMED && (
                  <>{i18n.approvedState}</>
                ))}
            </Typography>
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
          </>
        )}
        <Typography variant="body1">
          <Typography variant="span" fontWeight={"bolder"}>
            {i18n.locationTitle}
          </Typography>
          {": "}
          {address.location}, {address.street}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={`https://www.google.com/maps/search/${address.location
              .toLowerCase()
              .replaceAll(" ", "+")},${address.street
              .toLowerCase()
              .replaceAll(" ", "+")}`}
          >
            <ActionIconManager
              listActions={[
                {
                  type: ListIconTypes.MAPLOCATION,
                },
              ]}
            />
          </a>
        </Typography>
        <Typography variant="body1">
          <Typography variant="span" fontWeight={"bolder"}>
            {i18n.dateTitle}
          </Typography>
          {": "} {date}
        </Typography>
        <Typography variant="body1">
          <Typography variant="span" fontWeight={"bolder"}>
            {i18n.hour.title}
          </Typography>
          {": "}
          {i18n.hour.from} {slotFromHour} {i18n.hour.to} {slotToHour}
        </Typography>
        <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Typography variant="body1" fontWeight={"bolder"}>
          {i18n.description}
        </Typography>{" "}
        <Typography variant="body1">
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: description.replaceAll("\n", "<br/>"),
            }}
          />
        </Typography>
      </Content>
    </Wrapper>
  );
};
EventPrenotationDetailBox.propTypes = {
  address: PropTypes.object,
  date: PropTypes.date,
  slotFromHour: PropTypes.date,
  slotToHour: PropTypes.date,
  description: PropTypes.string,
  // agent: PropTypes.string,
  slotPrenotationState: PropTypes.string,
  showStatus: PropTypes.bool,
};
export { EventPrenotationDetailBox };
