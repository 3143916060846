import { useIntl } from "react-intl";

// components
import { ExportLinkCSV, MainLayout } from "components/index";

// containers
import { FilteredList } from "containers";

// utils
import translations from "utils/translations";

import { filtersList } from "./BusinessPartnerList.filters";
import useHeader from "./BusinessPartnerList.header";
import { useRegistryDataListFetch } from "hooks";
import { HeaderContainer } from "./BusinessPartnerList.styles";

function BusinessPartnerList() {
  const [header] = useHeader();
  const intl = useIntl();
  const i18n = translations.components.RegistryLists(intl);

  const renderHeaderAction = () => {
    return (
      <>
        <HeaderContainer>
          <ExportLinkCSV /> {/*TODO: add data for export*/}
        </HeaderContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <FilteredList
        useCustomFetch={useRegistryDataListFetch}
        i18n={i18n.businessPartner}
        i18nErrors={i18n.errors}
        headerAction={renderHeaderAction()}
        header={header}
        filtersList={filtersList}
        queryUserTypeParams={"&Group=BUSINESSPARTNER&"}
      />
    </MainLayout>
  );
}

export { BusinessPartnerList };
