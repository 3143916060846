export default {
  PermissionsPage: intl => ({
    page: {
      title: intl.formatMessage({
        description: "My Area Registry Permission page title",
        defaultMessage: "Gestione Permessi",
      }),
      fetchError: intl.formatMessage({
        description: "My Area Registry Permission page fetch error",
        defaultMessage: "Errore di comunicazione con il server",
      }),
    },
  }),
};
