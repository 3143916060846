export default {
  ChangePassword: intl => ({
    page: {
      title: intl.formatMessage({
        description: "My Area Registry Change Password page title",
        defaultMessage: "Cambia Password",
      }),
      form: {
        password: {
          errors: {
            required: intl.formatMessage({
              description:
                "My Area Registry Change Password Password error required",
              defaultMessage: "La password attuale è obbligatoria",
            }),
            min: intl.formatMessage({
              description:
                "My Area Registry Change Password Password error minimum",
              defaultMessage:
                "La password attuale è troppo corta, inserire almeno 8 caratteri",
            }),
            matches: intl.formatMessage({
              description:
                "My Area Registry Change Password Password error minimum",
              defaultMessage:
                "La password attuale deve contenere almeno 8 caratteri di cui almeno: una lettera maiuscola, una minuscola e un numero ",
            }),
          },
          label: intl.formatMessage({
            description: "My Area Registry Change Password password label",
            defaultMessage: "Password Attuale",
          }),
        },
        newPassword: {
          errors: {
            required: intl.formatMessage({
              description:
                "My Area Registry Change Password newpassword error required",
              defaultMessage: "La nuova password è obbligatoria",
            }),
            min: intl.formatMessage({
              description:
                "My Area Registry Change Password newpassword error minimum",
              defaultMessage:
                "La nuova password è troppo corta, inserire almeno 8 caratteri",
            }),
            matches: intl.formatMessage({
              description:
                "My Area Registry Change Password newpassword error minimum",
              defaultMessage:
                "La nuova password deve contenere almeno 8 caratteri di cui almeno: una lettera maiuscola, una minuscola e un numero ",
            }),
          },
          label: intl.formatMessage({
            description: "My Area Registry Change Password newpassword label",
            defaultMessage: "Nuova Password",
          }),
        },
        newConfirmPassword: {
          errors: {
            required: intl.formatMessage({
              description:
                "My Area Registry Change Password confirmPassword error required",
              defaultMessage: "La conferma password è obbligatoria",
            }),
            oneOf: intl.formatMessage({
              description:
                "My Area Registry Change Password confirmPassword error oneOf",
              defaultMessage: "Le password non coincidono",
            }),
          },
          label: intl.formatMessage({
            description:
              "My Area Registry Change Password confirmPassword label",
            defaultMessage: "Conferma Nuova Password",
          }),
        },
        submit: intl.formatMessage({
          description:
            "My Area Registry Change Password page form submit button label",
          defaultMessage: "Salva",
        }),
      },
      errors: {
        fetchError: intl.formatMessage({
          description:
            "My Area Registry Change Password page general fetch error 500",
          defaultMessage:
            "Errore di comunicazione con il server, riprovare più tardi.",
        }),
        invalidCredentials: intl.formatMessage({
          description:
            "My Area Registry Change Password page general fetch error 400",
          defaultMessage:
            "Credenziali errate, assicurati di aver inserito la password corretta",
        }),
      },
      success: intl.formatMessage({
        description: "My Area Registry Change Password page success",
        defaultMessage: "Password salvata con successo.",
      }),
    },
  }),
};
