export default {
  ForgotPassword: intl => ({
    page: {
      title: intl.formatMessage({
        description: "Forgot Password page title",
        defaultMessage: `Hai dimenticato la password?
        Inserisci il tuo indirizzo e-mail, riceverai indicazioni per procedere con il reset della tua password`,
      }),
      form: {
        email: {
          label: intl.formatMessage({
            description:
              "Forogt Password page Forogt Password form email input label",
            defaultMessage: "email",
          }),
          errors: {
            required: intl.formatMessage({
              description:
                "Forogt Password page Forogt Password form email input error required",
              defaultMessage: "Il campo email è obbligatorio",
            }),
            email: intl.formatMessage({
              description:
                "Forogt Password page Forogt Password form email input error email invalid",
              defaultMessage: "Inserire una mail valida",
            }),
          },
        },
        submit: intl.formatMessage({
          description: "Forogt Password page Forogt Password form submit label",
          defaultMessage: "invia",
        }),
      },
      success: {
        title: intl.formatMessage({
          description: "Forogt Password page success title",
          defaultMessage: "Grazie",
        }),
        description: intl.formatMessage({
          description: "Forogt Password page success description",
          defaultMessage:
            "Grazie Abbiamo inviato al tuo indirizzo di posta le infomazioni per procedere con il reset della password. Se non ricevi la email, controlla anche all'interno della posta indesiderata.",
        }),
        button: {
          label: intl.formatMessage({
            description: "Forogt Password page success button label",
            defaultMessage: "torna alla login",
          }),
        },
      },
      fetchError: {
        server: intl.formatMessage({
          description: "Forgot Password page fetch error server",
          defaultMessage: "Errore nel server, riprovare più tardi",
        }),
      },
      privacyLink: intl.formatMessage({
        description: "Create Password page privacy link",
        defaultMessage: "Privacy",
      }),
      legalNotesLink: intl.formatMessage({
        description: "Create Password page legal notes link",
        defaultMessage: "Note Legali",
      }),
    },
  }),
};
